<template lang="pug">
.customer-data-fields
  fi-data-field(v-if = 'compare')
    .row.font-weight-bold
      .col {{ $t('application') }}
      .col {{ $t('system') }}
  fi-data-field(:label = '$t("name")')
    .row
      .col
        router-link(
          v-if = 'showLink'
          :to  = '{ name: "Customer", params: { id: customer.id.toString() } }'
        ) {{ name || "!" }}
        span(
          v-else
          :class = 'markDiff("name", "compareName")'
        ) {{ name || "!" }}
      .col(v-if = 'compare')
        router-link(
          v-if = 'showLink'
          :to  = '{ name: "Customer", params: { id: compare.id.toString() } }'
        ) {{ compareName }}
        template(v-else) {{ compareName }}

  fi-data-field(:label = '$t("idCode")')
    .row
      .col
        router-link(
          v-if = 'showLink'
          :to  = '{ name: "Customer", params: { id: customer.id.toString() } }'
        ) {{ customer.idCode }}
        template(v-else) {{ customer.idCode }}
      .col(v-if = 'compare')
        router-link(v-if = 'showLink'
          :to  = '{ name: "Customer", params: { id: compare.id.toString() } }'
        ) {{ compare.idCode }}
        template(v-else) {{ compare.idCode }}

  //- fi-data-field(:label = '$t("idCode")')
  //-   .row
  //-     .col
  //-       template {{ customer }}
  //-       template {{ compare }}
      //- .col(v-if = 'compare')
      //-   router-link(v-if = 'showLink'
      //-     :to  = '{ name: "Customer", params: { id: compare.id.toString() } }'
      //-   ) {{ compare.idCode }}
      //-   template(v-else) {{ compare.idCode }}
  fi-data-field(:label = '$t("nace")')
    .row
      .col
        template {{ codeWithHuman(classifierById('nace', (customer.naceId ? customer.naceId : customer.corporateCustomer ? customer.corporateCustomer.naceId : ''))) }}
      .col(v-if = 'compare && compare.corporateCustomer')
        template {{ codeWithHuman(classifierById('nace', compare.corporateCustomer.naceId)) }}
  fi-data-field(:label = '$t("emtak")')
    .row
      .col
        template {{ codeWithHuman(classifierById('emtak', customer.emtakId ? customer.emtakId : customer.corporateCustomer ? customer.corporateCustomer.emtakId : ''))}}
      .col(v-if = 'compare && compare.corporateCustomer')
        template {{ codeWithHuman(classifierById('emtak', compare.corporateCustomer.emtakId)) }}
  fi-data-field(:label = '$t("vatNumber")')
    .row
      .col
        template {{ customer.vatNumber ? customer.vatNumber : customer.corporateCustomer ? customer.corporateCustomer.vatNumber : '' }}
      .col(v-if = 'compare && compare.corporateCustomer')
        template {{ compare.corporateCustomer.vatNumber }}
  fi-data-field(:label = '$t("registeredCapital")')
    .row
      .col
        template {{ customer.registeredCapital }}
      .col(v-if = 'compare && compare.corporateCustomer')
        template {{ compare.corporateCustomer.registeredCapital }}
  fi-data-field(:label = '$t("sector")')
    .row
      .col
        span(:class = 'markDiff("customer.sectorId", "compare.sectorId")') {{ classifierById('sectors', customer.sectorId).human }}
      .col(v-if = 'compare')
        span {{ classifierById('sectors', compare.sectorId).human }}

  fi-data-field(:label = '$t("segment")')
    .row
      .col
        span(:class = 'markDiff("customer.segmentId", "compare.segmentId")') {{ classifierById('segments', customer.segmentId).human }}
      .col(v-if = 'compare')
        span {{ classifierById('segments', compare.segmentId).human }}

  fi-data-field(:label = '$t("employmentType")')
    .row
      .col
        span(:class = 'markDiff("customer.employmentType.id", "compare.employmentType.id")') {{ employmentType.human }}
      .col(v-if = 'compare')
        span {{ compareEmploymentType.human }}
  fi-data-field(:label = '$t("acceptPEP")')
    .row
      .col
        fi-switch(
          v-model   = 'customer.acceptPep'
          :on-text  = '$t("common:enabled")'
          :off-text = '$t("common:disabled")'
          read-only
        )
      .col(v-if = 'compare')
        fi-switch(
          v-model   = 'compare.acceptPep'
          :on-text  = '$t("common:enabled")'
          :off-text = '$t("common:disabled")'
          read-only
        )

  fi-data-field(:label = '$t("sanctions")')
    .row
      .col
        fi-switch(
          v-model   = 'customer.existInSanctions'
          :on-text  = '$t("common:enabled")'
          :off-text = '$t("common:disabled")'
          read-only
        )
      .col(v-if = 'compare')
        fi-switch(
          v-model   = 'compare.existInSanctions'
          :on-text  = '$t("common:enabled")'
          :off-text = '$t("common:disabled")'
          read-only
        )

  fi-data-field(:label = '$t("blacklisted")')
    .row
      .col
        fi-switch(
          v-model   = 'customer.isInBlacklist'
          :on-text  = '$t("common:enabled")'
          :off-text = '$t("common:disabled")'
          read-only
        )
      .col(v-if = 'compare')
        fi-switch(
          v-model   = 'compare.isInBlacklist'
          :on-text  = '$t("common:enabled")'
          :off-text = '$t("common:disabled")'
          read-only
        )

  fi-data-field(:label = '$t("aml")')
    .row
      .col
        span(:class = 'markDiff("customer.antiMoneyLaunderingCategory.id", "compare.antiMoneyLaunderingCategory.id")') {{ antiMoneyLaunderingCategory.human }}
      .col(v-if = 'compare')
        span {{ compareAntiMoneyLaunderingCategory.human }}

  fi-data-field(:label = '$t("riskCategory")')
    .row
      .col
        span(:class = 'markDiff("customer.riskCategory.id", "compare.riskCategory.id")') {{ riskCategory.human }}
      .col(v-if = 'compare')
        span {{ compareRiskCategory.human }}

  template(v-if = '!isCorporateCustomer(customer)')
    fi-data-field(:label = '$t("maritalStatus")')
      .row
        .col
          span(:class = 'markDiff("customer.maritalStatus.id", "compare.maritalStatus.id")') {{ maritalStatus.human }}
        .col(v-if = 'compare')
          span {{ compareMaritalStatus.human }}

    fi-data-field(:label = '$t("dependantNumber")')
      .row
        .col
          span(:class = 'markDiff("dependantNumber", "compareDependantNumber")') {{ dependantNumber }}
        .col(v-if = 'compare')
          span {{ compareDependantNumber }}

  fi-data-field(:label = '$t("phone")')
    .row
      .col
        span(:class = 'markDiff("phone", "comparePhone")') {{ phone }}
      .col(v-if = 'compare')
        span {{ comparePhone }}

  fi-data-field(:label = '$t("deskPhone")')
    .row
      .col
        span(:class = 'markDiff("deskPhone", "compareDeskPhone")') {{ deskPhone }}
      .col(v-if = 'compare')
        span {{ compareDeskPhone }}

  fi-data-field(:label='$t("email")')
    .row
      .col
        span(:class = 'markDiff("email", "compareEmail")') {{ email }}
      .col(v-if = 'compare')
        span {{ compareEmail }}

  fi-data-field(:label='$t("countrySimply")')
    .row
      .col
        span(:class = 'markDiff("address.countryId", "compareAddress.countryId")') {{ classifierById('countries', address.countryId).human }}
      .col(v-if = 'compare')
        span {{ classifierById('countries', compareAddress.countryId).human }}

  fi-data-field(:label='$t("county")')
    .row
      .col
        span(:class = 'markDiff("address.countyId", "compareAddress.countyId")') {{ classifierById('counties', address.countyId).human }}
      .col(v-if = 'compare')
        span {{ classifierById('counties', compareAddress.countyId).human }}

  fi-data-field(:label='$t("city")')
    .row
      .col
        span(:class = 'markDiff("address.city", "compareAddress.city")') {{ address.city }}
      .col(v-if = 'compare')
        span {{ compareAddress.city }}

  fi-data-field(:label='$t("address")')
    .row
      .col
        span(:class = 'markDiff("address.fullAddress", "compareAddress.fullAddress")') {{ address.fullAddress }}
      .col(v-if = 'compare')
        span {{ compareAddress.fullAddress }}

  fi-data-field(:label='$t("postal")')
    .row
      .col
        span(:class = 'markDiff("address.postalCode", "compareAddress.postalCode")') {{ address.postalCode }}
      .col(v-if = 'compare')
        span {{ compareAddress.postalCode }}

  template(v-if = '!isCorporateCustomer(customer)')
    fi-data-field(:label = '$t("documentType")')
      .row
        .col
          span(:class = 'markDiff("idDocId.id", "compareIdDocId.id")') {{ idDocId.human }}
        .col(v-if = 'compare')
          span {{ compareIdDocId.human }}
    fi-data-field(:label = '$t("documentNumber")')
      .row
        .col
          span(:class = 'markDiff("idDocNumber", "compareIdDocNumber")') {{ idDocNumber }}
        .col(v-if = 'compare')
          span {{ compareIdDocNumber }}

    fi-data-field(:label = '$t("monthlyObligations")')
      span {{ balanceSummary | money }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import { mapGetters } from 'vuex'
import get from 'lodash/get'
import { customerName, findCustomerMainAddress, findCustomerMainContact } from '@/helpers'
import BigNumber from 'bignumber.js'
import FiSwitch from '@/components/FiSwitch'

export default {
  name: 'customer-data-fields',

  components: { FiDataField, FiSwitch },

  props: {
    customer: {
      type: Object,
      required: true
    },
    details: {
      type: Boolean,
      default: false
    },
    compare: {
      type: Object,
      default: null
    },
    depositContracts: {
      type: Array,
      default: () => []
    },
    factoringContracts: {
      type: Array,
      default: () => []
    },
    loanContracts: {
      type: Array,
      default: () => []
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'classifierByName']),
    ...mapGetters('customers', ['isCorporateCustomer']),
    name () {
      return customerName(this.customer)
    },
    compareName () {
      return customerName(this.compare)
    },
    address () {
      return findCustomerMainAddress(this.customer.addresses) ?? {}
    },
    compareAddress () {
      return findCustomerMainAddress(this.compare.addresses) ?? {}
    },
    email () {
      return findCustomerMainContact(this.customer.emails, this.customer.email)
    },
    compareEmail () {
      return findCustomerMainContact(this.compare.emails, this.compare.email)
    },
    phone () {
      return findCustomerMainContact(this.customer.phoneNumbers, this.customer.phoneNumber)
    },
    deskPhone () {
      return findCustomerMainContact(this.customer.deskPhoneNumbers, this.customer.deskPhone)
    },
    compareDeskPhone () {
      return findCustomerMainContact(this.compare.deskPhoneNumbers, this.compare.deskPhone)
    },
    comparePhone () {
      return findCustomerMainContact(this.compare.phoneNumbers, this.compare.phoneNumber)
    },
    showLink () {
      return this.details && this.customer.id
    },
    antiMoneyLaunderingCategory () {
      return this.classifierByName(this.customer.antiMoneyLaunderingCategory?.classifier, this.customer.antiMoneyLaunderingCategory?.code) ?? {}
    },
    compareAntiMoneyLaunderingCategory () {
      return this.classifierByName(this.compare?.antiMoneyLaunderingCategory?.classifier, this.compare?.antiMoneyLaunderingCategory?.code) ?? {}
    },
    employmentType () {
      return this.classifierByName(this.customer.employmentType?.classifier, this.customer.employmentType?.code) ?? {}
    },
    compareEmploymentType () {
      return this.classifierByName(this.compare?.employmentType?.classifier, this.compare?.employmentType?.code) ?? {}
    },
    riskCategory () {
      const { riskCategory = {} } = this.customer
      return this.classifierByName(riskCategory.classifier, riskCategory.code) ?? {}
    },
    compareRiskCategory () {
      const { riskCategory = {} } = this.compare
      return this.classifierByName(riskCategory.classifier, riskCategory.code) ?? {}
    },
    maritalStatus () {
      const { classifier = 'maritalStatus', code, id = this.customer?.privateCustomer?.maritalStatusId } = this.customer?.maritalStatus ?? {}
      return this.classifierByName(classifier, code) ?? this.classifierById(classifier, id) ?? {}
    },
    compareMaritalStatus () {
      const { classifier = 'maritalStatus', code, id = this.compare?.privateCustomer?.maritalStatusId } = this.compare?.maritalStatus ?? {}
      return this.classifierByName(classifier, code) ?? this.classifierById(classifier, id) ?? {}
    },
    dependantNumber () {
      const { dependantNumber } = this.customer.privateCustomer ?? this.customer

      return dependantNumber
    },
    compareDependantNumber () {
      const { dependantNumber } = this.compare.privateCustomer ?? this.compare

      return dependantNumber
    },
    idDocId () {
      const { idDocId } = this.customer?.privateCustomer ?? this.customer ?? {}

      return this.classifierById('idDocs', idDocId)
    },
    compareIdDocId () {
      const { idDocId } = this.compare?.privateCustomer ?? this.compare ?? {}

      return this.classifierById('idDocs', idDocId)
    },
    idDocNumber () {
      const { idDocNumber } = this.customer?.privateCustomer ?? this.customer ?? {}

      return idDocNumber
    },
    compareIdDocNumber () {
      const { idDocNumber } = this.compare?.privateCustomer ?? this.compare ?? {}

      return idDocNumber
    },
    balanceSummary () {
      let summ = 0
      if (this.depositContracts?.length) {
        summ = summ + this.depositContracts.reduce((sum, { balance = 0 }) => sum.plus(balance), new BigNumber(0)).toNumber()
      }
      if (this.factoringContracts?.length) {
        summ = summ + this.factoringContracts.reduce((sum, { balance = 0 }) => sum.plus(balance), new BigNumber(0)).toNumber()
      }
      if (this.loanContracts?.length) {
        summ = summ + this.loanContracts.reduce((sum, { balance = 0 }) => sum.plus(balance), new BigNumber(0)).toNumber()
      }
      return summ
    }
  },

  methods: {
    markDiff (val1, val2) {
      if (!this.compare) return
      return {
        'text-danger': get(this, val1) !== get(this, val2)
      }
    },
    codeWithHuman ({ code, human }) {
      if (!(code && human)) {
        return
      }
      return `${code} - ${human}`
    }
  }
}
</script>


<i18n>
en:
  application:    "Application"
  system:         "System"
  name:           "Name"
  firstName:       "First name"
  lastName:       "Last name"
  idCode:         "ID code"
  sector:         "Sector"
  segment:        "Segment"
  employmentType: "Employment type"
  aml:            "AML category"
  riskCategory:   "Risk category"
  phone:          "Mobile phone"
  deskPhone:      "Desk phone"
  email:          "E-mail"
  country:        "Country of origin"
  countrySimply:  "Country"
  county:         "County"
  city:           "City"
  address:        "Address"
  postal:         "Postal code"
  maritalStatus:  "Marital status"
  dependantNumber: "Number of dependants"
  monthlyIncome:      "Monthly income"
  monthlyObligations: "Monthly loan obligations"
  employmentLength:   "Current employment length in months"
  documentType:       "Document type"
  documentNumber:     "Document number"
  vatNumber:          "VAT number"
  emtak:              "EMTAK"
  nace:               "NACE"
  registeredCapital:  "Registered capital"
  acceptPEP:          "Accept PEP"
  sanctions:          "Sanctions"
  blacklisted:        "Blacklisted"
et:
  application:    "Application"
  system:         "System"
  name:           "Name"
  firstName:       "First name"
  lastName:       "Last name"
  idCode:         "ID code"
  sector:         "Sector"
  segment:        "Segment"
  employmentType: "Employment type"
  aml:            "AML category"
  riskCategory:   "Risk category"
  phone:          "Mobile phone"
  deskPhone:      "Desk phone"
  email:          "E-mail"
  country:        "Country of origin"
  countrySimply:  "Country"
  county:         "County"
  city:           "City"
  address:        "Address"
  postal:         "Postal code"
  maritalStatus:  "Marital status"
  dependantNumber: "Number of dependants"
  monthlyIncome:      "Monthly income"
  monthlyObligations: "Monthly loan obligations"
  employmentLength:   "Current employment length in months"
  documentType:       "Document type"
  documentNumber:     "Document number"
  vatNumber:          "VAT number"
  emtak:              "EMTAK"
  nace:               "NACE"
  registeredCapital:  "Registered capital"
  acceptPEP:          "Accept PEP"
  sanctions:          "Sanctions"
  blacklisted:        "Blacklisted"
ru:
  application:    "Application"
  system:         "System"
  name:           "Name"
  firstName:       "First name"
  lastName:       "Last name"
  idCode:         "ID code"
  sector:         "Sector"
  segment:        "Segment"
  employmentType: "Employment type"
  aml:            "AML category"
  riskCategory:   "Risk category"
  phone:          "Mobile phone"
  deskPhone:      "Desk phone"
  email:          "E-mail"
  country:        "Country of origin"
  countrySimply:  "Country"
  county:         "County"
  city:           "City"
  address:        "Address"
  postal:         "Postal code"
  maritalStatus:  "Marital status"
  dependantNumber: "Number of dependants"
  monthlyIncome:      "Monthly income"
  monthlyObligations: "Monthly loan obligations"
  employmentLength:   "Current employment length in months"
  documentType:       "Document type"
  documentNumber:     "Document number"
  vatNumber:          "VAT number"
  emtak:              "EMTAK"
  nace:               "NACE"
  registeredCapital:  "Registered capital"
  acceptPEP:          "Accept PEP"
  sanctions:          "Sanctions"
  blacklisted:        "Blacklisted"
</i18n>
