<template lang="pug">
.customer-payment-info-payment-list.card.animated.fadeIn
  .card-header {{ $t('title') }}
    router-link.float-right(
      :to = '{ name: paymentRouteName, query: { criteria: customerName(customer) } }'
    ) {{ $t('allPayments') }}
  .card-body
    b-tabs(
      content-class = 'border-0'
      pills
      v-model = 'selectedTab'
    )
      b-tab(:title = '$t("incoming")'
            lazy)
        customer-payment-info-incoming-payment-list(:brief-customer-payments-list = 'briefCustomerPaymentsList')
      b-tab(:title = '$t("outgoing")'
            lazy)
        customer-payment-info-outgoing-payment-list(:brief-customer-payments-list = 'briefCustomerOutgoingPaymentsList')

</template>


<script>
import CustomerPaymentInfoIncomingPaymentList from '@/views/customers/CustomerPaymentInfoIncomingPaymentList'
import CustomerPaymentInfoOutgoingPaymentList from '@/views/customers/CustomerPaymentInfoOutgoingPaymentList'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'customer-payment-info-payment-list',

  components: { CustomerPaymentInfoOutgoingPaymentList, CustomerPaymentInfoIncomingPaymentList },

  props: {
    selectedAccount: {
      type: String,
      default: ''
    }
  },

  i18nOptions: {},

  data: () => ({
    selectedTab: 0
  }),

  computed: {
    ...mapState('customers', ['customer', 'customerPayments', 'customerOutgoingPayments']),
    ...mapGetters('customers', ['customerName']),
    briefCustomerPaymentsList () {
      return this.customerPayments
        .filter(({ referenceNumber }) => !this.selectedAccount || this.selectedAccount === referenceNumber)
        .sort((a, b) => b.id - a.id)
        .slice(0, 5)
    },
    briefCustomerOutgoingPaymentsList () {
      return this.customerOutgoingPayments
        .filter(({ referenceNumber }) => !this.selectedAccount || this.selectedAccount === referenceNumber)
        .sort((a, b) => b.id - a.id)
        .slice(0, 5)
    },
    paymentRouteName () {
      return ['incomingPaymentsList', 'outgoingPaymentsList'][this.selectedTab]
    }
  },

  created () {
    this.loadCustomerPayments()
    this.loadCustomerOutgoingPayments()
  },

  methods: {
    ...mapActions('customers', ['loadCustomerPayments', 'loadCustomerOutgoingPayments'])
  }
}
</script>


<i18n>
en:
  title:       "Payments"
  incoming:    "Incoming"
  outgoing:    "Outgoing"
  date:        "Date"
  payerName:   "From / To"
  description: "Description"
  amount:      "Amount"
  reference:   "Reference"
  notFound:    "Payments not found"
  allPayments: "View all"
et:
  title:       "Payments"
  incoming:    "Incoming"
  outgoing:    "Outgoing"
  date:        "Date"
  payerName:   "From / To"
  description: "Description"
  amount:      "Amount"
  reference:   "Reference"
  notFound:    "Payments not found"
  allPayments: "View all"
ru:
  title:       "Payments"
  incoming:    "Incoming"
  outgoing:    "Outgoing"
  date:        "Date"
  payerName:   "From / To"
  description: "Description"
  amount:      "Amount"
  reference:   "Reference"
  notFound:    "Payments not found"
  allPayments: "View all"
</i18n>
