<template lang = "pug">
fi-modal.customer-prepayment-account-extra-income-modal(
  ref        = 'modal'
  v-model    = 'showModal'
  :title     = '$t("title")'
  ok-variant = 'secondary'
  lazy
  @hide      = 'onHide'
  @shown     = 'resetData'
)

  form(@submit.prevent = 'onSubmit')
    fi-form-field(
      :label     = '$t("loan")'
      label-cols = 3
    )
      fi-select(
        v-model   = 'formData.loanId'
        :options  = 'optionsFromCustomerLoans'
        required
        sm
      )
    fi-form-field(
      :label     = '$t("amount")'
      label-cols = 3
    )
      fi-money-input.form-control.form-control-sm(
        v-model        = 'formData.amount'
        :decimal-limit = 6
        autofocus
      )
    fi-form-field(
      :label     = '$t("description")'
      label-cols = 3
    )
      input.form-control.form-control-sm(
        v-model        = 'formData.description'
      )
  .alert-danger.alert(v-if = 'error') {{ error }}

  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'sending'
      @click.prevent = 'onSubmit'
    ) {{ $t('common:send') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'sending')
    button.btn.btn-secondary(
      :disabled      = 'sending'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t('common:cancel') }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiModal from '@/components/FiModal'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSelect from '@/components/FiSelect'
import { mapActions, mapState } from 'vuex'
import { loanStatuses } from '@/const'

export default {
  name: 'customer-prepayment-account-extra-income-modal',

  components: {
    FiModal,
    FiSelect,
    FiMoneyInput,
    FiFormField
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    accountEntry: {
      type: Object,
      required: true
    },
    reference: {
      type: String,
      required: true
    }
  },


  i18nOptions: {},

  data: () => ({
    error: '',
    formData: {
      description: null,
      amount: null,
      loanId: ''
    }
  }),

  computed: {
    ...mapState('customers', ['customerLoans']),
    showModal: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    sending () {
      return this.$vueLoading.isLoading(`customer:${this.$route.params.id}:extra-income:save`)
    },
    optionsFromCustomerLoans () {
      return this.customerLoans
        .filter(({ status, referenceNumber }) => status.id !== loanStatuses.inserted && this.reference === referenceNumber)
        .map(({
          loanId,
          agreementNumber
        }) => ({
          value: loanId,
          text: agreementNumber
        }))
    }
  },

  methods: {
    ...mapActions('customers', ['createCustomerExtraIncome']),
    onHide (e) {
      if (this.saving) e.preventDefault()
    },
    async onSubmit () {
      try {
        await this.createCustomerExtraIncome({
          customerId: this.$route.params.id,
          accountId: this.accountEntry.accountId,
          ...this.formData
        })
        this.$refs.modal.hide()
      } catch (e) {
        this.error = e
      }
    },
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.formData.amount = this.accountEntry.availableBalance
    }
  }
}
</script>


<i18n>
en:
  title:       "Extra income"
  amount:      "Amount"
  loan:        "Loan contract"
  description: "Description"
et:
  title:       "Extra income"
  amount:      "Amount"
  loan:        "Loan contract"
  description: "Description"
ru:
  title:       "Extra income"
  amount:      "Amount"
  loan:        "Loan contract"
  description: "Description"
</i18n>
