<template lang = 'pug'>
.customer-related-loans-list
  .card-header.border-top {{ full ? $t('title') : $t('titleActive') }}
  .card-body
    fi-table(
      :fields      = 'fields'
      :items       = 'loans'
      loader       = 'customer:relatedLoans:fetch'
      :empty-label = 'full ? $t("notFound") : $t("notFoundActive")'
      responsive
    )
      template(#default = '{ items }')
        customer-related-loans-list-item(
          v-for = 'loan in items'
          :key  = 'loan.id'
          :loan = 'loan'
          :full = 'full'
        )

</template>


<script>
import FiTable from '@/components/FiTable'
import CustomerRelatedLoansListItem from '@/views/customers/CustomerRelatedLoansListItem'

export default {
  name: 'customer-related-loans-list',

  components: { CustomerRelatedLoansListItem, FiTable },

  props: {
    loans: {
      type: Array,
      required: true
    },
    full: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    fields () {
      const shortFields = [
        {
          label: this.$t('contract')
        },
        {
          label: this.$t('relationType')
        },
        {
          label: this.$t('amount'),
          class: 'money'
        },
        {
          label: this.$t('status')
        }
      ]
      if (this.full) {
        shortFields.splice(2, 0, { label: this.$t('product') })
        shortFields.splice(-1, 1,
          {
            label: this.$t('validFrom')
          },
          {
            label: this.$t('validUntil')
          }
        )
      }
      return shortFields
    }
  }
}
</script>


<i18n>
en:
  titleActive:    "Active loan relations"
  notFoundActive: "Active loan relations not found"
  title:          "Loan relations"
  notFound:       "Loan relations not found"
  contract:       "Contract"
  relationType:   "Relation type"
  amount:         "Amount"
  product:        "Product"
  validFrom:      "Valid from"
  validUntil:     "Valid until"
  status:         "Status"
et:
  titleActive:    "Active loan relations"
  notFoundActive: "Active loan relations not found"
  title:          "Loan relations"
  notFound:       "Loan relations not found"
  contract:       "Contract"
  relationType:   "Relation type"
  amount:         "Amount"
  product:        "Product"
  validFrom:      "Valid from"
  validUntil:     "Valid until"
  status:         "Status"
ru:
  titleActive:    "Active loan relations"
  notFoundActive: "Active loan relations not found"
  title:          "Loan relations"
  notFound:       "Loan relations not found"
  contract:       "Contract"
  relationType:   "Relation type"
  amount:         "Amount"
  product:        "Product"
  validFrom:      "Valid from"
  validUntil:     "Valid until"
  status:         "Status"
</i18n>
