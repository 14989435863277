<template lang="pug">
tr.customer-payment-info-outgoing-payment-list-item
  td {{ $t('common:formatDate', { value: payment.paymentDate }) }}
  td {{ payment.toName }}
  td.wrap {{ payment.description }}
  td.money {{ payment.amount | money }}
  td {{ $t('common:formatDatetime', { value: payment.payableAfterDateTime }) }}
  td {{ payment.status }}
</template>


<script>
export default {
  name: 'customer-payment-info-outgoing-payment-list-item',

  props: {
    payment: {
      type: Object,
      required: true
    }
  }
}
</script>


<style lang='scss' scoped>
  .wrap {
    word-break: break-word;
  }
</style>
