<template lang="pug">
.scoring-request-progress-bar.progress.progress-xs
  transition(name = 'slide')
    .progress-bar(v-if   = 'startTransition'
                  :class = 'className'
                  :style = '{ width: percent }')
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'scoring-request-progress-bar',

  props: {
    request: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      startTransition: false
    }
  },

  computed: {
    ...mapGetters('scoring', ['scoringModelById']),
    model () {
      return this.scoringModelById(this.request.scoringModelId)
    },
    className () {
      if (this.request.finalScore <= this.model.warningScoreValue) {
        return 'bg-danger'
      } else if (this.request.finalScore < this.model.positiveScoreValue) {
        return 'bg-warning'
      } else {
        return 'bg-success'
      }
    },
    percent () {
      const start = this.model.warningScoreValue
      const end = this.model.positiveScoreValue
      const k = (end - start) / 50
      const percent = 50 + (this.request.finalScore - start) / k
      return percent < 50 ? '50%' : `${Math.round(percent)}%`
    }
  },

  mounted () {
    setTimeout(() => {
      this.startTransition = true
    }, 500)
  }
}
</script>


<style scoped lang="scss">
.progress-bar {
  transform-origin: left;
  transition: transform 0.5s ease-in-out;
}
.slide-enter{
  transform: scaleX(0);
}
</style>
