<template lang="pug">
tr.customer-prepayment-account-list-item
  td {{ account.number }}
  td: router-link(:to = '{ name: "Loans", query: { criteria: account.referenceNumber } }') {{ account.referenceNumber }}
  //- td.money {{ accountEntry.availableBalance | money }}
  td.money {{ account.balance | money }}
  td.money {{ account.bookedAmount | money }}
  td.text-center
    button.btn.btn-link.p-0(@click.prevent.stop = 'showTransactionsModal = true') {{ $t('viewTransactions') }}
  td.text-center
    button.btn.btn-link.p-0(@click.prevent.stop = 'showPayoutModal = true') {{ $t('payout') }}
  td.text-center
    button.btn.btn-link.p-0(@click.prevent = 'showExtraIncomeModal = true') {{ $t('extraIncome') }}
  customer-prepayment-account-transactions-modal(
    v-model     = 'showTransactionsModal'
    :account-id = 'account.id'
  )
  customer-prepayment-account-payback-modal(
    v-if           = 'showPayoutModal'
    v-model        = 'showPayoutModal'
    :account-entry = 'accountEntry'
    :account       = 'account'
  )
  customer-prepayment-account-extra-income-modal(
    v-model        = 'showExtraIncomeModal'
    :reference     = 'account.referenceNumber'
    :account-entry = 'accountEntry'
  )
</template>


<script>
import CustomerPrepaymentAccountExtraIncomeModal from '@/views/customers/CustomerPrepaymentAccountExtraIncomeModal'
import CustomerPrepaymentAccountPaybackModal from '@/views/customers/CustomerPrepaymentAccountPayoutModal'
import CustomerPrepaymentAccountTransactionsModal from './CustomerPrepaymentAccountTransactionListModal'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'customer-prepayment-account-list-item',

  components: { CustomerPrepaymentAccountExtraIncomeModal, CustomerPrepaymentAccountPaybackModal, CustomerPrepaymentAccountTransactionsModal },

  props: {
    account: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      showTransactionsModal: false,
      showPayoutModal: false,
      showExtraIncomeModal: false
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierByName']),
    ...mapState('settings', ['settings']),
    accountEntry () {
      return this.account.accountEntries.find(entry => entry.currencyId === this.classifierByName('currencies', this.settings.currency.toUpperCase())?.id) || this.account.accountEntries[0]
    }
  }
}
</script>


<i18n>
en:
  viewTransactions: "View transactions"
  payout:           "Pay out"
  extraIncome:      "Extra income"
et:
  viewTransactions: "View transactions"
  payout:           "Pay out"
  extraIncome:      "Extra income"
ru:
  viewTransactions: "View transactions"
  payout:           "Pay out"
  extraIncome:      "Extra income"
</i18n>


<style scoped lang='scss'>
  .customer-prepayment-account-list-item {
    cursor: pointer;
  }
</style>
