<template lang="pug">
.card.customer-contract-list.animated.fadeIn
  .card-header
    slot(name = 'header') {{ title }}
  .card-body
    fi-table(
      :items       = 'contracts'
      :fields      = 'fields'
      :loader      = 'loader'
      :empty-label = '$t("notFound")'
      responsive
      fixed
    )
      template(#default = '{ items }')
        slot(
          v-for = 'contract in contracts'
          name  = 'contractItem'
          :contract = 'contract'
        )
      template(#custom-footer)
        tr.font-weight-bold(v-if = 'summary')
          td(v-if = 'title === "Active loan contracts" || "Active deposit contracts"')(colspan = 3)
          td(v-else)(colspan = 2)
          td.money(v-if = 'full') {{ amountSummary | money }}
          td(v-else)
          td.money {{ balanceSummary | money }}
          td.money {{ debtSummary | money }}
          td(colspan = 7)
</template>


<script>
import { mapState } from 'vuex'
import FiTable from '@/components/FiTable'
import BigNumber from 'bignumber.js'

export default {
  name: 'customer-contract-list',

  components: {
    FiTable
  },

  props: {
    contracts: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    full: Boolean,
    summary: Boolean,
    loader: {
      type: String,
      default: null
    }
  },

  computed: {
    ...mapState('customers', ['customer']),
    fields () {
      return [
        { label: this.$t('contract') },
        { label: this.$t('creditIssuer') },
        { label: this.$t('product') },
        ...this.full ? [{
          label: this.$t('amount'),
          class: 'money'
        }] : [],
        {
          label: this.$t('balance'),
          class: 'money'
        },
        {
          label: this.$t('debt'),
          class: 'money'
        },
        { label: this.$t('startDate') },
        { label: this.$t('endDate') },
        {
          key: 'reference',
          label: this.$t('reference')
        },
        {
          label: this.$t('nextPayment'),
          class: 'money'
        },
        { label: this.$t('nextPaymentDate') },
        { label: this.$t('updatedAt') },
        { label: this.$t('status') }
      ]
    },
    amountSummary () {
      return this.contracts.reduce((sum, { amount = 0, initialAmount = 0 }) => sum.plus(amount).plus(initialAmount), new BigNumber(0)).toNumber()
    },
    balanceSummary () {
      return this.contracts.reduce((sum, { balance = 0 }) => sum.plus(balance), new BigNumber(0)).toNumber()
    },
    debtSummary () {
      return this.contracts.reduce((sum, { debts = 0 }) => sum.plus(debts), new BigNumber(0)).toNumber()
    }
  }
}
</script>


<i18n>
en:
  contract:        "Contract"
  creditIssuer:    "Credit Issuer"
  product:         "Product"
  amount:          "Amount"
  startDate:       "Start Date"
  endDate:         "End Date"
  balance:         "Balance"
  debt:            "Debt"
  reference:       "Reference number"
  nextPayment:     "Next Payment"
  nextPaymentDate: "Next payment date"
  updatedAt:       "Last updated"
  status:          "Status"
  notFound:        "Contracts not found"
et:
  contract:        "Contract"
  creditIssuer:    "Credit Issuer"
  product:         "Product"
  amount:          "Amount"
  startDate:       "Start Date"
  endDate:         "End Date"
  balance:         "Balance"
  debt:            "Debt"
  reference:       "Reference number"
  nextPayment:     "Next Payment"
  nextPaymentDate: "Next payment date"
  updatedAt:       "Last updated"
  status:          "Status"
  notFound:        "Contracts not found"
ru:
  contract:        "Contract"
  creditIssuer:    "Credit Issuer"
  product:         "Product"
  amount:          "Amount"
  startDate:       "Start date"
  endDate:         "End date"
  balance:         "Balance"
  debt:            "Debt"
  reference:       "Reference number"
  nextPayment:     "Next Payment"
  nextPaymentDate: "Next payment date"
  updatedAt:       "Last updated"
  status:          "Status"
  notFound:        "Contracts not found"
</i18n>
