<template lang="pug">
.card.animated.fadeIn.customer-new-brief
  .card-body
    form.form(
      @submit.prevent = 'onSubmit'
      novalidate
    )
      .form-row
        .col-lg-6
          template(v-if = 'isPrivateCustomer')
            fi-form-field(:state = '!$v.customerData.firstName.$error' :label = '$t("firstName")')
              input.form-control-sm.form-control(
                :placeholder = '$t("firstName")'
                type         = 'text'
                v-model.trim = 'customerData.firstName'
                :class       = '{ "is-invalid": $v.customerData.firstName.$error }'
              )
              template(#error)
                .error-message(v-if = '!$v.customerData.firstName.required') {{ $t('common:required') }}

            fi-form-field(:state = '!$v.customerData.lastName.$error' :label = '$t("lastName")')
              input.form-control.form-control-sm(
                v-model.trim = 'customerData.lastName'
                :placeholder = '$t("lastName")'
                type         = 'text'
                :class       = '{ "is-invalid": $v.customerData.lastName.$error }'
              )
              template(#error)
                .error-message(v-if = '!$v.customerData.lastName.required') {{ $t('common:required') }}
          template(v-else)
            fi-form-field(:state = '!$v.customerData.customerName.$error' :label = '$t("customerName")')
              input.form-control.form-control-sm(
                v-model.trim = 'customerData.customerName'
                :placeholder = '$t("customerName")'
                type         = 'text'
                :class       = '{ "is-invalid": $v.customerData.customerName.$error }'
              )
              template(#error)
                .error-message(v-if = '!$v.customerData.customerName.required') {{ $t('common:required') }}
          fi-form-field(:state = '!$v.customerData.countryId.$error' :label = '$t("country")')
            fi-select(
              v-model.number = 'customerData.countryId'
              :options       = 'optionsFromClassifier("countries")'
              :class         = '{ "is-invalid": $v.customerData.countryId.$error }'
              :placeholder   = '$t("country")'
              sm
            )
            template(#error)
              .error-message(v-if = '!$v.customerData.countryId.required') {{ $t('common:required') }}

          fi-form-field(:state = '!$v.customerData.idCode.$error' :label = '$t("idCode")')
            input.form-control.form-control-sm(
              v-model.trim = 'customerData.idCode'
              :placeholder = '$t("idCode")'
              type         = 'text'
              :class       = '{ "is-invalid": $v.customerData.idCode.$error }'
            )
            template(#error)
              .error-message(v-if = '!$v.customerData.idCode.required') {{ $t('common:required') }}

          fi-form-field(:state = '!$v.customerData.sectorId.$error' :label = '$t("sector")')
            fi-select.form-control.form-control-sm(
              v-model.number = 'customerData.sectorId'
              :options       = 'sectorOptions'
              :placeholder   = '$t("sector")'
              :class         = '{ "is-invalid": $v.customerData.sectorId.$error }'
              required
            )
            template(#error)
              .error-message(v-if = '!$v.customerData.sectorId.required') {{ $t('common:required') }}
          fi-form-field(:state = '!$v.customerData.segmentId.$error' :label = '$t("segment")')
            fi-select.form-control.form-control-sm(
              v-model.number = 'customerData.segmentId'
              :options       = 'optionsFromClassifier("segments")'
              :placeholder   = '$t("segment")'
              :class         = '{ "is-invalid": $v.customerData.segmentId.$error }'
              required
            )
            template(#error)
              .error-message(v-if = '!$v.customerData.segmentId.required') {{ $t('common:required') }}
        .col-lg-6
          fi-form-field(:state = '!$v.customerData.phoneNumbers.$error' :label = '$t("addPhone")')
            template(#error)
              .error-message(v-if = '!$v.customerData.phoneNumbers.required') {{ $t('common:required') }}
            customer-contact-list(
              v-model    = 'customerData.phoneNumbers'
              :add-label = '$t("addPhone")'
              name       = 'phoneContact'
              brief
              allow-main
            )
              template(#input="{ contact, onInput }")
                fi-phone-input.form-control-sm.form-control.animated.fadeIn(
                  :value     = 'contact'
                  :countries = 'countries'
                  :class     = '{ "is-invalid": $v.customerData.phoneNumbers.$error }'
                  @input     = 'onInput'
                )

          fi-form-field(:state = '!$v.customerData.deskPhoneNumbers.$error' :label = '$t("addDeskPhone")')
            template(#error)
              .error-message(v-if = '!$v.customerData.deskPhoneNumbers.required') {{ $t('common:required') }}
            customer-contact-list(
              v-model    = 'customerData.deskPhoneNumbers'
              :add-label = '$t("addDeskPhone")'
              name       = 'phoneContact'
              brief
            )
              template(#input="{ contact, onInput }")
                fi-phone-input.form-control-sm.form-control.animated.fadeIn(
                  :value     = 'contact'
                  :countries = 'countries'
                  :class     = '{ "is-invalid": $v.customerData.deskPhoneNumbers.$error }'
                  @input     = 'onInput'
                )

          fi-form-field(:state = '!$v.customerData.emails.$error' :label = '$t("email")')
            template(#error)
              .error-message(v-if = '!$v.customerData.emails.required') {{ $t('common:required') }}
              .error-message(v-if = '!$v.customerData.emails.email') {{ $t('emailError') }}
            customer-contact-list(
              v-model      = 'customerData.emails'
              :add-label   = '$t("addEmail")'
              :input-class = '{ "is-invalid": $v.customerData.emails.$error }'
              :placeholder = '$t("email")'
              name         = 'emailContact'
              allow-main
              brief
            )

      .alert.alert-danger.animated.fadeIn(v-if = 'error') {{ error }}
      .form-row.justify-content-end
        button.btn.btn-primary.mr-2(
          type       = 'submit'
          :disabled  = 'saving'
        )
          | {{ $t("common:save") }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = '$emit("cancel")'
        ) {{ $t("common:cancel") }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiPhoneInput from '@/components/FiPhoneInput'
import { mapActions, mapGetters } from 'vuex'
import { rules, validation, validators } from '@/validation'
import pick from 'lodash/pick'
import FiSelect from '@/components/FiSelect'
import FiMultiselect from '@/components/FiMultiselect'
import { codeWithHuman } from '@/mixins'
import CustomerContactList from '@/views/customers/CustomerContactList'

export default {
  name: 'customer-new-brief',

  components: { CustomerContactList, FiMultiselect, FiSelect, FiPhoneInput, FiFormField },

  mixins: [validation, codeWithHuman],

  props: {
    privateCustomer: {
      type: Boolean,
      default: false
    },
    relatedPerson: {
      type: Object,
      default: null
    }
  },

  i18nOptions: {},

  data () {
    return {
      error: null,
      customerData: {
        customerName: null,
        firstName: null,
        lastName: null,
        idCode: null,
        sectorId: '',
        segmentId: '',
        emails: [],
        phoneNumbers: [],
        deskPhoneNumbers: [],
        countryId: ''
      }
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierByName', 'classifierList', 'optionsFromClassifier', 'classifierById']),
    saving () {
      return this.$vueLoading.isLoading('customer:system:save')
    },
    sectorOptions () {
      return this.optionsFromClassifier('sectors')
        .filter(sector => this.privateCustomer || sector.value !== this.classifierByName('sectors', 'PRIVATE_CUSTOMER')?.id || sector.value !== this.classifierByName('sectors', 'EMPLOYEES')?.id)
    },
    countries () {
      return Object.values(this.classifierList('countries')).map(({ name }) => name)
    },
    isPrivateCustomer () {
      return /PRIVATE_CUSTOMER/i.test(this.classifierById('sectors', this.customerData.sectorId).name) || /EMPLOYEES/i.test(this.classifierById('sectors', this.customerData.sectorId).name)
    }
  },

  validations () {
    return {
      customerData: {
        ...pick(rules.customerRules, Object.keys(this.customerData)),
        firstName: {
          required: validators.requiredIf(this.privateCustomer)
        },
        lastName: {
          required: validators.requiredIf(this.privateCustomer)
        },
        customerName: {
          required: validators.requiredIf(!this.privateCustomer)
        }
      }
    }
  },

  mounted () {
    if (this.privateCustomer) {
      this.customerData.sectorId = this.classifierByName('sectors', 'PRIVATE_CUSTOMER')?.id
    }
    if (this.relatedPerson) {
      if (this.relatedPerson.companyName) {
        const { contactEmail, contactPhone, registrationCode, registrationCountry, companyName } = this.relatedPerson
        this.customerData.customerName = companyName
        this.customerData.idCode = registrationCode
        if (contactEmail) {
          this.customerData.emails.push({ main: true, value: contactEmail })
        }
        if (contactPhone) {
          this.customerData.phoneNumbers.push({ main: true, value: contactPhone })
        }
        this.customerData.countryId = registrationCountry
      } else {
        const { contactEmail, contactPhone, firstName, lastName, registrationCode, registrationCountry } = this.relatedPerson
        this.customerData.firstName = firstName
        this.customerData.lastName = lastName
        this.customerData.idCode = registrationCode
        if (contactEmail) {
          this.customerData.emails.push({ main: true, value: contactEmail })
        }
        if (contactPhone) {
          this.customerData.phoneNumbers.push({ main: true, value: contactPhone })
        }
        this.customerData.countryId = registrationCountry
      }
    }

    this.customerData.segmentId = this.classifierByName('segments', 'REGULAR')?.id
  },

  methods: {
    ...mapActions('customers', ['createSystemCustomer']),
    async onSubmit () {
      try {
        this.error = null
        if (this.validate()) {
          const customer = await this.createSystemCustomer({ customerData: this.customerData })
          this.$emit('submit', { customer })
        }
      } catch (error) {
        this.error = error
      }
    }
  }
}
</script>


<i18n>
en:
  firstName:    "First name"
  lastName:     "Last name"
  idCode:       "Id code"
  email:        "Email"
  addPhone:     "Mobile phone"
  addDeskPhone: "Desk phone"
  sector:       "Sector"
  segment:      "Segment"
  customerName: "Customer name"
  emailError:   "Incorrect email format"
  country:      "Country"
et:
  firstName:    "First name"
  lastName:     "Last name"
  idCode:       "Id code"
  email:        "Email"
  addPhone:     "Mobile phone"
  addDeskPhone: "Desk phone"
  sector:       "Sector"
  segment:      "Segment"
  customerName: "Customer name"
  emailError:   "Incorrect email format"
  country:      "Country"
ru:
  firstName:    "First name"
  lastName:     "Last name"
  idCode:       "Id code"
  email:        "Email"
  addPhone:     "Mobile phone"
  addDeskPhone: "Desk phone"
  sector:       "Sector"
  segment:      "Segment"
  customerName: "Customer name"
  emailError:   "Incorrect email format"
  country:      "Country"
</i18n>


<style lang='scss' scoped>
.customer-new-brief {
  box-shadow:0 2px 1px rgba(0,0,0,0.3)
}
</style>
