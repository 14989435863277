<template lang="pug">
.application-corporate-annual-report-list-item.content-column
  .cell.border-bottom
      .btn-group.btn-group-sm
        span.mr-2.font-weight-bold {{ report.reportYear }}
        button.btn.btn-link.p-0(@click.prevent = 'showEditReport = true')
          i.far.fa-edit.fa-fw.fa-lg
        button.btn.btn-link.p-0(
          v-if = '$route.name !== "CustomerOverview"'
          @click.prevent = '$emit("remove", {report})'
        )
          i.far.fa-trash-alt.fa-lg.text-danger
  .cell.border-bottom {{ '-' }}
  .cell.border-bottom  {{ report.fixedAssets || 0 }}
  .cell.border-bottom  {{ report.intangibles || 0 }}
  .cell.border-bottom  {{ report.property || 0 }}
  .cell.border-bottom  {{ report.longTermInvestments || 0}}
  .cell.border-bottom  {{ report.otherFixedAssets || 0 }}
  .cell.border-bottom  {{ report.currentAssets || 0 }}
  .cell.border-bottom  {{ report.inventories || 0 }}
  .cell.border-bottom  {{ report.accountsReceivable || 0 }}
  .cell.border-bottom  {{ report.shortTermInvestments || 0 }}
  .cell.border-bottom  {{ report.cash || 0 }}
  .cell.border-bottom  {{ report.accruedIncomeDeferredExpenses || 0 }}
  .cell.border-bottom  {{ report.totalAssets || 0 }}
  .cell.border-bottom  {{ '-' }}
  .cell.border-bottom  {{ report.equity || 0 }}
  .cell.border-bottom  {{ report.addedCapital || 0}}
  .cell.border-bottom  {{ report.reserves || 0 }}
  .cell.border-bottom  {{ report.retainedEarnings || 0 }}
  .cell.border-bottom  {{ report.subsidie || 0 }}
  .cell.border-bottom  {{ report.provisions || 0 }}
  .cell.border-bottom  {{ '-' }}
  .cell.border-bottom {{ report.longTermLiabilities || 0 }}
  .cell.border-bottom {{ report.longTermDebts || 0 }}
  .cell.border-bottom {{ report.longTermAccountsPayable || 0 }}
  .cell.border-bottom {{ report.longTermOther || 0 }}
  .cell.border-bottom {{ '-' }}
  .cell.border-bottom {{ report.shortTermLiabilities || 0 }}
  .cell.border-bottom {{ report.shortTermDebts || 0 }}
  .cell.border-bottom {{ report.shortTermAccountsPayable || 0}}
  .cell.border-bottom {{ report.shortTermOther || 0 }}
  .cell.border-bottom {{ report.deferredIncome || 0 }}
  .cell.border-bottom {{ '-' }}
  .cell.border-bottom {{ report.salesRevenue || 0 }}
  .cell.border-bottom {{ report.costOfSales || 0 }}
  .cell.border-bottom {{ report.operatingExpenses || 0 }}
  .cell.border-bottom {{ report.adminExpenses || 0 }}
  .cell.border-bottom {{ report.operatingProfit || 0}}
  .cell.border-bottom {{ report.otherIncome || 0 }}
  .cell.border-bottom {{ report.otherExpenses || 0 }}
  .cell.border-bottom {{ report.interestExpense || 0 }}
  .cell.border-bottom {{ report.profitBeforeTax || 0 }}
  .cell.border-bottom {{ report.taxesPaid || 0 }}
  .cell.border-bottom {{ report.profitLoss || 0 }}
  .cell.border-bottom {{ report.depreciation || 0 }}
  .cell {{ report.amortization || 0 }}
  application-corporate-annual-report-modal(
    v-model = 'showEditReport'
    :report = 'report'
    @submit = '$emit("edit", $event)'
  )
</template>


<script>
import ApplicationCorporateAnnualReportModal from '@/views/applications/ApplicationCorporateAnnualReportModal'
import FiDataField from '@/components/FiDataField'

export default {
  name: 'application-corporate-annual-report-list-item',

  components: { FiDataField, ApplicationCorporateAnnualReportModal },

  props: {
    report: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    showEditReport: false
  })
}
</script>


<style lang="scss" scoped>
.content-column {
  display: flex;
  flex-direction: column;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
.cell {
  min-height: 22px;
  padding-inline: 15px;
  margin-inline: 15px;
}
.gray-divider {
  width: 100%;
  height: 2px;
  background-color: #a4b7c1;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, .125);
}
</style>
