<template lang="pug">
tr.customer-application-list-item
  td: router-link(:to = '{ name: "Application", params: { id: application.id.toString() }}') {{ application.id }}
  td {{ product }}
  td.money {{ application.creditAmount | money }}
  td {{ $t('common:formatDate', { value: application.createdAt }) }}
  td {{ manager }}
  td: .badge(:class = '`badge-${applicationStatusColor(application.statusId)}`')  {{ status.human }}
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'customer-application-list-item',

  props: {
    application: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('products', ['productById']),
    ...mapGetters('classifiers', ['classifierById', 'managerById']),
    ...mapGetters('applications', ['applicationStatusColor']),
    product () {
      return this.productById(this.application.productId).name
    },
    status () {
      return this.classifierById('creditApplicationStatuses', this.application.statusId)
    },
    manager () {
      return this.managerById(this.application.employeeId).name
    }
  }
}
</script>
