<template lang="pug">
tr.customer-debt-list-item
  td: router-link(:to = '{ name: "Loan", params: { id: debt.loanId } }') {{ debt.agreementNumber }}
  td {{ debt.type.human }}
  td.money {{ debt.amount | money }}
  td {{ $t('common:formatDate', { value: debt.paymentDate }) }}
  td {{ debt.debtDays }}
  td: .badge(:class = '`badge-${loanInvoiceStatusColor(debt.status)}`') {{ debt.status.human }}
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'customer-debt-list-item',

  props: {
    debt: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierByName']),
    ...mapGetters('loans', ['loanInvoiceStatusColor'])
  }
}
</script>
