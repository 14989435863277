<template lang="pug">
.customer-view
  v-loading(loader = 'customer:fetch')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg

    .animated.fadeIn(v-if = 'customer')
      .customer-view-header
        h3 {{ customerName(customer) }} ({{ customer.idCode }})
        ul.nav.mt-2.mb-2
          li.nav-item
            router-link.nav-link.pl-0(
              active-class = 'text-dark'
              :to          = '{ name: "CustomerOverview" }'
            ) {{ $t('overview') }}
          li.nav-item
            router-link.nav-link(
              active-class = 'text-dark'
              :to          = '{ name: "CustomerGeneral" }'
            ) {{ $t('general') }}
          li.nav-item
            router-link.nav-link(
              active-class = 'text-dark'
              :to          = '{ name: "CustomerRoles" }'
            ) {{ $t('roles') }}
          li.nav-item
            router-link.nav-link(
              active-class = 'text-dark'
              :to          = '{ name: "CustomerCommunication" }'
            ) {{ $t('communication') }}
          li.nav-item
            router-link.nav-link(
              active-class = 'text-dark'
              :to          = '{ name: "CustomerLoans" }'
            ) {{ $t('contracts') }}
          li.nav-item
            router-link.nav-link(
              active-class = 'text-dark'
              :to          = '{ name: "CustomerPaymentInfo" }'
            ) {{ $t('paymentInfo') }}
          li.nav-item
            router-link.nav-link(
              active-class = 'text-dark'
              :to          = '{ name: "CustomerReports" }'
            ) {{ $t('reports') }}

      router-view
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'customer-view',

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapState('customers', ['customer']),
    ...mapGetters('customers', ['customerName'])
  },

  watch: {
    id: {
      handler: 'loadData',
      immediate: true
    }
  },

  methods: {
    ...mapActions('customers', [
      'loadCustomer',
      'loadCustomerRelations'
    ]),
    ...mapActions('products', ['loadProducts']),
    async loadData (customerId) {
      await this.loadCustomer({ id: customerId })
      await this.loadCustomerRelations({ customerId })
      await this.loadProducts({ group: ['LOAN', 'OVERDRAFT', 'LEASING', 'GUARANTEE', 'FACTORING', 'DEPOSIT'] })
    }
  }
}
</script>


<i18n>
en:
  overview:      "Overview"
  general:       "General"
  roles:         "Roles"
  communication: "Communication"
  contracts:     "Contracts"
  paymentInfo:   "Payment info"
  reports:       "Reports"
et:
  overview:      "Overview"
  general:       "General"
  roles:         "Roles"
  communication: "Communication"
  contracts:     "Contracts"
  paymentInfo:   "Payment info"
  reports:       "Reports"
ru:
  overview:      "Overview"
  general:       "General"
  roles:         "Roles"
  communication: "Communication"
  contracts:     "Contracts"
  paymentInfo:   "Payment info"
  reports:       "Reports"
</i18n>


<style lang='scss' scoped>
  .customer-view-header {
    top: 101px;
    background-color: #e4e5e6;
    position: sticky;
    z-index: 10;
  }
</style>
