<template lang="pug">
.application-paid-taxes-list.card
  .card-header {{ $t('title') }}
    //- button.btn.btn-link.fa-pull-right.py-0.animated.fadeIn(
    //-   :title         = '$t("common:add")'
    //-   @click.prevent = 'showAddThirdParty = true'
    //- ): i.fas.fa-user-plus.fa-lg
  .card-body
    fi-table(
      :fields      = 'fields'
      :items       = 'paidTaxes'
      :empty-label = '$t("notFound")'
    )
      template(#default = '{ items }')
        application-paid-taxes-list-item(
          v-for        = '(item, index) in items'
          :key         = 'index'
          :product     = 'product'
          :application = 'sourceData'
          :paid-taxes = 'item'
        )
</template>


<script>
import FiTable from '@/components/FiTable'
import ApplicationPaidTaxesListItem
  from '@/views/applications/ApplicationFactoring/ApplicationPaidTaxesListItem'

export default {
  name: 'application-paid-taxes-list',

  components: { ApplicationPaidTaxesListItem, FiTable },

  props: {
    paidTaxes: {
      type: Array,
      default: () => []
    },
    sourceData: {
      type: Object,
      default: () => {}
    },
    product: {
      type: Object,
      default: () => {}
    }
  },

  i18nOptions: {},

  data: () => ({
    showAddThirdParty: false
  }),

  computed: {
    fields () {
      return [
        {
          key: 'year',
          label: this.$t('year')
        },
        {
          key: 'quarter',
          label: this.$t('quarter')
        },
        {
          key: 'turnover',
          label: this.$t('turnover')
        },
        {
          key: 'paidTaxes',
          label: this.$t('paidTaxes')
        },
        {
          key: 'employeeTaxes',
          label: this.$t('employeeTaxes')
        },
        {
          key: 'employees',
          label: this.$t('employees')
        }
      ]
    }
  }
}
</script>


<i18n>
en:
  title:         "Paid taxes data"
  notFound:      "No paid taxes"
  year:          "Year"
  quarter:       "Quarter"
  turnover:      "Turnover"
  paidTaxes:     "Paid taxes"
  employeeTaxes: "Employee taxes"
  employees:     "Employees"
et:
  title:         "Paid taxes data"
  notFound:      "No paid taxes"
  year:          "Year"
  quarter:       "Quarter"
  turnover:      "Turnover"
  paidTaxes:     "Paid taxes"
  employeeTaxes: "Employee taxes"
  employees:     "Employees"
ru:
  title:         "Paid taxes data"
  notFound:      "No paid taxes"
  year:          "Year"
  quarter:       "Quarter"
  turnover:      "Turnover"
  paidTaxes:     "Paid taxes"
  employeeTaxes: "Employee taxes"
  employees:     "Employees"
</i18n>
