<template lang="pug">
tr.customer-role-list-item
  td {{ classifierById('roles', role.roleTypeId).human }}
  td
    template(v-if = 'role.validFrom') {{ $t('common:formatDate', { value: role.validFrom }) }}
    template(v-else) ∞
  td
    template(v-if = 'role.validTo') {{ $t('common:formatDate', { value: role.validTo }) }}
    template(v-else) ∞
  td
    .fa-pull-right(v-if = 'removing')
      | {{ $t('common:removing') }}
      | &nbsp;
      i.fa.fa-spinner.fa-pulse.fa-lg
    .fa-pull-right(v-else)
      button.btn.btn-sm.btn-link(@click.prevent = 'editing = true'): i.far.fa-edit.fa-lg
      | &nbsp;
      button.btn.btn-sm.btn-link.text-danger(
        :disabled    = 'removing'
        v-fi-confirm = '{ ok: remove, message: $t("removeConfirm") }'
      ): i.far.fa-trash-alt.fa-lg
  customer-role-new-modal(v-model = 'editing'
                          :role = 'role')
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import CustomerRoleNewModal from './CustomerRoleNewModal'

export default {
  name: 'customer-role-list-item',

  components: {
    CustomerRoleNewModal
  },

  props: {
    role: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      editing: false
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    removing () {
      return this.$vueLoading.isLoading(`customer:role:${this.role.id}:remove`)
    }
  },

  methods: {
    ...mapActions('customers', ['removeCustomerRole']),
    async remove ({ done }) {
      await this.removeCustomerRole({ id: this.role.id })
      done()
    }
  }
}
</script>


<i18n>
en:
  removeConfirm:  "Are you sure you want to delete this role?"

et:
  removeConfirm:  "Are you sure you want to delete this role?"

ru:
  removeConfirm:  "Are you sure you want to delete this role?"
</i18n>
