<template lang="pug">
.customer-contact-list
  .input-group.input-group-sm
    slot(
      name = 'input'
      :contact = 'contact'
      :onInput = 'value => contact = value'
    )
      input.form-control.form-control-sm.animated.fadeIn(
        v-model.trim = 'contact'
        v-bind       = '$attrs'
        :class       = 'inputClass'
        type         = 'text'
      )
    .input-group-append
      button.btn.btn-sm.btn-primary(
        :disabled      = '!contact'
        :title         = '$t("add")'
        @click.prevent = 'addContact'
      )
        i.far.fa-plus-square.fa-lg
  c-list-group
    c-list-group-item.p-1(
      v-for = '(contact, index) in contactList'
      :key  = 'index'
    )
      .col.d-flex.align-items-center.justify-content-between
        label.mb-0 {{ contact.value }}
          b-radio.fa-pull-left(
            v-if    = 'allowMain'
            v-model = 'contact.main'
            type    = 'radio'
            :name   = '$attrs.name'
            :value  = 'allowMain'
            @change = 'setDefaultContact(index)'
          )
        button.btn.btn-sm.btn-link.text-danger.fa-pull-right(@click.prevent = 'removeContact(index)')
          i.far.fa-trash-alt.fa-lg
</template>

<script>
import { CButton, CListGroup, CListGroupItem } from '@coreui/vue'

const contactModel = {
  id: '',
  main: false,
  value: ''
}

export default {
  name: 'customer-contact-list',

  components: {
    CListGroup,
    CListGroupItem,
    CButton
  },

  inheritAttrs: false,

  model: {
    prop: 'contactList'
  },

  props: {
    contactList: {
      type: Array,
      required: true
    },
    addLabel: {
      type: String,
      default: ''
    },
    brief: Boolean,
    allowMain: Boolean,
    inputClass: {
      type: [Object, Array, String],
      default: null
    }
  },

  data: () => ({
    contact: ''
  }),

  methods: {
    addContact () {
      this.$emit('input', [
        ...this.contactList,
        {
          ...contactModel,
          main: this.allowMain && !this.contactList.some(({ main }) => main),
          value: this.contact
        }
      ])
      this.contact = ''
    },
    setDefaultContact (index) {
      this.$emit('input', this.contactList.map((contact, idx) => ({
        ...contact,
        main: idx === index
      })))
    },
    removeContact (index) {
      this.$emit(
        'input',
        this.contactList
          .filter((_, idx) => idx !== index)
          .map((contact, idx, arr) => ({
            ...contact,
            main: !arr.some(({ main }) => main)
          }))
      )
    }
  }
}
</script>

<style scoped>

</style>
