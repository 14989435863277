<template lang="pug">
.card.customer-corporate-profile
  .card-header
    | {{ $t('title') }}
    a.fa-pull-right(
      v-if           = '!editing'
      href           = '#'
      @click.prevent = 'toggleEditing'
    ) {{ $t('common:edit') }}
  .card-body
    form.form-horizontal(@submit.prevent = 'onSubmit')
      template(v-if = 'editing')
        fi-form-field(:label = '$t("nace")')
          fi-multiselect(
            track-by         = 'id'
            label            = 'human'
            v-model          = 'selectedNACEModel'
            :options         = 'Object.values(classifierList("nace"))'
            :search-callback = 'loadClassifiersNACE'
            :loading         = '$vueLoading.isLoading("classifiers:nace:fetch")'
            :custom-label    = 'codeWithHuman'
          )
            template(#option = '{ option }')  {{ option.code }} - {{ option.human }}
        fi-form-field(:label = '$t("emtak")')
          fi-multiselect(
            track-by         = 'id'
            label            = 'human'
            v-model          = 'selectedEMTAKModel'
            :options         = 'Object.values(classifierList("emtak"))'
            :search-callback = 'loadClassifiersEMTAK'
            :loading         = '$vueLoading.isLoading("classifiers:emtak:fetch")'
            :custom-label    = 'codeWithHuman'
          )
            template(#option = '{ option }')  {{ option.code }} - {{ option.human }}
        fi-form-field(:label = '$t("vatNumber")')
          input.form-control.form-control-sm(
            v-model.trim = 'corporateCustomerData.vatNumber'
            type         = 'text'
          )
        fi-form-field(:label = '$t("turnover")')
          input.form-control.form-control-sm(
            v-model.number = 'corporateCustomerData.turnover'
            type           = 'text'
          )
        fi-form-field(:label = '$t("numberOfEmployees")')
          input.form-control.form-control-sm(
            v-model.trim = 'corporateCustomerData.numberOfEmployees'
            type         = 'text'
          )
        fi-form-field(:label = '$t("registeredCapital")')
          input.form-control.form-control-sm(
            v-model.number = 'corporateCustomerData.registeredCapital'
            type         = 'number'
          )
      template(v-else)
        fi-data-field(:label = '$t("nace")') {{ codeWithHuman(classifierById('nace', corporateCustomer.naceId)) }}
        fi-data-field(:label = '$t("emtak")') {{ codeWithHuman(classifierById('emtak', corporateCustomer.emtakId)) }}
        fi-data-field(:label = '$t("vatNumber")') {{ corporateCustomer.vatNumber }}
        fi-data-field(:label = '$t("turnover")') {{ corporateCustomer.turnover | money }}
        fi-data-field(:label = '$t("numberOfEmployees")') {{ corporateCustomer.numberOfEmployees }}
        fi-data-field(:label = '$t("registeredCapital")') {{ corporateCustomer.registeredCapital }}

      template(v-if = 'editing')
        button.btn.btn-primary(
          :disabled = 'saving'
          type      = 'submit'
        )
          |{{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'toggleEditing'
        ) {{ $t('common:cancel') }}
</template>


<script>
import FiMultiselect from '@/components/FiMultiselect'
import { mapState, mapActions, mapGetters } from 'vuex'
import FiDataField from '@/components/FiDataField'
import FiFormField from '@/components/FiFormField'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'customer-corporate-profile',

  components: {
    FiMultiselect,
    FiDataField,
    FiFormField
  },

  i18nOptions: {},

  data () {
    return {
      editing: false,
      corporateCustomerData: {
        naceId: '',
        emtakId: '',
        vatNumber: null,
        turnover: null,
        registeredCapital: null,
        numberOfEmployees: null
      }
    }
  },

  computed: {
    ...mapState('customers', ['customer']),
    ...mapGetters('classifiers', ['classifierList', 'classifierById']),
    corporateCustomer () {
      return this.customer.corporateCustomer
    },
    saving () {
      return this.$vueLoading.isLoading('customer:corporate:save')
    },
    selectedNACEModel: {
      get () {
        const model = this.classifierById('nace', this.corporateCustomerData.naceId)
        return isEmpty(model) ? null : model
      },
      set (value) {
        this.corporateCustomerData.naceId = value?.id || value
      }
    },
    selectedEMTAKModel: {
      get () {
        const model = this.classifierById('emtak', this.corporateCustomerData.emtakId)
        return isEmpty(model) ? null : model
      },
      set (value) {
        this.corporateCustomerData.emtakId = value?.id || value
      }
    }
  },

  created () {
    this.loadClassifiersEMTAK()
    this.loadClassifiersNACE()
  },

  methods: {
    ...mapActions('customers', ['updateCustomerCorporate']),
    ...mapActions('classifiers', ['loadClassifiersEMTAK', 'loadClassifiersNACE']),
    codeWithHuman ({ code, human }) {
      if (!(code && human)) {
        return
      }
      return `${code} - ${human}`
    },
    toggleEditing () {
      if (!this.editing) this.resetData()
      this.editing = !this.editing
    },
    resetData () {
      this.corporateCustomerData = {
        ...this.corporateCustomerData,
        ...pick(this.corporateCustomer, Object.keys(this.corporateCustomerData))
      }
    },
    async onSubmit () {
      await this.updateCustomerCorporate({ corporateCustomerData: this.corporateCustomerData })
      this.toggleEditing()
    }
  }
}
</script>


<i18n>
en:
  title:             "Corporate profile"
  nace:              "NACE"
  emtak:             "EMTAK"
  vatNumber:         "VAT number"
  turnover:          "Turnover"
  numberOfEmployees: "Number of employees"
  registeredCapital:  "Registered capital"
et:
  title:             "Corporate profile"
  nace:              "NACE"
  emtak:             "EMTAK"
  vatNumber:         "VAT number"
  turnover:          "Turnover"
  numberOfEmployees: "Number of employees"
  registeredCapital:  "Registered capital"
ru:
  title:             "Corporate profile"
  nace:              "NACE"
  emtak:             "EMTAK"
  vatNumber:         "VAT number"
  turnover:          "Turnover"
  numberOfEmployees: "Number of employees"
  registeredCapital:  "Registered capital"
</i18n>
