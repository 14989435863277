<template lang='pug'>
.customer-reports
  scoring-request-list(
    v-if = 'isScoringEnabled'
    :criteria = 'customer.idCode'
    :customer = 'customer'
    disable-filters
  )
</template>


<script>
import ScoringRequestList from '@/views/scoring/ScoringRequestList'
import { mapState } from 'vuex'
import { isScoringEnabled } from '@/mixins'

export default {
  name: 'customer-reports',

  components: { ScoringRequestList },

  mixins: [isScoringEnabled],

  computed: mapState('customers', ['customer'])
}
</script>
