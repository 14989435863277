<template lang="pug">
.customer-address-list-item
  customer-address-form(v-if     = 'editing'
                        v-model  = 'editing'
                        :address = 'address')
  .animated.fadeIn(v-else)
    fi-data-field(:label = '$t("country")')
      | {{ classifierById('countries', address.countryId).human }}
      .fa-pull-right
        button.btn.btn-sm.btn-link.px-1(@click.prevent = 'editing = true')
          i.far.fa-edit.fa-lg
        button.btn.btn-sm.btn-link.text-danger.px-1(
          :disabled    = 'removing'
          v-fi-confirm = '{ ok: remove, message: $t("removeConfirm") }'
        )
          i.far.fa-trash-alt.fa-lg
    fi-data-field(:label = '$t("county")') {{ classifierById('counties', address.countyId).human }}
    fi-data-field(:label = '$t("city")') {{ address.city }}
    fi-data-field(:label = '$t("address")') {{ address.fullAddress }}
    fi-data-field(:label = '$t("postalCode")') {{ address.postalCode }}
    fi-data-field(v-if = 'address.isDefault')
      i.fa.fa-check-square.form-check-input
      | {{ $t('primary') }}
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import CustomerAddressForm from './CustomerAddressForm'
import FiDataField from '@/components/FiDataField'

export default {
  name: 'customer-address-list-item',

  components: {
    CustomerAddressForm,
    FiDataField
  },

  props: {
    address: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      editing: false,
      addressData: {
        countryId: null,
        countyId: null,
        city: '',
        fullAddress: '',
        postalCode: ''
      }
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    removing () {
      return this.$vueLoading.isLoading(`customer:address:${this.address.id}:remove`)
    }
  },

  methods: {
    ...mapActions('customers', ['removeCustomerAddress']),
    async remove ({ done }) {
      await this.removeCustomerAddress({ id: this.address.id })
      done()
    }
  }
}
</script>


<i18n>
en:
  country:       "Country"
  county:        "County"
  city:          "City"
  address:       "Address"
  postalCode:    "Postal code"
  primary:       "Primary address"
  removeConfirm: "Are you sure you want to delete this address?"
et:
  country:       "Country"
  county:        "County"
  city:          "City"
  address:       "Address"
  postalCode:    "Postal code"
  primary:       "Primary address"
  removeConfirm: "Are you sure you want to delete this address?"
ru:
  country:       "Country"
  county:        "County"
  city:          "City"
  address:       "Address"
  postalCode:    "Postal code"
  primary:       "Primary address"
  removeConfirm: "Are you sure you want to delete this address?"
</i18n>
