<template lang="pug">
.animated.fadeIn.scoring-request-list
  .card
    .card-header
      | {{ $t('scoringRequestsTitle') }}
      b-dd.float-right(
        :text   = '$t("newScoring")'
        variant = 'link transparent p-0 mt-0'
        right
      )
        b-dd-item(
          v-for = 'model in scoringModels'
          :key  = 'model.id'
          :to   = '{ name: "NewScoringRequest", query: { modelId: model.id }, params: { customer } }'
        ) {{ model.description }}
    .card-body
      form.form-horizontal(v-if = '!disableFilters')
        .row
          .form-group.col-lg-6
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-search
              input.form-control(
                v-model.trim = 'searchParams.criteria'
                type         = 'text'
                :placeholder = "$t('search')"
              )
          .form-group.col-lg-6
            range-input-date(
              :start.sync  = 'searchParams.startDate'
              :end.sync    = 'searchParams.endDate'
            )
        .row
          .form-group.col-lg-6
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-tag
              fi-select(
                v-model.number = 'searchParams.modelId'
                :options       = 'sortClassifiers'
                :placeholder   = '$t("modelPlaceholder")'
              )
          .form-group.col-lg-6
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-flag
              fi-select(
                v-model.number = 'searchParams.scoringState'
                :options       = 'optionsFromClassifier("scoringStates")'
                :placeholder   = '$t("statePlaceHolder")'
              )
      scoring-request-list-table(
        v-model = 'pagination.page'
        :items  = 'requestList'
        loader  = 'scoring:requests:fetch'
        :pages  = 'requestsPages'
      )
</template>


<script>
import FiTable from '@/components/FiTable'
import ScoringRequestListTable from '@/views/scoring/ScoringRequestListTable'
import debounce from 'lodash/debounce'
import { mapActions, mapGetters, mapState } from 'vuex'
import FiSelect from '@/components/FiSelect'
import ScoringRequestListItem from './ScoringRequestListItem'
import RangeInputDate from '@/components/RangeInputDate'
import FiPagination from '@/components/FiPagination'
import pick from 'lodash/pick'
import { pagination } from '@/const'
import pickBy from 'lodash/pickBy'

const searchParams = {
  criteria: '',
  startDate: null,
  endDate: null,
  modelId: '',
  scoringState: '',
  sort: 'createdAt,desc'
}

export default {
  name: 'scoring-request-list',

  components: {
    ScoringRequestListTable,
    FiTable,
    FiSelect,
    RangeInputDate,
    FiPagination,
    ScoringRequestListItem
  },

  props: {
    disableFilters: {
      type: Boolean,
      default: false
    },
    criteria: {
      type: String,
      default: ''
    },
    customer: {
      type: Object,
      default: null
    }
  },

  i18nOptions: {},

  data: (vm) => ({
    searchParams: { ...searchParams, criteria: vm.criteria, ...pick(vm.$route.query, Object.keys(searchParams)) },
    pagination: { ...pagination, ...pick(vm.$route.query, Object.keys(pagination)) }
  }),

  computed: {
    ...mapState('scoring', ['requestList', 'requestsPages', 'scoringModels']),
    ...mapGetters('scoring', ['optionsFromScoringModels']),
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    searchQuery () {
      return pickBy({ ...this.searchParams, ...this.pagination })
    },
    sortClassifiers () {
      const list = JSON.parse(JSON.stringify(this.optionsFromScoringModels))
      const sortList = list.sort(function (a, b) {
        const nameA = a.text
        const nameB = b.text
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
      return sortList
    }
  },

  watch: {
    searchParams: {
      handler () {
        this.pagination.page = 0
        this.search()
      },
      deep: true
    },
    'pagination.page' () {
      this.search()
    },
    '$route.query': {
      handler (query) {
        this.loadRequestList({ filters: query })
      },
      deep: true
    }
  },

  created () {
    this.loadRequestList({ filters: this.searchQuery })
    this.loadScoringModels()
  },

  methods: {
    ...mapActions('scoring', ['loadRequestList', 'loadScoringModels']),
    search: debounce(
      function () {
        this.$router.push({ query: this.searchQuery })
      }, 250
    )
  }
}
</script>


<i18n>
en:
  scoringRequestsTitle: "Scoring requests"
  newScoring:           "+ New scoring"
  search:               "Name, ID code"
  startDate:            "Start date"
  endDate:              "End date"
  modelPlaceholder:     "All scoring model"
  statePlaceHolder:     "All scoring states"
et:
  scoringRequestsTitle: "Scoring requests"
  newScoring:           "+ New scoring"
  search:               "Name, ID code"
  startDate:            "Start date"
  endDate:              "End date"
  modelPlaceholder:     "All scoring model"
  statePlaceHolder:     "All scoring states"
ru:
  scoringRequestsTitle: "Scoring requests"
  newScoring:           "+ New scoring"
  search:               "Name, ID code"
  startDate:            "Start date"
  endDate:              "End date"
  modelPlaceholder:     "All scoring model"
  statePlaceHolder:     "All scoring states"
</i18n>
