<template lang="pug">
.customer-general.animated.fadeIn.row
  .col
    customer-data
  .col
    customer-custom-fields-data(
      v-if           = '!isLoading'
      :custom-fields = 'customFieldsValues'
      :options       = 'customerCustomFields'
      @submit        = 'saveCustomFields'
    )
    customer-corporate-profile(v-if = 'isCorporateCustomer(customer)')
    customer-private-profile(v-else)
    customer-address-list(:addresses = 'customerAddress')
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CustomerData from './CustomerData'
import CustomerAddressList from './CustomerAddressList'
import CustomerPrivateProfile from './CustomerPrivateProfile'
import CustomerCorporateProfile from './CustomerCorporateProfile'
import CustomerCustomFieldsData from '@/views/customers/CustomerCustomFieldsData'

export default {
  name: 'customer-general',

  components: {
    CustomerCustomFieldsData,
    CustomerData,
    CustomerAddressList,
    CustomerPrivateProfile,
    CustomerCorporateProfile
  },

  data: () => ({
    isLoading: true
  }),

  computed: {
    ...mapState('customers', ['customer', 'customFieldsValues', 'customerAddress']),
    ...mapState('customFields', ['customFields']),
    ...mapState('customerFields', ['customerFields']),
    ...mapGetters('customers', ['isCorporateCustomer']),
    customerCustomFields () {
      return this.customerFields.filter(({ customerSectorId }) => this.customer.sectorId === customerSectorId)
        .map(field => ({ ...field, customField: this.customFields.find(({ id }) => id === field.customFieldId) }))
        .filter(({ customField }) => customField?.enabled)
        .sort((a, b) => a.fieldOrder - b.fieldOrder)
    }
  },

  async created () {
    await this.loadCustomFields()
    await this.loadCustomerFields()
    await this.loadCustomerCustomFields({ customerId: this.customer.id })
    await this.loadCustomerAddress({ customerId: this.customer.id })
    this.isLoading = false
  },

  methods: {
    ...mapActions('customFields', ['loadCustomFields']),
    ...mapActions('customerFields', ['loadCustomerFields']),
    ...mapActions('customers', ['loadCustomerCustomFields', 'saveCustomerCustomFields', 'loadCustomerAddress']),
    async saveCustomFields ({ data, done }) {
      await this.saveCustomerCustomFields({ customerId: this.customer.id, customerFieldData: data })
      done()
    }
  }
}
</script>


<style  scoped lang="scss">
@import '~bootstrap/scss/mixins';
@import 'scss/variables';

.card-columns {
  @include media-breakpoint-up(sm) {
    column-count: 2;
  }
}
</style>
