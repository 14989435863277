import { Expose } from 'class-transformer'

export class CApplicationCorporateAnnualReport {
  @Expose() reportYear: number
  @Expose() intangibles: number = 0
  @Expose() property: number = 0
  @Expose() longTermInvestments: number = 0
  @Expose() otherFixedAssets: number = 0
  @Expose() fixedAssets: number = 0
  @Expose() inventories: number = 0
  @Expose() accountsReceivable: number = 0
  @Expose() shortTermInvestments: number = 0
  @Expose() accruedIncomeDeferredExpenses: number = 0
  @Expose() corporateCustomerId: number = 0
  @Expose() cash: number = 0
  @Expose() currentAssets: number = 0
  @Expose() currentRatio: number = 0
  @Expose() quickRatio: number = 0
  @Expose() workingCapitalVolume: number = 0
  @Expose() workingCapitalSales: number = 0
  @Expose() inventoryTurnoverDays: number = 0
  @Expose() receivableTurnoverDays: number = 0
  @Expose() payablesTurnoverDays: number = 0
  @Expose() cashConversionCycleRatio: number = 0
  @Expose() ebitda: number = 0
  @Expose() debtEbitdaRatio: number = 0
  @Expose() debtEbitdaRatioNewLoan: number = 0
  @Expose() leverage: number = 0
  @Expose() returnOnEquity: number = 0
  @Expose() returnOnAssets: number = 0
  @Expose() returnOnCapitalEmployed: number = 0
  @Expose() ebitdaMargin: number = 0
  @Expose() profitMargin: number = 0
  @Expose() lastYearlySalesRevenue: number = 0
  @Expose() totalAssets: number = 0
  @Expose() equity: number = 0
  @Expose() addedCapital: number = 0
  @Expose() reserves: number = 0
  @Expose() retainedEarnings: number = 0
  @Expose() subsidies: number = 0
  @Expose() provisions: number = 0
  @Expose() longTermDebts: number = 0
  @Expose() longTermAccountsPayable: number = 0
  @Expose() longTermOther: number = 0
  @Expose() longTermLiabilities: number = 0
  @Expose() shortTermDebts: number = 0
  @Expose() shortTermAccountsPayable: number = 0
  @Expose() shortTermOther: number = 0
  @Expose() shortTermLiabilities: number = 0
  @Expose() deferredIncome: number = 0
  @Expose() salesRevenue: number = 0
  @Expose() costOfSales: number = 0
  @Expose() operatingExpenses: number = 0
  @Expose() adminExpenses: number = 0
  @Expose() operatingProfit: number = 0
  @Expose() otherIncome: number = 0
  @Expose() otherExpenses: number = 0
  @Expose() interestExpense: number = 0
  @Expose() profitBeforeTax: number = 0
  @Expose() taxesPaid: number = 0
  @Expose() profitLoss: number = 0
  @Expose() depreciation: number = 0
  @Expose() amortization: number = 0
  @Expose() id: number
}
