<template lang = "pug">
.customer-address-form.animated.fadeIn
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(:label = '$t("country")')
      fi-select(
        v-model.number = 'addressData.countryId'
        :options       = 'optionsFromClassifier("countries")'
        sm
      )
    fi-form-field(:label = '$t("county")')
      fi-select(
        v-model.number = 'addressData.countyId'
        :options       = 'counties'
        sm
      )
    fi-form-field(:label = '$t("city")')
      input.form-control.form-control-sm(
        v-model.trim = 'addressData.city'
        type         = 'text'
      )
    fi-form-field(:label = '$t("address")')
      input.form-control.form-control-sm(
        v-model.trim = 'addressData.fullAddress'
        type         = 'text'
      )
    fi-form-field(:label = '$t("postalCode")')
      input.form-control.form-control-sm(
        v-model.trim = 'addressData.postalCode'
        type         = 'text'
      )
    fi-form-field
      .form-check: label.form-check-label
        input.form-check-input(
          v-model = 'addressData.isDefault'
          type    = 'checkbox')
        | {{ $t('primary') }}

    button.btn.btn-primary(
      :disabled = 'saving'
      type      = 'submit'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    | &nbsp;
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = 'close'
    ) {{ $t('common:cancel') }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import pick from 'lodash/pick'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'customer-address-form',

  components: {
    FiFormField,
    FiSelect
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    address: {
      type: Object,
      default: null
    }
  },

  i18nOptions: {},

  data () {
    return {
      addressData: {
        countryId: '',
        countyId: '',
        city: null,
        fullAddress: null,
        postalCode: null,
        isDefault: false
      }
    }
  },

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'optionsFromCountiesByCountry']),
    saving () {
      if (this.editing) {
        return this.$vueLoading.isLoading(`customer:address:${this.address.id}:save`)
      } else {
        return this.$vueLoading.isLoading('customer:address:save')
      }
    },
    editing () {
      return !!this.address
    },
    counties () {
      return this.optionsFromCountiesByCountry(this.addressData.countryId)
    }
  },

  mounted () {
    if (this.editing) {
      this.addressData = pick(this.address, Object.keys(this.addressData))
    }
  },

  methods: {
    ...mapActions('customers', ['createCustomerAddress', 'updateCustomerAddress']),
    close () {
      this.$emit('input', false)
    },
    async onSubmit () {
      if (this.editing) {
        await this.updateCustomerAddress({ id: this.address.id, addressData: this.addressData })
      } else {
        await this.createCustomerAddress({ addressData: this.addressData })
      }
      this.close()
    }
  }
}
</script>


<i18n>
en:
  country:    "Country"
  county:     "County"
  city:       "City"
  address:    "Address"
  postalCode: "Postal code"
  primary:    "Primary address"
et:
  country:    "Country"
  county:     "County"
  city:       "City"
  address:    "Address"
  postalCode: "Postal code"
  primary:    "Primary address"
ru:
  country:    "Country"
  county:     "County"
  city:       "City"
  address:    "Address"
  postalCode: "Postal code"
  primary:    "Primary address"
</i18n>
