<template lang="pug">
.customer-debt-list.card
  .card-header {{ $t('title') }}
  .card-body
    .table-responsive
      table.table
        thead: tr
          th {{ $t('contract') }}
          th {{ $t('type') }}
          th.money {{ $t('debt') }}
          th {{ $t('date') }}
          th {{ $t('period') }}
          th {{ $t('status') }}
        tbody
          tr(v-if = '$vueLoading.isLoading("customer:debts:fetch")'): td.text-center(colspan = 5): i.fa.fa-spinner.fa-pulse.fa-lg
          tr(v-else-if = 'customerHistory.debts.length === 0'): td.text-center(colspan = 5) {{ $t('notFound') }}
          template(v-else)
            customer-debt-list-item(v-for = 'debt in customerHistory.debts'
                                    :key  = 'debt.id'
                                    :debt = 'debt')

</template>


<script>
import { mapState, mapActions } from 'vuex'
import CustomerDebtListItem from './CustomerDebtListItem.vue'

export default {
  name: 'customer-debt-list',

  components: {
    CustomerDebtListItem
  },

  i18nOptions: {},

  computed: {
    ...mapState('customers', ['customerHistory'])
  },

  created () {
    this.loadCustomerDebts()
  },

  methods: mapActions('customers', ['loadCustomerDebts'])
}
</script>


<i18n>
en:
  title:    "Debts"
  type:     "Invoice type"
  contract: "Contract"
  debt:     "Debt"
  date:     "Date"
  period:   "Period"
  status:   "Status"
  notFound: "Debts not found"
et:
  title:    "Debts"
  type:     "Invoice type"
  contract: "Contract"
  debt:     "Debt"
  date:     "Date"
  period:   "Period"
  status:   "Status"
  notFound: "Debts not found"
ru:
  title:    "Debts"
  type:     "Invoice type"
  contract: "Contract"
  debt:     "Debt"
  date:     "Date"
  period:   "Period"
  status:   "Status"
  notFound: "Debts not found"
</i18n>

