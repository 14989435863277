<template lang="pug">
.customer-history-debts
  h6.card-title {{ $t('debts') }}
  .table-responsive(v-if = 'debts.length')
    table.table.table-sm
      thead: tr
        th {{ $t('contract') }}
        th {{ $t('type') }}
        th.money {{ $t('debt') }}
        th {{ $t('date') }}
        th {{ $t('period') }}
        th {{ $t('status') }}
      tbody
        customer-debt-list-item(v-for = 'debt in debts'
                                :key  = 'debt.id'
                                :debt = 'debt')
  template(v-else) {{ $t('notFound') }}
</template>


<script>
import CustomerDebtListItem from '@/views/customers/CustomerDebtListItem'

export default {
  name: 'customer-history-debts',

  components: { CustomerDebtListItem },

  props: {
    debts: {
      type: Array,
      required: true
    }
  },

  i18nOptions: {}
}
</script>


<i18n>
en:
  debts:    "Debts"
  type:     "Invoice type"
  contract: "Contract"
  debt:     "Debt"
  date:     "Date"
  period:   "Period"
  status:   "Status"
  notFound: "Not found"
et:
  debts:    "Debts"
  type:     "Invoice type"
  contract: "Contract"
  debt:     "Debt"
  date:     "Date"
  period:   "Period"
  status:   "Status"
  notFound: "Not found"
ru:
  debts:    "Debts"
  type:     "Invoice type"
  contract: "Contract"
  debt:     "Debt"
  date:     "Date"
  period:   "Period"
  status:   "Status"
  notFound: "Not found"
</i18n>
