<template lang="pug">
tr.scoring-request-list-item
  td {{ request.id }}
  td {{ request.description }}
  td {{ request.idCode }}
  td {{ request.firstName }} {{ request.lastName }}
  td {{ $t('common:formatDatetime', { value: request.createdAt }) }}
  td
    strong {{ request.finalScore }}
    scoring-request-progress-bar(:request = 'request')
  td.text-center: router-link(:to = '{ name: "ScoringRequest", params: { id: request.id.toString(), type: request.modelType.toLowerCase() } }') {{ $t('details') }}
</template>


<script>
import { mapGetters, mapState } from 'vuex'
import ScoringRequestProgressBar from './ScoringRequestProgressBar'

export default {
  name: 'scoring-request-list-item',

  components: { ScoringRequestProgressBar },

  props: {
    request: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapState('i18N', ['currentLocale']),
    ...mapGetters('scoring', ['scoringModelById']),
    ...mapGetters('classifiers', ['classifierById']),
    scoringModel () {
      return this.scoringModelById(this.request.scoringModelId)
    }
  }
}
</script>


<style scoped lang="scss">
.badge {
  font-size: 100%;
}
</style>


<i18n>
en:
  details: "View details"
et:
  details: "View details"
ru:
  details: "View details"
</i18n>
