<template lang="pug">
tr.customer-deposit-list-item
  td: router-link(:to = '{ name: "Deposit", params: { id: deposit.id.toString() }}') {{ deposit.contractNumber }}
  td {{ deposit.creditIssuerName }}
  td {{ product.name }}
  td.money {{ deposit.initialAmount | money }}
  td.money {{ deposit.balance | money }}
  td.money {{ deposit.serviceDebt | money }}
  td {{ $t('common:formatDate', { value: deposit.startDate }) }}
  td {{ $t('common:formatDate', { value: deposit.endDate }) }}
  td
    router-link(:to = '{ name: "CustomerPaymentInfo", params: { id: deposit.customer.id } }') {{ deposit.paymentAccountReference }}
  td.money {{ deposit.nextPaymentAmount | money }}
  td {{ $t('common:formatDate', { value: deposit.nextPaymentDate }) }}
  td {{ $t('common:formatDate', { value: deposit.updatedAt }) }}
  td: .badge(:class = '`badge-${loanStatusColor(status.id)}`') {{ status.human }}
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'customer-deposit-list-item',

  props: {
    deposit: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierByName']),
    ...mapGetters('loans', ['loanStatusColor']),
    ...mapGetters('products', ['productById']),
    product () {
      return this.productById(this.deposit.productId)
    },
    status () {
      return this.classifierByName(this.deposit.status.classifier, this.deposit.status.code) ?? {}
    }
  }
}
</script>
