<template lang = "pug">
.card.customer-private-profile
  .card-header
    | {{ $t('title') }}
    button.btn.btn-primary.fa-pull-right.btn-sm(
      v-if           = '!editing'
      @click.prevent = 'toggleEditing'
    ) {{ $t('common:edit') }}
  .card-body
    form.form-horizontal(@submit.prevent = 'onSubmit')
      template(v-if = 'editing')
        fi-form-field(:label = '$t("documentType")')
          fi-select(
            v-model.number = 'privateCustomerData.idDocId'
            :options       = 'optionsFromClassifier("idDocs")'
            sm
          )
        fi-form-field(:label = '$t("documentNumber")')
          input.form-control.form-control-sm(
            v-model.trim = 'privateCustomerData.idDocNumber'
            type         = 'text'
          )
        fi-form-field(:label = '$t("issued")')
          fi-datepicker(
            v-model = 'privateCustomerData.idDocIssueDate'
            sm
          )

        fi-form-field(:label = '$t("expires")')
          fi-datepicker(
            v-model = 'privateCustomerData.idDocExpireDate'
            sm
          )
        fi-form-field(:label = '$t("maritalStatus")')
          fi-select.animated.fadeIn(
            v-model.number = 'privateCustomerData.maritalStatusId'
            :options       = 'optionsFromClassifier("maritalStatus")'
            sm
          )

        fi-form-field(:label = '$t("dependantNumber")')
          input.form-control.form-control-sm(
            v-model.number = 'privateCustomerData.dependantNumber'
            type         = 'text'
          )
        fi-form-field(:label = '$t("monthlyIncome")')
          input.form-control.form-control-sm(
            v-model.number = 'privateCustomerData.monthlyIncome'
            type         = 'text'
          )
        fi-form-field(:label = '$t("employmentLength")')
          input.form-control.form-control-sm(
            v-model.number = 'privateCustomerData.employmentLength'
            type         = 'text'
          )
        fi-form-field(:label = '$t("incomeSources")')
          fi-select.animated.fadeIn(
            v-model = 'sourceOfIncomeId'
            :options       = 'optionsFromClassifier("incomeSources")'
            sm
            :change = 'addAccount()'
          )
          c-list-group
            c-list-group-item.p-1(
              v-for = '(incomeSource, index) in incomeSources'
              :key  = 'index'
            )
              .col.d-flex.align-items-center.justify-content-between
                label.mb-0.mr-5 {{ classifierById('incomeSources', incomeSource.sourceOfIncomeId).human }}
                input.form-control.form-control-sm(
                  v-if="incomeSource.sourceOfIncomeId === 1005"
                  v-model = 'incomeSource.otherSourceOfIncome'
                  type         = 'text'
                )
                button.btn.btn-sm.btn-link.text-danger.fa-pull-right(@click.prevent = 'removeAccount(index)')
                  i.far.fa-trash-alt.fa-lg
        table.table.table-sm.mt-5
          thead: tr
            th {{ $t('employmentType') }}
            th {{ $t('companyName') }}
            th {{ $t('position') }}
            th {{ $t('typeOfActivity') }}
            th
          tbody
            td
              fi-select.animated.fadeIn(
                v-model.number = 'employmentDetail.employmentTypeId'
                :options       = 'optionsFromClassifier("employmentType")'
                sm
              )
              input.form-control.form-control-sm(
                v-if="employmentDetail.employmentTypeId === 1005"
                v-model = 'employmentDetail.otherEmploymentType'
                type         = 'text'
              )
            td
              input.form-control.form-control-sm(
                v-model.number = 'employmentDetail.companyName'
                type         = 'text'
              )
            td
              input.form-control.form-control-sm(
                v-model.number = 'employmentDetail.position'
                type         = 'text'
              )
            td
              input.form-control.form-control-sm(
                v-model.number = 'employmentDetail.typeOfActivity'
                type         = 'text'
              )
            td
              button.btn.btn-sm.btn-primary(
                @click.prevent = 'addEmploymentDetail'
              )
                i.far.fa-plus-square.fa-lg
          tbody(
            v-for = '(employmentDetail, index) in employmentDetails'
            :key  = 'index'
          )
            td
              div.mb-0  {{ employmentDetail.employmentTypeId === 1005 ? employmentDetail.otherEmploymentType : classifierById('employmentType', employmentDetail.employmentTypeId).human  }}
            td
              div.mb-0 {{ employmentDetail.companyName }}
            td
              div.mb-0 {{ employmentDetail.position }}
            td
              div.mb-0 {{ employmentDetail.typeOfActivity }}
            td
              button.btn.btn-sm.btn-link.text-danger.fa-pull-right(@click.prevent = 'removeEmploymentDetail(index)')
                i.far.fa-trash-alt.fa-lg

      template(v-else)
        fi-data-field(:label = '$t("documentType")') {{ classifierById('idDocs', privateCustomer.idDocId).human }}
        fi-data-field(:label = '$t("documentNumber")') {{ privateCustomer.idDocNumber }}
        fi-data-field(:label = '$t("issued")') {{ $t('common:formatDate', { value: privateCustomer.idDocIssueDate }) }}
        fi-data-field(:label = '$t("expires")') {{ $t('common:formatDate', { value: privateCustomer.idDocExpireDate }) }}
        fi-data-field(:label = '$t("maritalStatus")') {{ maritalStatus.human }}
        fi-data-field(:label = '$t("dependantNumber")') {{ privateCustomer.dependantNumber }}
        fi-data-field(:label = '$t("monthlyIncome")') {{ privateCustomer.monthlyIncome }}
        fi-data-field(:label = '$t("employmentLength")') {{ privateCustomer.employmentLength }}
        fi-data-field(:label = '$t("incomeSources")')
          c-list-group
            c-list-group-item.p-1(
              v-for = '(incomeSource, index) in privateCustomer.incomeSources'
              :key  = 'index'
            )
              label.mb-0 {{ incomeSourcesName(incomeSource.sourceOfIncomeId, incomeSource)}}
        table(v-if = 'privateCustomer.employmentDetails').table.table-sm.mt-5
          thead: tr
            th {{ $t('employmentType') }}
            th {{ $t('companyName') }}
            th {{ $t('position') }}
            th {{ $t('typeOfActivity') }}
          tbody(
            v-for = '(employmentDetail, index) in privateCustomer.employmentDetails'
            :key  = 'index'
          )
            td
              div.mb-0 {{ employmentDetail.employmentTypeId === 1005 ? employmentDetail.otherEmploymentType : classifierById('employmentType', employmentDetail.employmentTypeId).human  }}
            td
              div.mb-0 {{ employmentDetail.companyName }}
            td
              div.mb-0 {{ employmentDetail.position }}
            td
              div.mb-0 {{ employmentDetail.typeOfActivity }}
            td
              button.btn.btn-sm.btn-link.text-danger.fa-pull-right(@click.prevent = 'removeEmploymentDetail(index)')
                i.far.fa-trash-alt.fa-lg
      template(v-if = 'editing')
        button.btn.btn-primary(
          :disabled = 'saving'
          type      = 'submit'
        ) {{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'toggleEditing'
        ) {{ $t('common:cancel') }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import FiDatepicker from '@/components/FiDatepicker'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import pick from 'lodash/pick'
import { mapActions, mapGetters, mapState } from 'vuex'
import { CButton, CListGroup, CListGroupItem } from '@coreui/vue'

export default {
  name: 'customer-private-profile',

  components: {
    FiSwitch,
    FiDataField,
    FiSelect,
    FiFormField,
    FiDatepicker,
    CListGroup,
    CListGroupItem,
    CButton
  },

  i18nOptions: {},

  data () {
    return {
      editing: false,
      sourceOfIncomeId: null,
      privateCustomerData: {
        idDocId: '',
        idDocNumber: null,
        idDocIssueDate: null,
        idDocExpireDate: null,
        maritalStatusId: '',
        dependantNumber: null,
        monthlyIncome: null,
        employmentLength: null
      },
      incomeSources: [],
      employmentDetails: [],
      employmentDetail: {
        companyName: '',
        employmentTypeId: null,
        position: '',
        typeOfActivity: ''
      }
    }
  },

  computed: {
    ...mapState('customers', ['customer']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById', 'classifierByName']),
    privateCustomer () {
      return this.customer?.privateCustomer || {}
    },
    saving () {
      return this.$vueLoading.isLoading('customer:private:save')
    },
    maritalStatus () {
      const { maritalStatusId } = this.privateCustomer
      return this.classifierById('maritalStatus', maritalStatusId) || {}
    },
    employmentType () {
      const { employmentTypeId } = this.privateCustomer.employmentDetail
      return this.classifierById('employmentType', employmentTypeId) || {}
    }
  },

  methods: {
    ...mapActions('customers', ['updateCustomerPrivate']),
    incomeSourcesName (sourceOfIncomeId, incomeSource) {
      if (sourceOfIncomeId === 1005) {
        return incomeSource.otherSourceOfIncome
      }
      return this.classifierById('incomeSources', sourceOfIncomeId).human || {}
    },
    toggleEditing () {
      if (!this.editing) this.resetData()
      this.editing = !this.editing
    },
    resetData () {
      this.privateCustomerData = pick(this.privateCustomer, Object.keys(this.privateCustomerData))
      if (this.privateCustomer.incomeSources) {
        this.incomeSources = this.privateCustomer.incomeSources.slice(0)
      }
      if (this.privateCustomer.employmentDetails) {
        this.employmentDetails = this.privateCustomer.employmentDetails
      }
    },
    async onSubmit () {
      await this.updateCustomerPrivate({ privateCustomerData: { ...this.privateCustomerData, incomeSources: this.incomeSources, employmentDetails: this.employmentDetails } })
      this.toggleEditing()
    },
    addAccount () {
      if (!this.sourceOfIncomeId) {
        return
      }
      this.incomeSources.push({
        sourceOfIncomeId: this.sourceOfIncomeId
      })
      this.sourceOfIncomeId = null
    },
    addEmploymentDetail () {
      if (!this.employmentDetail.companyName && !this.employmentDetail.employmentTypeId && !this.employmentDetail.position && !this.employmentDetail.typeOfActivity) {
        return
      }
      this.employmentDetails.push(JSON.parse(JSON.stringify(this.employmentDetail)))
      this.employmentDetail.companyName = ''
      this.employmentDetail.employmentTypeId = null
      this.employmentDetail.position = ''
      this.employmentDetail.typeOfActivity = ''
    },
    async removeEmploymentDetail (index) {
      this.employmentDetails.splice(index, 1)
    },
    async removeAccount (index) {
      this.incomeSources.splice(index, 1)
    }
  }
}
</script>


<i18n>
en:
  title:                 "Private profile"
  documentType:          "Document type"
  documentNumber:        "Document number"
  issued:                "Issued"
  expires:               "Expires"
  maritalStatus:         "Marital status"
  dependantNumber:       "Number of dependants"
  monthlyIncome:         "Monthly income"
  employmentLength:      "Current employment length in months"
  incomeSources:         "Income source"
  employmentType:        "Employment type"
  companyName:           "Company name"
  position:              "Position"
  typeOfActivity:        "Type of the activities"
et:
  title:                 "Private profile"
  documentType:          "Document type"
  documentNumber:        "Document number"
  issued:                "Issued"
  expires:               "Expires"
  maritalStatus:         "Marital status"
  dependantNumber:       "Number of dependants"
  monthlyIncome:         "Monthly income"
  employmentLength:      "Current employment length in months"
  incomeSources:         "Income source"
  employmentType:        "Employment type"
  companyName:           "Company name"
  position:              "Position"
  typeOfActivity:        "Type of the activities"
ru:
  title:                 "Private profile"
  documentType:          "Document type"
  documentNumber:        "Document number"
  issued:                "Issued"
  expires:               "Expires"
  maritalStatus:         "Marital status"
  dependantNumber:       "Number of dependants"
  monthlyIncome:         "Monthly income"
  employmentLength:      "Current employment length in months"
  incomeSources:         "Income source"
  employmentType:        "Employment type"
  companyName:           "Company name"
  position:              "Position"
  typeOfActivity:        "Type of the activities"
</i18n>
