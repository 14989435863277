<template lang="pug">
.customer-payment-info-outgoing-payment-list
  fi-table(:fields     = 'fields'
           :items      = 'briefCustomerPaymentsList'
           loader      = 'customer:payments:fetch'
           :emptyLabel = '$t("notFound")'
           responsive)
    template(#default = '{ items }')
      customer-payment-info-outgoing-payment-list-item(v-for    = 'payment in items'
                                                       :key     = 'payment.id'
                                                       :payment = 'payment')
</template>


<script>
import FiTable from '@/components/FiTable'
import CustomerPaymentInfoOutgoingPaymentListItem from '@/views/customers/CustomerPaymentInfoOutgoingPaymentListItem'

export default {
  name: 'customer-payment-info-outgoing-payment-list',

  components: { CustomerPaymentInfoOutgoingPaymentListItem, FiTable },

  props: {
    briefCustomerPaymentsList: {
      type: Array,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    fields () {
      return [
        {
          key: 'date',
          label: this.$t('date')
        },
        {
          key: 'toName',
          label: this.$t('toName')
        },
        {
          key: 'description',
          label: this.$t('description')
        },
        {
          key: 'amount',
          label: this.$t('amount'),
          class: 'money'
        },
        {
          key: 'payableAfter',
          label: this.$t('payableAfter')
        },
        {
          key: 'status',
          label: this.$t('status')
        }
      ]
    }
  }
}
</script>


<i18n>
en:
  date:         "Payment date"
  toName:       "Beneficiary name"
  description:  "Description"
  amount:       "Amount"
  payableAfter: "Payable after"
  status:       "Status"
  notFound:     "Payments not found"
et:
  date:         "Payment date"
  toName:       "Beneficiary name"
  description:  "Description"
  amount:       "Amount"
  payableAfter: "Payable after"
  status:       "Status"
  notFound:     "Payments not found"
ru:
  date:         "Payment date"
  toName:       "Beneficiary name"
  description:  "Description"
  amount:       "Amount"
  payableAfter: "Payable after"
  status:       "Status"
  notFound:     "Payments not found"
</i18n>
