<template lang="pug">
b-modal.application-corporate-annual-report-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title")'
  size   =  'xl'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    .form-row
      .col-lg-6
        fi-form-field(:label = '$t("reportYear")')
          input.form-control-sm.form-control(v-model.number="reportData.reportYear")
        fi-form-field(:label = '$t("fixedAssets")')
          input.form-control-sm.form-control(v-model.number="reportData.fixedAssets")
        fi-form-field(:label = '$t("intangibles")')
          input.form-control-sm.form-control(v-model.number="reportData.intangibles")
        fi-form-field(:label = '$t("property")')
          input.form-control-sm.form-control(v-model.number="reportData.property")
        fi-form-field(:label = '$t("longTermInvestments")')
          input.form-control-sm.form-control(v-model.number="reportData.longTermInvestments")
        fi-form-field(:label = '$t("otherFixedAssets")')
          input.form-control-sm.form-control(v-model.number="reportData.otherFixedAssets")
        fi-form-field(:label = '$t("currentAssets")')
          input.form-control-sm.form-control(v-model.number="reportData.currentAssets")
        fi-form-field(:label = '$t("inventories")')
          input.form-control-sm.form-control(v-model.number="reportData.inventories")
        fi-form-field(:label = '$t("accountsReceivable")')
          input.form-control-sm.form-control(v-model.number="reportData.accountsReceivable")
        fi-form-field(:label = '$t("shortTermInvestments")')
          input.form-control-sm.form-control(v-model.number="reportData.shortTermInvestments")
        fi-form-field(:label = '$t("cash")')
          input.form-control-sm.form-control(v-model.number="reportData.cash")
        fi-form-field(:label = '$t("accruedIncomeDeferredExpenses")')
          input.form-control-sm.form-control(v-model.number="reportData.accruedIncomeDeferredExpenses")
        fi-form-field(:label = '$t("totalAssets")')
          input.form-control-sm.form-control(v-model.number="reportData.totalAssets")
        fi-form-field(:label = '$t("equity")')
          input.form-control-sm.form-control(v-model.number="reportData.equity")
        fi-form-field(:label = '$t("addedCapital")')
          input.form-control-sm.form-control(v-model.number="reportData.addedCapital")
        fi-form-field(:label = '$t("reserves")')
          input.form-control-sm.form-control(v-model.number="reportData.reserves")
        fi-form-field(:label = '$t("retainedEarnings")')
          input.form-control-sm.form-control(v-model.number="reportData.retainedEarnings")
        fi-form-field(:label = '$t("subsidies")')
          input.form-control-sm.form-control(v-model.number="reportData.subsidies")
        fi-form-field(:label = '$t("provisions")')
          input.form-control-sm.form-control(v-model.number="reportData.provisions")
        fi-form-field(:label = '$t("longTermLiabilities")')
          input.form-control-sm.form-control(v-model.number="reportData.longTermLiabilities")

      .col-lg-6
        fi-form-field(:label = '$t("longTermDebts")')
          input.form-control-sm.form-control(v-model.number="reportData.longTermDebts")
        fi-form-field(:label = '$t("longTermAccountsPayable")')
          input.form-control-sm.form-control(v-model.number="reportData.longTermAccountsPayable")
        fi-form-field(:label = '$t("longTermOther")')
          input.form-control-sm.form-control(v-model.number="reportData.longTermOther")
        fi-form-field(:label = '$t("shortTermLiabilities")')
          input.form-control-sm.form-control(v-model.number="reportData.shortTermLiabilities")
        fi-form-field(:label = '$t("shortTermDebts")')
          input.form-control-sm.form-control(v-model.number="reportData.shortTermDebts")
        fi-form-field(:label = '$t("shortTermAccountsPayable")')
          input.form-control-sm.form-control(v-model.number="reportData.shortTermAccountsPayable")
        fi-form-field(:label = '$t("shortTermOther")')
          input.form-control-sm.form-control(v-model.number="reportData.shortTermOther")
        fi-form-field(:label = '$t("deferredIncome")')
          input.form-control-sm.form-control(v-model.number="reportData.deferredIncome")
        fi-form-field(:label = '$t("salesRevenue")')
          input.form-control-sm.form-control(v-model.number="reportData.salesRevenue")
        fi-form-field(:label = '$t("costOfSales")')
          input.form-control-sm.form-control(v-model.number="reportData.costOfSales")
        fi-form-field(:label = '$t("operatingExpenses")')
          input.form-control-sm.form-control(v-model.number="reportData.operatingExpenses")
        fi-form-field(:label = '$t("adminExpenses")')
          input.form-control-sm.form-control(v-model.number="reportData.adminExpenses")
        fi-form-field(:label = '$t("operatingProfit")')
          input.form-control-sm.form-control(v-model.number="reportData.operatingProfit")
        fi-form-field(:label = '$t("otherIncome")')
          input.form-control-sm.form-control(v-model.number="reportData.otherIncome")
        fi-form-field(:label = '$t("otherExpenses")')
          input.form-control-sm.form-control(v-model.number="reportData.otherExpenses")
        fi-form-field(:label = '$t("interestExpense")')
          input.form-control-sm.form-control(v-model.number="reportData.interestExpense")
        fi-form-field(:label = '$t("profitBeforeTax")')
          input.form-control-sm.form-control(v-model.number="reportData.profitBeforeTax")
        fi-form-field(:label = '$t("taxesPaid")')
          input.form-control-sm.form-control(v-model.number="reportData.taxesPaid")
        fi-form-field(:label = '$t("profitLoss")')
          input.form-control-sm.form-control(v-model.number="reportData.profitLoss")
        fi-form-field(:label = '$t("depreciation")')
          input.form-control-sm.form-control(v-model.number="reportData.depreciation")
        fi-form-field(:label = '$t("amortization")')
          input.form-control-sm.form-control(v-model.number="reportData.amortization")
  template(#modal-footer)
    button.btn.btn-primary(
      @click.prevent = 'onSubmit'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loader')
    button.btn.btn-secondary(
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
</template>


<script>
import FiModal from '@/components/FiModal'
import { CApplicationCorporateAnnualReport } from '@/models/application/CApplicationCorporateAnnualReport.ts'
import { classToClass, plainToClass } from 'class-transformer'
import FiFormField from '@/components/FiFormField'
import FiDatepicker from '@/components/FiDatepicker'

export default {
  name: 'application-corporate-annual-report-modal',

  components: { FiDatepicker, FiFormField, FiModal },

  props: {
    loader: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    report: {
      type: CApplicationCorporateAnnualReport,
      default: () => plainToClass(CApplicationCorporateAnnualReport, {}, { strategy: 'exposeAll', exposeDefaultValues: true })
    }
  },

  data: (vm) => ({
    reportData: classToClass(vm.report),
    isError: false
  }),

  computed: {
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },

  methods: {
    resetData () {
      Object.assign(this.$data, this.$options.data(this))
    },
    onHide ({ preventDefault }) {
      if (this.loader) {
        preventDefault()
      }
    },
    onSubmit () {
      // if (this.reportData.annualReportDate && this.reportData.annualReportYear) {
      //   for (const current in this.reportData) {
      //     if (this.reportData[current] === '') {
      //       this.reportData[current] = 0
      //     }
      //   }
      this.$emit('submit', {
        report: this.reportData,
        done: () => this.$refs.modal.hide()
      })
      // } else {
      //   this.isError = true
      // }
    }
  }
}
</script>


<i18n>
en:
  year:                 "Year"
  turnover:             "Turnover"
  equity:               "Equity"
  debt:                 "Debt"
  debtToVendors:        "Debt to vendors"
  profit:                "Profit"
  profitLoss:            "Profit loss"
  interestExpense:      "Interest expense"
  taxesPaid:            "Taxes paid"
  depreciation:         "Depreciation"
  amortization:         "Amortization"
  currentAssets:        "Current assets"
  currentLiabilities:   "Current liabilities"
  inventories:          "Inventories"
  prepayments:          "Prepayments"
  floatingAssets:        "Floating assets"
  shortTermLiabilities: "Short term liabilities"
  debtToVendor:         "Debt to vendor"
  date:                 "Report date"

et:
  year:                 "Year"
  turnover:             "Turnover"
  equity:               "Equity"
  debt:                 "Debt"
  debtToVendors:        "Debt to vendors"
  profit:                "Profit"
  profitLoss:            "Profit loss"
  interestExpense:      "Interest expense"
  taxesPaid:            "Taxes paid"
  depreciation:         "Depreciation"
  amortization:         "Amortization"
  currentAssets:        "Current assets"
  currentLiabilities:   "Current liabilities"
  inventories:          "Inventories"
  prepayments:          "Prepayments"
  floatingAssets:        "Floating assets"
  shortTermLiabilities: "Short term liabilities"
  debtToVendor:         "Debt to vendor"
  date:                 "Report date"
ru:
  year:                 "Year"
  turnover:             "Turnover"
  equity:               "Equity"
  debt:                 "Debt"
  debtToVendors:        "Debt to vendors"
  profit:                "Profit"
  profitLoss:            "Profit loss"
  interestExpense:      "Interest expense"
  taxesPaid:            "Taxes paid"
  depreciation:         "Depreciation"
  amortization:         "Amortization"
  currentAssets:        "Current assets"
  currentLiabilities:   "Current liabilities"
  inventories:          "Inventories"
  prepayments:          "Prepayments"
  floatingAssets:        "Floating assets"
  shortTermLiabilities: "Short term liabilities"
  debtToVendor:         "Debt to vendor"
  date:                 "Report date"
</i18n>
