<template lang="pug">
.application-corporate-annual-report-list.card
  .card-header {{ $t('title') }}
    button.btn.btn-link.fa-pull-right.py-0.animated.fadeIn(
      :title         = '$t("common:add")'
      @click.prevent = 'showAddReport = true'
    ) {{ $t('addRreport') }}
  .card-body
    .main-row
      .main-column(:style = '{ minWidth: "310px" }')
        .main-column--cell.border-bottom(
          :style = '{ minHeight: "24px" }'
        ) {{ $t("reportYear") }}
        .main-column--cell.border-bottom.font-weight-bold {{ $t("assets") }}
        .main-column--cell.border-bottom {{ $t("fixedAssets") }}
        .main-column--cell.border-bottom {{ $t("intangibles") }}
        .main-column--cell.border-bottom {{ $t("property") }}
        .main-column--cell.border-bottom {{ $t("longTermInvestments") }}
        .main-column--cell.border-bottom {{ $t("otherFixedAssets") }}
        .main-column--cell.border-bottom {{ $t("currentAssets") }}
        .main-column--cell.border-bottom {{ $t("inventories") }}
        .main-column--cell.border-bottom {{ $t("accountsReceivable") }}
        .main-column--cell.border-bottom {{ $t("shortTermInvestments") }}
        .main-column--cell.border-bottom {{ $t("cash") }}
        .main-column--cell.border-bottom {{ $t("accruedIncomeDeferredExpenses") }}
        .main-column--cell.border-bottom {{ $t("totalAssets") }}
        .main-column--cell.border-bottom.font-weight-bold {{ $t("liabilities") }}
        .main-column--cell.border-bottom {{ $t("equity") }}
        .main-column--cell.border-bottom {{ $t("addedCapital") }}
        .main-column--cell.border-bottom {{ $t("reserves") }}
        .main-column--cell.border-bottom {{ $t("retainedEarnings") }}
        .main-column--cell.border-bottom {{ $t("subsidies") }}
        .main-column--cell.border-bottom {{ $t("provisions") }}
        .main-column--cell.border-bottom.font-weight-bold {{ $t("longTermLiabilities") }}
        .main-column--cell.border-bottom {{ $t("longTermTotal") }}
        .main-column--cell.border-bottom {{ $t("longTermDebts") }}
        .main-column--cell.border-bottom {{ $t("longTermAccountsPayable") }}
        .main-column--cell.border-bottom {{ $t("longTermOther") }}
        .main-column--cell.border-bottom.font-weight-bold  {{ $t("shortTermLiabilities") }}
        .main-column--cell.border-bottom {{ $t("shortTermTotal") }}
        .main-column--cell.border-bottom {{ $t("shortTermDebts") }}
        .main-column--cell.border-bottom {{ $t("shortTermAccountsPayable") }}
        .main-column--cell.border-bottom {{ $t("shortTermOther") }}
        .main-column--cell.border-bottom {{ $t("deferredIncome") }}
        .main-column--cell.border-bottom.font-weight-bold {{ $t("incomeStatement") }}
        .main-column--cell.border-bottom {{ $t("salesRevenue") }}
        .main-column--cell.border-bottom {{ $t("costOfSales") }}
        .main-column--cell.border-bottom {{ $t("operatingExpenses") }}
        .main-column--cell.border-bottom {{ $t("adminExpenses") }}
        .main-column--cell.border-bottom {{ $t("operatingProfit") }}
        .main-column--cell.border-bottom {{ $t("otherIncome") }}
        .main-column--cell.border-bottom {{ $t("otherExpenses") }}
        .main-column--cell.border-bottom {{ $t("interestExpense") }}
        .main-column--cell.border-bottom {{ $t("profitBeforeTax") }}
        .main-column--cell.border-bottom {{ $t("taxesPaid") }}
        .main-column--cell.border-bottom {{ $t("profitLoss") }}
        .main-column--cell.border-bottom {{ $t("depreciation") }}
        .main-column--cell {{ $t("amortization") }}
      .col.overflow-x
          application-corporate-annual-report-list-item(
            v-for   = '(report, index) in reports'
            :key    = 'report.annualReportYear'
            :report = 'report'
            @edit   = '$emit("update", { ...$event, index })'
            @remove = '$emit("remove", { ...$event,index })'
          )
  application-corporate-annual-report-modal(
    v-model = 'showAddReport'
    @submit = '$emit("update", $event)'
  )
</template>

<script>
import FiTable from '@/components/FiTable'
import ApplicationCorporateAnnualReportModal from '@/views/applications/ApplicationCorporateAnnualReportModal'
import ApplicationCorporateAnnualReportListItem from '@/views/applications/ApplicationCorporateAnnualReportListItem'
import FiDataField from '@/components/FiDataField'

export default {
  name: 'application-corporate-annual-report-list',
  components: { FiDataField, ApplicationCorporateAnnualReportListItem, ApplicationCorporateAnnualReportModal, FiTable },
  props: {
    reports: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    showAddReport: false
  })
}
</script>


<i18n>
en:
  title:                          "Annual reports"
  addRreport:                     "+ Add report"
  annualReportYear:               "Year"
  annualReportAmortization:       "Amortization"
  annualReportCurrentAssets:      "Current assets"
  annualReportCurrentLiabilities: "Current liabilities"
  annualReportDate:               "Report date"
  annualReportDebt:               "Debt"
  annualReportDebtToVendors:      "Debt to vendors"
  annualReportDepreciation:       "Depreciation"
  annualReportEquity:             "Equity"
  annualReportInterestExpense:    "Interest expense"
  annualReportInventories:        "Inventories"
  annualReportPrepayments:        "Prepayments"
  annualReportProfit:              "Profit"
  annualReportProfitLoss:          "Profit loss"
  annualReportTaxesPaid:          "Taxes paid"
  annualReportTurnover:           "Turnover"
  annualReportFloatingAssets:     "Floating assets"
  annualReportShortTermLiabilities: "Short-term liabilities"


  reportYear: "Year"
  assets: "Assets"
  fixedAssets: "Fixed Assets"
  intangibles: "Intangibles"
  property: "Property and Equipment"
  longTermInvestments: "Longterm Investments"
  otherFixedAssets: "Other Assets"
  currentAssets: "Current Assets"
  inventories: "Inventory"
  accountsReceivable: "Accounts Receivable"
  shortTermInvestments: "Short Term Investments"
  cash: "Cash"
  accruedIncomeDeferredExpenses: "Accrued Income and Deferred Expenses"
  totalAssets: "Total Assets"
  liabilities: "Liabilities"
  equity: "Equity"
  addedCapital: "Addition Paid in Capital"
  reserves: "Reserves"
  retainedEarnings: "Retained Earnings"
  subsidies: "Subsidies"
  provisions: "Provisions"

  longTermLiabilities: "Long Term Liabilities"

  longTermTotal: "Long Term Total"
  longTermDebts: "Debts"
  longTermAccountsPayable: "Accounts Payable"
  longTermOther: "Other"

  shortTermLiabilities: "Short Term Liabilities"

  shortTermTotal: "Short Term Total"
  shortTermDebts: "Debts"
  shortTermAccountsPayable: "Accounts Payable"
  shortTermOther: "Other"
  deferredIncome: "Accrued Expenses and Deferred Income"

  incomeStatement: "Income statement"

  salesRevenue: "Sales Revenue"
  costOfSales: "Cost of Sales"
  operatingExpenses: "Operating Expenses"
  adminExpenses: "Admin Expenses"
  operatingProfit: "Operating Profit"
  otherIncome: "Other Income"
  otherExpenses: "Other Expenses"
  interestExpense: "Interest Expense"
  profitBeforeTax: "Profit Before Taxes"
  taxesPaid: "Taxes Paid"
  profitLoss: "Profit / Loss"
  depreciation: "Depreciation"
  amortization: "Amortization"
et:
  title:                          "Annual reports"
  addRreport:                     "+ Add report"
  annualReportYear:               "Year"
  annualReportAmortization:       "Amortization"
  annualReportCurrentAssets:      "Current assets"
  annualReportCurrentLiabilities: "Current liabilities"
  annualReportDate:               "Report date"
  annualReportDebt:               "Debt"
  annualReportDebtToVendors:      "Debt to vendors"
  annualReportDepreciation:       "Depreciation"
  annualReportEquity:             "Equity"
  annualReportInterestExpense:    "Interest expense"
  annualReportInventories:        "Inventories"
  annualReportPrepayments:        "Prepayments"
  annualReportProfit:              "Profit"
  annualReportProfitLoss:          "Profit loss"
  annualReportTaxesPaid:          "Taxes paid"
  annualReportTurnover:           "Turnover"
  annualReportFloatingAssets:     "Floating assets"
  annualReportShortTermLiabilities: "Short-term liabilities"

  reportYear: "Year"
  assets: "Assets"
  fixedAssets: "Fixed Assets"
  intangibles: "Intangibles"
  property: "Property and Equipment"
  longTermInvestments: "Longterm Investments"
  otherFixedAssets: "Other Assets"
  currentAssets: "Current Assets"
  inventories: "Inventory"
  accountsReceivable: "Accounts Receivable"
  shortTermInvestments: "Short Term Investments"
  cash: "Cash"
  accruedIncomeDeferredExpenses: "Accrued Income and Deferred Expenses"
  totalAssets: "Total Assets"
  liabilities: "Liabilities"
  equity: "Equity"
  addedCapital: "Addition Paid in Capital"
  reserves: "Reserves"
  retainedEarnings: "Retained Earnings"
  subsidies: "Subsidies"
  provisions: "Provisions"

  longTermLiabilities: "Long Term Liabilities"

  longTermTotal: "Long Term Total"
  longTermDebts: "Debts"
  longTermAccountsPayable: "Accounts Payable"
  longTermOther: "Other"

  shortTermLiabilities: "Short Term Liabilities"

  shortTermTotal: "Short Term Total"
  shortTermDebts: "Debts"
  shortTermAccountsPayable: "Accounts Payable"
  shortTermOther: "Other"
  deferredIncome: "Accrued Expenses and Deferred Income"

  incomeStatement: "Income statement"

  salesRevenue: "Sales Revenue"
  costOfSales: "Cost of Sales"
  operatingExpenses: "Operating Expenses"
  adminExpenses: "Admin Expenses"
  operatingProfit: "Operating Profit"
  otherIncome: "Other Income"
  otherExpenses: "Other Expenses"
  interestExpense: "Interest Expense"
  profitBeforeTax: "Profit Before Taxes"
  taxesPaid: "Taxes Paid"
  profitLoss: "Profit / Loss"
  depreciation: "Depreciation"
  amortization: "Amortization"
ru:
  title:                          "Annual reports"
  addRreport:                     "+ Add report"
  annualReportYear:               "Year"
  annualReportAmortization:       "Amortization"
  annualReportCurrentAssets:      "Current assets"
  annualReportCurrentLiabilities: "Current liabilities"
  annualReportDate:               "Report date"
  annualReportDebt:               "Debt"
  annualReportDebtToVendors:      "Debt to vendors"
  annualReportDepreciation:       "Depreciation"
  annualReportEquity:             "Equity"
  annualReportInterestExpense:    "Interest expense"
  annualReportInventories:        "Inventories"
  annualReportPrepayments:        "Prepayments"
  annualReportProfit:              "Profit"
  annualReportProfitLoss:          "Profit loss"
  annualReportTaxesPaid:          "Taxes paid"
  annualReportTurnover:           "Turnover"
  annualReportFloatingAssets:     "Floating assets"
  annualReportShortTermLiabilities: "Short-term liabilities"

  reportYear: "Year"
  assets: "Assets"
  fixedAssets: "Fixed Assets"
  intangibles: "Intangibles"
  property: "Property and Equipment"
  longTermInvestments: "Longterm Investments"
  otherFixedAssets: "Other Assets"
  currentAssets: "Current Assets"
  inventories: "Inventory"
  accountsReceivable: "Accounts Receivable"
  shortTermInvestments: "Short Term Investments"
  cash: "Cash"
  accruedIncomeDeferredExpenses: "Accrued Income and Deferred Expenses"
  totalAssets: "Total Assets"
  liabilities: "Liabilities"
  equity: "Equity"
  addedCapital: "Addition Paid in Capital"
  reserves: "Reserves"
  retainedEarnings: "Retained Earnings"
  subsidies: "Subsidies"
  provisions: "Provisions"

  longTermLiabilities: "Long Term Liabilities"

  longTermTotal: "Long Term Total"
  longTermDebts: "Debts"
  longTermAccountsPayable: "Accounts Payable"
  longTermOther: "Other"

  shortTermLiabilities: "Short Term Liabilities"

  shortTermTotal: "Short Term Total"
  shortTermDebts: "Debts"
  shortTermAccountsPayable: "Accounts Payable"
  shortTermOther: "Other"
  deferredIncome: "Accrued Expenses and Deferred Income"

  incomeStatement: "Income statement"

  salesRevenue: "Sales Revenue"
  costOfSales: "Cost of Sales"
  operatingExpenses: "Operating Expenses"
  adminExpenses: "Admin Expenses"
  operatingProfit: "Operating Profit"
  otherIncome: "Other Income"
  otherExpenses: "Other Expenses"
  interestExpense: "Interest Expense"
  profitBeforeTax: "Profit Before Taxes"
  taxesPaid: "Taxes Paid"
  profitLoss: "Profit / Loss"
  depreciation: "Depreciation"
  amortization: "Amortization"
</i18n>

<style lang="scss" scoped>
.main-column {
  display: flex;
  flex-direction: column;

  &--cell {
    width: 100%;
    min-height: 22px;
    padding-inline: 15px;
  }
}

.main-row {
  display: flex;
}

.overflow-x {
  display: flex;
  // overflow-x: scroll;
  overflow-x: auto;
  padding: 0;
}
.border-bottom {
 border-bottom: 1px solid rgba(0, 0, 0, .125);
}
.overflow-x::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  background-color: #f9f9fd;
}

.overflow-x::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #20a8d8;
}

.overflow-x::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
  border-radius: 10px;
  background-color: #f9f9fd;
}
</style>
