<template lang="pug">
.customer-payment-info-incoming-payment-list
  fi-table(:fields     = 'fields'
           :items      = 'briefCustomerPaymentsList'
           loader      = 'customer:payments:fetch'
           :emptyLabel = '$t("notFound")'
           responsive)
    template(#default = '{ items }')
      customer-payment-info-incoming-payment-list-item(v-for    = 'payment in items'
                                                       :key     = 'payment.id'
                                                       :payment = 'payment')
</template>


<script>
import CustomerPaymentInfoIncomingPaymentListItem from '@/views/customers/CustomerPaymentInfoIncomingPaymentListItem'
import FiTable from '@/components/FiTable'

export default {
  name: 'customer-payment-info-incoming-payment-list',

  components: { CustomerPaymentInfoIncomingPaymentListItem, FiTable },

  props: {
    briefCustomerPaymentsList: {
      type: Array,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    fields () {
      return [
        {
          key: 'date',
          label: this.$t('date')
        },
        {
          key: 'payerName',
          label: this.$t('payerName')
        },
        {
          key: 'description',
          label: this.$t('description')
        },
        {
          key: 'amount',
          label: this.$t('amount'),
          class: 'money'
        },
        {
          key: 'reference',
          label: this.$t('reference')
        }
      ]
    }
  }
}
</script>


<i18n>
en:
  date:        "Date"
  payerName:   "From / To"
  description: "Description"
  amount:      "Amount"
  reference:   "Reference"
  notFound:    "Payments not found"
et:
  date:        "Date"
  payerName:   "From / To"
  description: "Description"
  amount:      "Amount"
  reference:   "Reference"
  notFound:    "Payments not found"
ru:
  date:        "Date"
  payerName:   "From / To"
  description: "Description"
  amount:      "Amount"
  reference:   "Reference"
  notFound:    "Payments not found"
</i18n>
