<template lang="pug">
.customer-overview-payment.card
  .card-header.bg-primary
    h4.card-title.mb-0 {{ balance | money }}
    | {{ $t('balance') }}
  .card-body
    table.table.table-hover
      tbody
        tr(v-if = '$vueLoading.isLoading("customer:payments:fetch")'): td.text-center(colspan = 3): i.fa.fa-spinner.fa-pulse.fa-lg
        tr(v-else-if = 'payments.length === 0'): td.text-center(colspan = 3) {{ $t('notFound') }}
        template(v-else)
          customer-payment-info-incoming-payment-list-item(v-for    = 'payment in payments'
                                                           :key     = 'payment.id'
                                                           :payment = 'payment')
</template>

<script>
import CustomerPaymentInfoPaymentList from './CustomerPaymentInfoPaymentList'
import CustomerPaymentInfoIncomingPaymentListItem from '@/views/customers/CustomerPaymentInfoIncomingPaymentListItem'
import { mapActions, mapGetters, mapState } from 'vuex'
import orderBy from 'lodash/orderBy'

export default {
  name: 'customer-overview-payment',

  components: {
    CustomerPaymentInfoIncomingPaymentListItem,
    CustomerPaymentInfoPaymentList
  },

  i18nOptions: {},

  computed: {
    ...mapState('customers', ['customerPayments', 'customer']),
    ...mapGetters('classifiers', ['classifierByName']),
    ...mapState('settings', ['settings']),
    payments () {
      return orderBy(this.customerPayments, ['paymentDate'], ['desc']).slice(0, 3)
    },
    balance () {
      const prepaymentAccountType = this.classifierByName('accountTypes', 'PREPAYMENT') ?? {}
      // const currency = this.classifierByName('currencies', this.settings.currency) ?? {}
      return this.customer.accounts
        .filter(account => account.accountTypeId === prepaymentAccountType.id)
        // .map(account => account.accountEntries.find(entry => entry.currencyId === currency.id))
        // .filter(entry => entry)
        .reduce((memo, entry) => memo + entry.balance, 0)
    }

  },

  created () {
    this.loadCustomerPayments()
  },

  methods: mapActions('customers', ['loadCustomerPayments'])
}
</script>


<i18n>
en:
  balance:  "Current balance"
  notFound: "Payments not found"
et:
  balance:  "Current balance"
  notFound: "Payments not found"
ru:
  balance:  "Current balance"
  notFound: "Payments not found"
</i18n>
