<template lang = "pug">
.customer-list.animated.fadeIn
  .card
    .card-header
      | {{ $t('title') }}
      router-link.float-right(
        to = 'new'
        append
      ) {{ $t('newLink') }}
    .card-body
      form.form-horizontal
        .row
          .form-group.col-lg-6
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-search
              input.form-control(
                v-model.trim = 'searchParams.criteria'
                :placeholder = '$t("searchPlaceholder")'
                type         = 'text'
              )
          .form-group.col-lg-6
            range-input-date(
              :title       = '$t("registered")'
              :start.sync  = 'searchParams.startDate'
              :end.sync    = 'searchParams.endDate'
            )
        .row
          .form-group.col-lg-4
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-users
              fi-select(
                v-model.number = 'searchParams.roleId'
                :options       = 'optionsFromClassifier("roles")'
                :placeholder   = '$t("rolesPlaceholder")'
              )
          .form-group.col-lg-4
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-chart-pie
              fi-select(
                v-model.number = 'searchParams.sectorId'
                :options       = 'optionsFromClassifier("sectors")'
                :placeholder   = '$t("sectorsPlaceholder")'
              )
          .form-group.col-lg-4
            .input-group
              .input-group-prepend: span.input-group-text
                i.fa.fa-spinner.fa-pulse(v-if = '$vueLoading.isLoading("classifiers:managers:fetch")')
                i.fa.fa-user(v-else)
              fi-select(
                v-model.number = 'searchParams.customerManagerId'
                :disabled      = '$vueLoading.isLoading("classifiers:managers:fetch")'
                :options       = 'optionsFromManagers'
                :placeholder   = '$t("managersPlaceholder")'
              )
      fi-table(
        :fields      = 'fields'
        :items       = 'customers'
        loader       = 'customers:fetch'
        :empty-label = '$t("notFound")'
        :sort.sync   = 'searchParams.sort'
        responsive
      )
        template(#default = '{ items }')
          customer-list-item.animated.fadeIn(
            v-for     = 'customer in items'
            :key      = 'customer.id'
            :customer = 'customer'
          )

      fi-pagination(
        v-if    = 'customersPages > 1'
        :pages  = 'customersPages'
        v-model = 'pagination.page'
      )
</template>


<script>
import FiPagination from '@/components/FiPagination'
import FiSelect from '@/components/FiSelect'
import FiTable from '@/components/FiTable'
import RangeInputDate from '@/components/RangeInputDate'
import debounce from 'lodash/debounce'
import { mapActions, mapGetters, mapState } from 'vuex'
import CustomerListItem from './CustomerListItem'
import pickBy from 'lodash/pickBy'
import pick from 'lodash/pick'
import { pagination } from '@/const'

const searchParams = {
  criteria: '',
  startDate: null,
  endDate: null,
  roleId: '',
  sectorId: '',
  customerManagerId: '',
  sort: 'createdAt,desc'
}

export default {
  name: 'customer-list',

  components: {
    FiTable,
    FiSelect,
    FiPagination,
    RangeInputDate,
    CustomerListItem
  },

  i18nOptions: {},

  data: (vm) => ({
    searchParams: { ...searchParams, ...pick(vm.$route.query, Object.keys(searchParams)) },
    pagination: { ...pagination, ...pick(vm.$route.query, Object.keys(pagination)) }
  }),

  computed: {
    ...mapState('customers', ['customers', 'customersPages']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'optionsFromManagers']),
    fields () {
      return [
        {
          key: 'customer',
          label: this.$t('customer'),
          sort: ['firstName', 'lastName', 'customerName']
        },
        {
          key: 'email',
          label: this.$t('email')
        },
        {
          key: 'phone',
          label: this.$t('phone')
        },
        {
          key: 'sector',
          label: this.$t('sector'),
          sort: 'sectorId'
        },
        {
          key: 'roles',
          label: this.$t('roles')
        },
        {
          key: 'registered',
          label: this.$t('registered')
        },
        {
          key: 'manager',
          label: this.$t('manager'),
          sort: 'customerManagerId'
        }
      ]
    },
    searchQuery () {
      return pickBy({ ...this.searchParams, ...this.pagination })
    }
  },

  watch: {
    searchParams: {
      handler () {
        this.pagination.page = 0
        this.search()
      },
      deep: true
    },
    'pagination.page' () {
      this.search()
    },
    '$route.query': {
      handler (query) {
        this.loadCustomers({ filters: query })
      },
      deep: true
    }
  },

  created () {
    this.loadManagers()
    this.loadCustomers({ filters: this.searchQuery })
  },

  methods: {
    ...mapActions('classifiers', ['loadManagers']),
    ...mapActions('customers', ['loadCustomers']),
    search: debounce(
      function () {
        this.$router.push({ query: this.searchQuery })
      }, 250
    )
  }
}
</script>


<i18n>
en:
  title:                "Customers"
  newLink:              "+ New customer"
  searchPlaceholder:    "Search by name, id code, contacts, etc."
  registered:           "Registered"
  rolesPlaceholder:     "All roles"
  sectorsPlaceholder:   "All sectors"
  managersPlaceholder:  "All customer managers"
  customer:             "Customer"
  email:                "Email"
  phone:                "Phone"
  roles:                "Roles"
  sector:               "Sector"
  manager:              "Manager"
  notFound:             "Customers not found"
et:
  title:                "Customers"
  newLink:              "+ New customer"
  searchPlaceholder:    "Search by name, id code, contacts, etc."
  registered:           "Registered"
  rolesPlaceholder:     "All roles"
  sectorsPlaceholder:   "All sectors"
  managersPlaceholder:  "All customer managers"
  customer:             "Customer"
  email:                "Email"
  phone:                "Phone"
  roles:                "Roles"
  sector:               "Sector"
  manager:              "Manager"
  notFound:             "Customers not found"
ru:
  title:                "Customers"
  newLink:              "+ New customer"
  searchPlaceholder:    "Search by name, id code, contacts, etc."
  registered:           "Registered"
  rolesPlaceholder:     "All roles"
  sectorsPlaceholder:   "All sectors"
  managersPlaceholder:  "All customer managers"
  customer:             "Customer"
  email:                "Email"
  phone:                "Phone"
  roles:                "Roles"
  sector:               "Sector"
  manager:              "Manager"
  notFound:             "Customers not found"
</i18n>
