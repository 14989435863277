<template lang="pug">
fi-modal.customer-prepayment-account-payout-modal(
  ref        = 'modal'
  v-model    = 'showModal'
  size       = 'lg'
  ok-variant = 'secondary'
  lazy
  @hide      = 'onHide'
  @shown     = 'resetData'
)
  template(#modal-title)
    .row.text-nowrap
      .col {{ $t("title") }}
      .col {{ account.number }}
      .col.font-weight-bold {{ accountEntry.availableBalance | money }}
  form(@submit.prevent = 'onSubmit')
    payment-outgoing-fields(
      :payment-data     = 'paymentData'
      :validation-state = '$v.paymentData'
      :customers        = 'systemCustomersForSelect'
      :accounts         = 'selectedCustomerAccounts'
      :selected-customer = 'selectedCustomerForSelect'
      @find-customers   = 'findSystemCustomers'
      @select-customer  = 'selectCustomer'
      is-new
      editable
    )

  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'sending'
      @click.prevent = 'onSubmit'
    )
      | {{ $t('common:send') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'sending')
    button.btn.btn-secondary(
      :disabled      = 'sending'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t('common:cancel') }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiModal from '@/components/FiModal'
import { validation, validators } from '@/validation'
import PaymentOutgoingFields from '@/views/payments/PaymentOutgoingFields'
import { mapActions, mapGetters, mapState } from 'vuex'
import api from '@/api'

export default {
  name: 'customer-prepayment-account-payout-modal',

  components: {
    PaymentOutgoingFields,
    FiMoneyInput,
    FiFormField,
    FiModal
  },

  mixins: [validation],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    accountEntry: {
      type: Object,
      required: true
    },
    account: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data: (vm) => ({
    selectedCustomer: vm.customer,
    paymentData: {
      amount: '',
      description: '',
      externalAccount: '',
      customerFullName: '',
      paymentWayId: '',
      idCode: '',
      customerId: ''
    }
  }),

  computed: {
    ...mapState('customers', ['customer']),
    ...mapGetters('customers', ['customerName', 'systemCustomersForSelect']),
    ...mapGetters('classifiers', ['classifierByName', 'classifierById']),
    showModal: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    sending () {
      return this.$vueLoading.isLoading('customer:payout:save')
    },
    selectedCustomerAccounts () {
      const prepaymentAccount = this.classifierByName('accountTypes', 'PREPAYMENT') ?? {}
      return (this.classifierById('paymentWays', this.paymentData.paymentWayId).name === 'external'
        ? this.selectedCustomer?.externalBankAccounts?.map(({ accountExternalNumber }) => accountExternalNumber)
        : this.selectedCustomer?.accounts.filter(({ id, accountTypeId }) => id !== this.account.id && accountTypeId === prepaymentAccount.id).map(({ number }) => number)) ?? []
    },
    selectedCustomerForSelect () {
      return this.selectedCustomer ? {
        text: this.customerName(this.selectedCustomer),
        value: this.selectedCustomer?.id
      } : this.selectedCustomer
    }
  },

  watch: {
    'paymentData.customerFullName' () {
      if (this.$v.paymentData.customerFullName.$dirty && this.selectedCustomer) {
        this.selectedCustomer = null
        this.paymentData = {
          ...this.paymentData,
          idCode: '',
          customerId: ''
        }
      }
    }
  },

  validations () {
    return {
      paymentData: {
        description: {
          required: validators.required
        },
        amount: {},
        externalAccount: {
          required: validators.required
        },
        customerFullName: {},
        paymentWayId: {
          required: validators.required
        },
        customerId: {},
        idCode: {}
      }
    }
  },

  methods: {
    ...mapActions('customers', ['createCustomerPayout', 'findSystemCustomers']),
    onHide (e) {
      if (this.saving) e.preventDefault()
    },
    async onSubmit () {
      if (this.validate()) {
        await this.createCustomerPayout({ ...this.paymentData, prepaymentAccountId: this.account.id })
      }
    },
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.paymentData = {
        ...this.paymentData,
        amount: this.accountEntry.availableBalance,
        customerFullName: this.customerName(this.selectedCustomer),
        externalAccount: this.selectedCustomer?.externalBankAccounts.find(({ generalAccount }) => generalAccount)?.accountExternalNumber,
        paymentWayId: this.classifierByName('paymentWays', 'external')?.id,
        idCode: this.selectedCustomer?.idCode,
        customerId: this.selectedCustomer?.id
      }
      this.$v.$reset()
    },
    async selectCustomer (selectedCustomer) {
      if (!selectedCustomer) {
        this.selectedCustomer = selectedCustomer
        this.paymentData = {
          ...this.paymentData,
          customerFullName: '',
          externalAccount: '',
          idCode: '',
          customerId: ''
        }
      } else {
        const { data: customer } = await api.getCustomer({ id: selectedCustomer.value })
        this.$v.paymentData.customerFullName.$reset()
        this.selectedCustomer = customer
        this.paymentData = {
          ...this.paymentData,
          customerFullName: this.customerName(this.selectedCustomer),
          externalAccount: this.selectedCustomer?.externalBankAccounts.find(({ generalAccount }) => generalAccount)?.accountExternalNumber,
          idCode: this.selectedCustomer.idCode,
          customerId: this.selectedCustomer.id
        }
      }
    }
  }
}
</script>


<i18n>
en:
  title:  "Payout"
  amount: "Amount"
et:
  title:  "Payout"
  amount: "Amount"
ru:
  title:  "Payout"
  amount: "Amount"
</i18n>
