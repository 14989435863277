<template lang="pug">
.customer-address-list.card
  .card-header.border-bottom-0
    | {{ $t('title') }}
    a.fa-pull-right(v-if           = '!newForm'
                 href           = '#'
                 @click.prevent = 'newForm = true') {{ $t('newLink') }}
  customer-address-form.card-body.border-top(v-if    = 'newForm'
                                             v-model = 'newForm')
  customer-address-list-item.card-body.border-top(v-for    = 'address in sortedAddresses'
                                                  :key     = 'address.id'
                                                  :address = 'address')
</template>


<script>
import orderBy from 'lodash/orderBy'
import { mapState } from 'vuex'
import CustomerAddressForm from './CustomerAddressForm'
import CustomerAddressListItem from './CustomerAddressListItem'

export default {
  name: 'customer-address-list',

  components: {
    CustomerAddressForm,
    CustomerAddressListItem
  },

  props: {
    addresses: {
      type: Array,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      newForm: false
    }
  },

  computed: {
    ...mapState('customers', ['customer']),
    sortedAddresses () {
      return orderBy(this.addresses, ['isDefault', 'updatedAt'], ['desc', 'desc'])
    }
  }
}
</script>


<i18n>
en:
  title:   "Addresses"
  newLink: "+ New address"
et:
  title:   "Addresses"
  newLink: "+ New address"
ru:
  title:   "Addresses"
  newLink: "+ New address"
</i18n>
