<template lang="pug">
tr.customer-payment-info-incoming-payment-list-item
  td {{ $t('common:formatDate', { value: payment.paymentDate }) }}
  td {{ payment.fromName }}
  td {{ payment.description }}
  td.money {{ payment.amount | money }}
  td: router-link(:to = '{ name: "Loans", query: { criteria: payment.referenceNumber } }') {{ payment.referenceNumber }}
</template>


<script>
export default {
  name: 'customer-payment-info-incoming-payment-list-item',

  props: {
    payment: {
      type: Object,
      required: true
    }
  }
}
</script>
