<template lang="pug">
tr.customer-factoring-third-party-list-item
  td: router-link(:to = '{ name: "FactoringContract", params: { id: thirdParty.contractId.toString() }}') {{ thirdParty.agreementNumber }}
  td {{ product.name }}
  td: router-link(:to = '{ name: "Customer", params: { id: thirdParty.customerId.toString() }}') {{ thirdParty.customer.fullName }}
  td.money {{ thirdParty.limit | money }}
  td.money {{ thirdParty.balance | money }}
  td.money {{ thirdParty.debt | money }}
  td {{ $t('common:formatDate', { value: thirdParty.startDate }) }}
  td {{ $t('common:formatDate', { value: thirdParty.endDate }) }}
  td
    router-link(:to = '{ name: "CustomerPaymentInfo", params: { id: thirdParty.customerId } }') {{ thirdParty.referenceNumber }}
  td.money {{ thirdParty.nextPaymentAmount | money }}
  td {{ $t('common:formatDate', { value: thirdParty.nextPaymentDate }) }}
  td {{ $t('common:formatDate', { value: thirdParty.updatedAt }) }}
  td: .badge(:class = '`badge-${loanStatusColor(thirdParty.status.code)}`') {{ thirdParty.status.code }}
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'customer-factoring-third-party-list-item',

  props: {
    thirdParty: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierByName']),
    ...mapGetters('loans', ['loanStatusColor']),
    ...mapGetters('products', ['productById']),
    product () {
      return this.productById(this.thirdParty.productId)
    },
    status () {
      return this.classifierByName(this.thirdParty?.status?.classifier, this.thirdParty?.status?.code) ?? {}
    }
  }
}
</script>
