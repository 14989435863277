<template lang="pug">
tr.customer-relation-list-item
  td: router-link(:to = '{ name: "Customer", params: { id: relatedCustomer.id } }') {{ name }}
  td {{ classifierById('relationTypes', relation.relationTypeId).human }}
  template(v-if = 'full')
    td
      template(v-if = 'relation.validFrom') {{ $t('common:formatDate', { value: relation.validFrom }) }}
      template(v-else) ∞
    td
      template(v-if = 'relation.validTo') {{ $t('common:formatDate', { value: relation.validTo }) }}
      template(v-else) ∞
    td {{ relation.comment }}
  template(v-if = 'editMain')
    td: a(:href = '`tel: ${phoneNumber}`') {{ phoneNumber }}
    td: a(:href = '`mailto: ${email}`') {{ email }}
  template(v-if = 'full')
    td.text-center(v-if = 'editMain')
      b-form-radio(v-model = 'mainContact'
                   name    = 'mainContact'
                   :value  = 'true')
    td.text-center(v-else)
      i.fa.fa-check-square(v-if = 'relation.mainContact')
    td.text-nowrap
      .fa-pull-right(v-if = 'removing')
        | {{ $t('common:removing') }}
        | &nbsp;
        i.fa.fa-spinner.fa-pulse.fa-lg
      .fa-pull-right(v-else)
        button.btn.btn-sm.btn-link(
          v-if           = 'editable'
          @click.prevent = 'editing = true'
        ): i.far.fa-edit.fa-lg
        | &nbsp;
        button.btn.btn-sm.btn-link.text-danger(
          v-if         = 'removable'
          :disabled    = 'removing'
          v-fi-confirm = '{ ok: remove, message: $t("removeConfirm") }'
        ): i.far.fa-trash-alt.fa-lg
  customer-relation-new-modal(
    v-model   = 'editing'
    :relation = 'relation'
    title     = 'editRelation'
    :loader   = '`customer:relation:${relation.id}:save`'
    :customer = 'customer'
    @submit   = '$emit("submit", $event)'
  )
</template>


<script>
import FiDataField from '@/components/FiDataField'
import FiSwitch from '@/components/FiSwitch'
import { mapActions, mapGetters } from 'vuex'
import CustomerRelationNewModal from './CustomerRelationNewModal'
import { findCustomerMainContact } from '@/helpers'

export default {
  name: 'customer-relation-list-item',

  components: { FiDataField, FiSwitch, CustomerRelationNewModal },

  props: {
    relation: {
      type: Object,
      required: true
    },
    full: {
      type: Boolean,
      default: false
    },
    removable: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    field: {
      type: String,
      required: true
    },
    editMain: {
      type: Boolean,
      default: false
    },
    customer: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      editing: false
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    ...mapGetters('customers', ['customerName']),
    name () {
      return this.customerName(this.relatedCustomer)
    },
    removing () {
      return this.$vueLoading.isLoading(`customer:relation:${this.relation.id}:remove`)
    },
    relatedCustomer () {
      return this.relation[this.field]
    },
    mainContact: {
      get () {
        return this.relation.mainContact
      },
      set (val) {
        if (!val) return
        this.$emit('set-main', this.relation)
      }
    },
    email () {
      return findCustomerMainContact(this.relatedCustomer.emails)
    },
    phoneNumber () {
      return findCustomerMainContact(this.relatedCustomer.phoneNumbers)
    }
  },

  methods: {
    ...mapActions('customers', ['removeCustomerRelation', 'updateCustomerRelation']),
    async remove ({ done }) {
      await this.removeCustomerRelation({ id: this.relation.id })
      done()
    }
  }
}
</script>


<i18n>
en:
  removeConfirm:  "Are you sure you want to delete this relation?"
  updateSuccess:  "Main contact changed successfully"
et:
  removeConfirm:  "Are you sure you want to delete this relation?"
  updateSuccess:  "Main contact changed successfully"
ru:
  removeConfirm:  "Are you sure you want to delete this relation?"
  updateSuccess:  "Main contact changed successfully"
</i18n>
