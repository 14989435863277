<template lang="pug">
.customer-payment-info.animated.fadeId
  .row
    .col-md
      customer-prepayment-account-list(
        v-model   = 'selectedAccount'
        :accounts = 'customerPrepaymentAccounts'
      )
      customer-payment-info-payment-list(:selected-account = 'selectedAccount')
</template>


<script>
import CustomerPaymentInfoPaymentList from '@/views/customers/CustomerPaymentInfoPaymentList'
import CustomerPrepaymentAccountList from './CustomerPrepaymentAccountList'
import CustomerDebtList from './CustomerDebtList'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'customer-payment-info',

  components: {
    CustomerPaymentInfoPaymentList,
    CustomerPrepaymentAccountList,
    CustomerDebtList
  },

  props: {
    reference: {
      type: [Number, String],
      default: null
    }
  },

  data: (vm) => ({
    selectedAccount: vm.reference
  }),

  computed: {
    ...mapState('customers', ['customerPrepaymentAccounts'])
  },

  async created () {
    await this.loadCustomerLoans({ id: this.$route.params.id })
    await this.loadCustomerPrepaymentAccounts({ customerId: this.$route.params.id })
  },

  methods: {
    ...mapActions('customers', ['loadCustomerLoans', 'loadCustomerPrepaymentAccounts'])
  }
}
</script>
