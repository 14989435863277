<template lang="pug">
.customer-contracts
  customer-contract-list(:contracts = 'customerLoans')
    template(#header) {{ $t("loanListTitle") }}
      router-link.float-right(:to  = '{ name: "LoanNew", params: { customer }}') {{ $t('newLoanLink') }}
    template(#contractItem="{ contract }")
      customer-loan-list-item(
        :key  = 'contract.loanId'
        :loan = 'contract'
      )

  customer-deposit-list(
    v-if      = 'isDepositEnabled'
    :deposits = 'customerDeposits'
    :customer = 'customer'
  )
  template(v-if = 'isFactoringEnabled')
    customer-factoring-list(
      :contracts = 'customerFactoring'
      :customer = 'customer'
    )
    customer-factoring-third-party-list(
      :third-parties = 'customerThirdParties'
      :customer      = 'customer'
    )
  customer-application-list
</template>


<script>
import CustomerDepositList from '@/views/customers/CustomerDepositList'
import { mapActions, mapState } from 'vuex'
import CustomerApplicationList from './CustomerApplicationList.vue'
import { isDepositEnabled, isFactoringEnabled } from '@/mixins'
import CustomerFactoringList from '@/views/customers/CustomerFactoringList'
import CustomerFactoringThirdPartyList from '@/views/customers/CustomerFactoringThirdPartyList'
import CustomerContractList from '@/views/customers/CustomerContractList'
import CustomerLoanListItem from '@/views/customers/CustomerLoanListItem'

export default {
  name: 'customer-contracts',

  components: {
    CustomerLoanListItem,
    CustomerContractList,
    CustomerFactoringThirdPartyList,
    CustomerFactoringList,
    CustomerDepositList,
    CustomerApplicationList
  },

  mixins: [isDepositEnabled, isFactoringEnabled],

  computed: {
    ...mapState('customers', ['customerLoans', 'customerDeposits', 'customer', 'customerFactoring', 'customerThirdParties'])
  },

  created () {
    this.loadManagers()
    this.loadCustomerApplications()
    this.loadCustomerLoans({ id: this.$route.params.id })
    if (this.isFactoringEnabled) {
      this.loadCustomerFactoringContracts({ customerId: this.$route.params.id })
      this.loadCustomerFactoringThirdParties({ customerId: this.$route.params.id })
    }

    if (this.isDepositEnabled) {
      this.loadCustomerDeposits({ id: this.$route.params.id })
    }
  },

  methods: {
    ...mapActions('classifiers', ['loadManagers']),
    ...mapActions('customers', [
      'loadCustomerApplications',
      'loadCustomerLoans',
      'loadCustomerDeposits',
      'loadCustomerFactoringContracts',
      'loadCustomerFactoringThirdParties'
    ])
  }
}
</script>


<i18n>
en:
  loanListTitle: "Loans"
  newLoanLink:   "+ New loan"
et:
  loanListTitle: "Loans"
  newLoanLink:   "+ New loan"
ru:
  loanListTitle: "Loans"
  newLoanLink:   "+ New loan"
</i18n>
