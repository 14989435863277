<template lang="pug">
.customer-application-list.animated.fadeIn
  .card
    .card-header
      | {{ $t('title') }}
      application-new-button.fa-pull-right(
        :customer-id   = 'customer.id'
        :customer      = 'customer'
        :product-types = 'productTypesOptions'
      )
    .card-body
      fi-table(
        :fields      = 'fields'
        :items       = 'customerApplications'
        loader       = 'customer:applications:fetch'
        :empty-label = '$t("notFound")'
        responsive
        fixed
      )
        template(#default = '{ items }')
          customer-application-list-item(
            v-for        = 'application in items'
            :key         = 'application.id'
            :application = 'application'
          )

</template>


<script>
import FiTable from '@/components/FiTable'
import { mapGetters, mapState } from 'vuex'
import ApplicationNewButton from '@/views/applications/ApplicationNewButton'
import CustomerApplicationListItem from './CustomerApplicationListItem.vue'

export default {
  name: 'customer-application-list',

  components: {
    FiTable,
    ApplicationNewButton,
    CustomerApplicationListItem
  },

  i18nOptions: {},

  computed: {
    ...mapState('customers', ['customerApplications', 'customer']),
    ...mapGetters('customers', ['isCorporateCustomer']),
    ...mapState('classifiers', ['productTypes']),
    productTypesOptions () {
      return Object.values(this.productTypes)
        .filter(({ productIds, group, corporateCustomer }) => productIds?.length && !/deposit/i.test(group) && corporateCustomer === this.isCorporateCustomer(this.customer))
        .map(({ human, code }) => ({ text: human, value: code }))
    },
    fields () {
      return [
        {
          label: 'ID'
        },
        {
          label: this.$t('product')
        },
        {
          label: this.$t('amount'),
          class: 'money'
        },
        {
          label: this.$t('date')
        },
        {
          label: this.$t('manager')
        },
        {
          label: this.$t('status')
        }
      ]
    }
  }
}
</script>


<i18n>
en:
  title:       "Applications"
  application: "Application"
  date:        "Date"
  product:     "Product"
  amount:      "Amount"
  manager:     "Manager"
  status:      "Status"
  notFound:    "Applications not found"
et:
  title:       "Applications"
  application: "Application"
  date:        "Date"
  product:     "Product"
  amount:      "Amount"
  manager:     "Manager"
  status:      "Status"
  notFound:    "Applications not found"
ru:
  title:       "Applications"
  application: "Application"
  date:        "Date"
  product:     "Product"
  amount:      "Amount"
  manager:     "Manager"
  status:      "Status"
  notFound:    "Applications not found"
</i18n>
