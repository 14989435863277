<template lang="pug">
tr.customer-list-item
  td: router-link(:to = 'customer.id.toString()'
                  append) {{ customerName(customer) }} ({{ customer.idCode }})
  td {{ email }}
  td {{ phone }}
  td {{ classifierById('sectors', customer.sectorId).human }}
  td {{ roles }}
  td {{ $t('common:formatDate', { value: customer.createdAt }) }}
  td {{ managerById(customer.customerManagerId).name }}
</template>


<script>
import { mapGetters } from 'vuex'
import { findCustomerMainContact } from '@/helpers'

export default {
  name: 'customer-list-item',

  props: {
    customer: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('customers', ['customerName']),
    ...mapGetters('classifiers', ['classifierById', 'managerById']),
    phone () {
      return findCustomerMainContact(this.customer.phoneNumbers)
    },
    email () {
      return findCustomerMainContact(this.customer.emails)
    },
    roles () {
      return this.customer.roles.map((role) => this.classifierById('roles', role.roleTypeId).human).join(', ')
    }
  }
}
</script>
