<template lang="pug">
.card.customer-deposit-list.animated.fadeIn
  .card-header {{ $t('title') }}
    router-link.float-right(:to  = '{ name: "DepositNew", params: { customer }}') {{ $t('newLink') }}
  .card-body
    fi-table(
      :items       = 'deposits'
      :fields       = 'fields'
      :empty-label = '$t("notFound")'
      loader       = 'customer:deposits:fetch'
      responsive
      fixed
    )
      template(#default="{ items }")
        customer-deposit-list-item(
          v-for    = 'deposit in items'
          :key     = 'deposit.id'
          :deposit = 'deposit'
        )

</template>


<script>
import CustomerDepositListItem from '@/views/customers/CustomerDepositListItem'
import CustomerLoanListItem from './CustomerLoanListItem.vue'
import FiTable from '@/components/FiTable'

export default {
  name: 'customer-deposit-list',

  components: {
    FiTable,
    CustomerDepositListItem,
    CustomerLoanListItem
  },

  props: {
    deposits: {
      type: Array,
      required: true
    },
    customer: {
      type: Object,
      required: true
    }
  },

  computed: {
    fields () {
      return [
        {
          key: 'contract',
          label: this.$t('contract')
        },
        {
          key: 'creditIssuer',
          label: this.$t('creditIssuer')
        },
        {
          key: 'product',
          label: this.$t('product')
        },
        {
          key: 'amount',
          label: this.$t('amount'),
          class: 'money'
        },
        {
          key: 'balance',
          label: this.$t('balance'),
          class: 'money'
        },
        {
          key: 'debt',
          label: this.$t('debt'),
          class: 'money'
        },
        {
          key: 'startDate',
          label: this.$t('startDate')
        },
        {
          key: 'endDate',
          label: this.$t('endDate')
        },
        {
          key: 'reference',
          label: this.$t('reference')
        },
        {
          key: 'nextPayment',
          label: this.$t('nextPayment'),
          class: 'money'
        },
        {
          key: 'nextPaymentDate',
          label: this.$t('nextPaymentDate')
        },
        {
          key: 'updatedAt',
          label: this.$t('updatedAt')
        },
        {
          key: 'status',
          label: this.$t('status')
        }
      ]
    }
  }
}
</script>


<i18n>
en:
  title:           "Deposits"
  newLink:         "+ New deposit"
  contract:        "Contract"
  product:         "Product"
  amount:          "Initial amount"
  creditIssuer:    "Credit Issuer"
  startDate:       "Start Date"
  endDate:         "End Date"
  reference:       "Reference number"
  balance:         "Balance"
  debt:            "Debt"
  nextPayment:     "Next Payment"
  nextPaymentDate: "Next payment date"
  updatedAt:       "Last updated"
  status:          "Status"
  notFound:        "Contracts not found"
et:
  title:           "Deposits"
  newLink:         "+ New deposit"
  contract:        "Contract"
  product:         "Product"
  amount:          "Initial amount"
  creditIssuer:    "Credit Issuer"
  startDate:       "Start Date"
  endDate:         "End Date"
  reference:       "Reference number"
  balance:         "Balance"
  debt:            "Debt"
  nextPayment:     "Next Payment"
  nextPaymentDate: "Next payment date"
  updatedAt:       "Last updated"
  status:          "Status"
  notFound:        "Contracts not found"
ru:
  title:           "Deposits"
  newLink:         "+ New deposit"
  contract:        "Contract"
  creditIssuer:    "Credit Issuer"
  product:         "Product"
  amount:          "Initial amount"
  startDate:       "Start date"
  endDate:         "End date"
  reference:       "Reference number"
  balance:         "Balance"
  debt:            "Debt"
  nextPayment:     "Next Payment"
  nextPaymentDate: "Next payment date"
  updatedAt:       "Last updated"
  status:          "Status"
  notFound:        "Contracts not found"
</i18n>
