<template lang="pug">
tr.customer-loan-list-item
  td: router-link(:to = '{ name: "Loan", params: { id: loan.loanId.toString() }}') {{ loan.agreementNumber }}
  td {{ loan.creditIssuerName }}
  td {{ product.name }}
  td.money(v-if = 'full') {{ loan.amount | money }}
  td.money {{ loan.balance | money }}
  td.money {{ loan.debts | money }}
  td {{ $t('common:formatDate', { value: loan.periodStart }) }}
  td {{ $t('common:formatDate', { value: loan.periodEnd }) }}
  td
    router-link(:to = '{ name: "CustomerPaymentInfo", params: { id: loan.customerId } }') {{ loan.referenceNumber }}
  td.money {{ loan.nextPaymentAmount | money }}
  td {{ $t('common:formatDate', { value: loan.nextPaymentDate }) }}
  td {{ $t('common:formatDate', { value: loan.updatedAt }) }}
  td: .badge(:class = '`badge-${loanStatusColor(status.id)}`') {{ status.human }}
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'customer-loan-list-item',

  props: {
    loan: {
      type: Object,
      required: true
    },
    full: Boolean
  },

  computed: {
    ...mapGetters('classifiers', ['classifierByName']),
    ...mapGetters('loans', ['loanStatusColor']),
    ...mapGetters('products', ['productById']),
    product () {
      return this.productById(this.loan.productId)
    },
    status () {
      return this.classifierByName(this.loan.status.classifier, this.loan.status.code) ?? {}
    }
  }
}
</script>
