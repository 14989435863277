<template lang="pug">
.card.customer-factoring-third-party-list.animated.fadeIn
  .card-header {{ $t('title') }}
  .card-body
    fi-table(
      :fields      = 'fields'
      :items       = 'thirdParties'
      :empty-label = "$t('notFound')"
      loader       = 'customer:factoring:third:party:fetch'
      responsive
    )
      template(#default = '{ items }')
        customer-factoring-third-party-list-item(
          v-for        = 'thirdParty in items'
          :key         = 'thirdParty.factoringId'
          :third-party = 'thirdParty'
        )
</template>


<script>
import FiTable from '@/components/FiTable'
import CustomerFactoringThirdPartyListItem from '@/views/customers/CustomerFactoringThirdPartyListItem'

export default {
  name: 'customer-factoring-third-party-list',

  components: {
    CustomerFactoringThirdPartyListItem,
    FiTable
  },

  props: {
    thirdParties: {
      type: Array,
      required: true
    },
    customer: {
      type: Object,
      required: true
    }
  },

  computed: {
    fields () {
      return [
        { label: this.$t('contract') },
        { label: this.$t('product') },
        { label: this.$t('thirdParty') },
        {
          label: this.$t('limit'),
          class: 'money'
        },
        {
          label: this.$t('balance'),
          class: 'money'
        },
        {
          label: this.$t('debt'),
          class: 'money'
        },
        {
          label: this.$t('startDate')
        },
        {
          label: this.$t('endDate')
        },
        {
          label: this.$t('reference')
        },
        {
          label: this.$t('nextPayment'),
          class: 'money'
        },
        {
          label: this.$t('nextPaymentDate')
        },
        {
          label: this.$t('updatedAt')
        },
        {
          label: this.$t('status')
        }
      ]
    }
  }
}
</script>


<i18n>
en:
  title:           "Third party agreements"
  contract:        "Contract"
  product:         "Product"
  thirdParty:      "Third party"
  limit:           "Limit"
  startDate:       "Start Date"
  endDate:         "End Date"
  reference:       "Reference number"
  balance:         "Balance"
  debt:            "Debt"
  nextPayment:     "Next Payment"
  nextPaymentDate: "Next payment date"
  updatedAt:       "Last updated"
  status:          "Status"
  notFound:        "Contracts not found"
et:
  title:           "Third party agreements"
  contract:        "Contract"
  product:         "Product"
  thirdParty:      "Third party"
  limit:           "Limit"
  startDate:       "Start Date"
  endDate:         "End Date"
  reference:       "Reference number"
  balance:         "Balance"
  debt:            "Debt"
  nextPayment:     "Next Payment"
  nextPaymentDate: "Next payment date"
  updatedAt:       "Last updated"
  status:          "Status"
  notFound:        "Contracts not found"
ru:
  title:           "Third party agreements"
  contract:        "Contract"
  product:         "Product"
  thirdParty:      "Third party"
  limit:           "Limit"
  startDate:       "Start date"
  endDate:         "End date"
  reference:       "Reference number"
  balance:         "Balance"
  debt:            "Debt"
  nextPayment:     "Next Payment"
  nextPaymentDate: "Next payment date"
  updatedAt:       "Last updated"
  status:          "Status"
  notFound:        "Contracts not found"
</i18n>
