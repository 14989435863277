<template lang="pug">
.customer-history.animated.fadeIn.card
  .card-header {{ $t('title') }}
  .card-body
    .text-center(v-if = '$vueLoading.isLoading("customer:history:fetch")'): i.fa.fa-spinner.fa-pulse.fa-lg
    .row.animated.fadeIn(v-else)
      .col-lg-4
        h6.card-title {{ $t('applications') }}
        .table-responsive(v-if = 'history.creditApplications && history.creditApplications.length')
          table.table.table-sm
            thead: tr
              th {{ $t('application') }}
              th.money {{ $t('amount') }}
              th {{ $t('date') }}
              th {{ $t('status') }}
            tbody
              tr(v-for='application in history.creditApplications')
                td: router-link(:to = '{ name: "Application", params: { id: application.id } }') {{ application.id }}
                td.money {{ application.creditAmount | money }}
                td {{ $t('common:formatDate', { value: application.createdAt }) }}
                td: .badge(:class = '`badge-${applicationStatusColor(application.statusId)}`') {{ classifierById('creditApplicationStatuses', application.statusId).human }}
        p(v-else) {{ $t('notFound') }}
      .col-lg-4
        h6.card-title {{ $t('contracts') }}
        .table-responsive(v-if = 'history.contracts && history.contracts.length')
          table.table.table-sm
            thead: tr
              th {{ $t('contract') }}
              th {{ $t('product') }}
              th.money {{ $t('amount') }}
              th {{ $t('date') }}
              th {{ $t('status') }}
            tbody
              tr(v-for = 'contract in history.contracts')
                td(v-if = 'contract.contractNumber'): router-link(:to = '{ name: "Deposit", params: { id: contract.id } }') {{ contract.contractNumber }}
                td(v-else-if = 'contract.factoringId'): router-link(:to = '{ name: "FactoringContract", params: { id: contract.factoringId } }') {{ contract.agreementNumber }}
                td(v-else): router-link(:to = '{ name: "Loan", params: { id: contract.loanId } }') {{ contract.agreementNumber }}
                td {{ contractProduct(contract.productId).name }}
                td.money {{ contract.amount || contract.balance | money }}
                td {{ $t('common:formatDate', { value: contract.createdAt || contract.startDate }) }}
                td: .badge(:class = '`badge-${loanStatusColor(contract.status.code)}`') {{ classifierByName(contract.status.classifier, contract.status.code).human }}
        p(v-else) {{ $t('notFound') }}
      .col-lg-4
        v-loading(loader = 'customer:debts:fetch')
          .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg
          customer-history-debts(:debts = 'history.debts')
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CustomerHistoryDebts from '@/views/customers/CustomerHistoryDebts'

export default {
  name: 'customer-history',
  components: { CustomerHistoryDebts },
  props: {
    storeModule: {
      type: String,
      required: true
    },
    historyState: {
      type: String,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapState({
      history (state) {
        return state[this.storeModule][this.historyState]
      }
    }),
    ...mapGetters('classifiers', ['classifierById', 'classifierByName']),
    ...mapGetters('loans', ['loanStatusColor']),
    ...mapGetters('applications', ['applicationStatusColor']),
    ...mapGetters('products', ['productById'])
  },

  async created () {
    this.loadCustomerHistory()
    this.loadCustomerDebts()
  },

  methods: {
    ...mapActions({
      loadCustomerHistory (dispatch, payload) {
        return dispatch(this.storeModule + '/loadCustomerHistory', payload)
      },
      loadCustomerDebts (dispatch, payload) {
        return dispatch(`${this.storeModule}/loadCustomerDebts`, payload)
      }
    }),
    contractProduct (productId) {
      return this.productById(productId)
    }
  }
}
</script>


<i18n>
en:
  title:        "Client history"
  applications: "Applications"
  contracts:    "Contracts"
  contract:     "Contract"
  application:  "Application"
  amount:       "Amount"
  product:      "Product"
  date:         "Date"
  status:       "Status"
  notFound:     "Not found"
et:
  title:        "Client history"
  applications: "Applications"
  contracts:    "Contracts"
  contract:     "Contract"
  debts:        "Debts"
  application:  "Application"
  amount:       "Amount"
  product:      "Product"
  date:         "Date"
  status:       "Status"
  notFound:     "Not found"
ru:
  title:        "Client history"
  applications: "Applications"
  contracts:    "Contracts"
  contract:     "Contract"
  debts:        "Debts"
  application:  "Application"
  amount:       "Amount"
  product:      "Product"
  date:         "Date"
  status:       "Status"
  notFound:     "Not found"
</i18n>
