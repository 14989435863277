<template lang="pug">
.customer-role-list.card
  .card-header
    | {{ $t('title') }}
    a.float-right(href           = '#'
                  @click.prevent = 'newRoleModal = true') {{ $t('newLink') }}
  .card-body
    .text-center(v-if = 'customer.roles.length === 0') {{ $t('notFound') }}
    .table-responsive(v-else)
      table.table.mb-0
        thead: tr
          th {{ $t('role') }}
          th {{ $t('validFrom') }}
          th {{ $t('validTo') }}
          th
        tbody
          customer-role-list-item(v-for = 'role in customer.roles'
                                  :key  = 'role.id'
                                  :role = 'role')

    customer-role-new-modal(v-model = 'newRoleModal')
</template>


<script>
import { mapState } from 'vuex'
import CustomerRoleListItem from './CustomerRoleListItem'
import CustomerRoleNewModal from './CustomerRoleNewModal'

export default {
  name: 'customer-role-list',

  components: {
    CustomerRoleListItem,
    CustomerRoleNewModal
  },

  i18nOptions: {},

  data () {
    return {
      newRoleModal: false
    }
  },

  computed: mapState('customers', ['customer'])
}
</script>


<i18n>
en:
  title:     "Roles"
  newLink:   "+ New role"
  notFound:  "No roles found"
  role:      "Role"
  validFrom: "Valid from"
  validTo:   "Valid until"
et:
  title:     "Roles"
  newLink:   "+ New role"
  notFound:  "No roles found"
  role:      "Role"
  validFrom: "Valid from"
  validTo:   "Valid until"
ru:
  title:     "Roles"
  newLink:   "+ New role"
  notFound:  "No roles found"
  role:      "Role"
  validFrom: "Valid from"
  validTo:   "Valid until"
</i18n>
