<template lang="pug">
fi-modal.customer-prepayment-account-transactions-modal(
  ref        = 'modal'
  v-model    = 'showModal'
  size       = 'lg'
  :title     = '$t("title")'
  :ok-title  = '$t("common:close")'
  ok-variant = 'secondary'
  ok-only
  lazy
  @shown     = 'resetData'
)
  .table-responsive
    table.table
      thead: tr
        th {{ $t('date') }}
        th.money  {{ $t('amount') }}
        th {{ $t('fromAccount') }}
        th {{ $t('toAccount') }}
        th {{ $t('contract') }}
      tbody
        tr(v-if = '$vueLoading.isLoading("account:transactions:fetch")'): td.text-center(colspan = 5): i.fa.fa-spinner.fa-pulse.fa-lg
        tr(v-else-if = 'customerAccountTransactions.length === 0'): td.text-center(colspan = 5) {{ $t('notFound') }}
        template(v-else)
          customer-payment-info-transaction-list-item(
            v-for        = 'transaction in customerAccountTransactions'
            :key         = 'transaction.id'
            :transaction = 'transaction')

  fi-pagination(
    v-if    = 'customerAccountTransactionsPages > 1'
    :pages  = 'customerAccountTransactionsPages'
    v-model = 'pagination.page'
  )
</template>


<script>
import CustomerPaymentInfoTransactionListItem from './CustomerPaymentInfoTransactionListItem'
import FiPagination from '@/components/FiPagination'
import FiModal from '@/components/FiModal'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'customer-prepayment-account-transactions-modal',

  components: {
    FiPagination,
    CustomerPaymentInfoTransactionListItem,
    FiModal
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    accountId: {
      type: [Number, String],
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      pagination: {
        page: 0,
        size: 25
      }
    }
  },

  computed: {
    ...mapState('customers', ['customerAccountTransactions', 'customerAccountTransactionsPages']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },

  watch: {
    'pagination.page' () {
      this.search()
    }
  },

  methods: {
    ...mapActions('customers', ['loadCustomerAccountTransactions']),
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.search()
    },
    search () {
      this.loadCustomerAccountTransactions({ accountId: this.accountId, filters: { ...this.pagination, sort: 'transactionId,desc' } })
    }
  }
}
</script>


<style lang="scss" scoped>
.customer-prepayment-account-transactions-modal table {
  background-color: transparent!important;
}
</style>


<i18n>
en:
  title:       "Transactions"
  date:        "Date"
  amount:      "Amount"
  fromAccount: "From account"
  toAccount:   "To account"
  contract:    "Contract"
  notFound:    "Transactions not found"
et:
  title:       "Transactions"
  date:        "Date"
  amount:      "Amount"
  fromAccount: "From account"
  toAccount:   "To account"
  contract:    "Contract"
  notFound:    "Transactions not found"
ru:
  title:       "Transactions"
  date:        "Date"
  amount:      "Amount"
  fromAccount: "From account"
  toAccount:   "To account"
  contract:    "Contract"
  notFound:    "Transactions not found"
</i18n>
