<template lang="pug">
.customer-roles
  customer-role-list
  customer-relation-list(full)
</template>


<script>
import CustomerRoleList from './CustomerRoleList'
import CustomerRelationList from './CustomerRelationList'

export default {
  name: 'customer-roles',

  components: {
    CustomerRoleList,
    CustomerRelationList
  }
}
</script>
