<template lang = "pug">
fi-modal.customer-role-new-modal(
  ref     = 'modal'
  v-model = 'showModal'
  size    = 'lg'
  :title  = 'title'
  lazy
  @hide   = 'onHide'
  @shown  = 'resetData'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(
      :label     = '$t("role")'
      label-cols = 3
    )
      fi-select(
        v-model  = 'formData.roleTypeId'
        :options = 'optionsFromClassifier("roles")'
        sm
      )
    fi-form-field(
      :label     = '$t("validFrom")'
      label-cols = 3
    )
      fi-datepicker(
        v-model      = 'formData.validFrom'
        :placeholder = '$t("datePlaceholder")'
        sm
      )
    fi-form-field(
      :label     = '$t("validTo")'
      label-cols = 3
    )
      fi-datepicker(
        v-model      = 'formData.validTo'
        :placeholder = '$t("datePlaceholder")'
        sm
      )
  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'saving'
      @click.prevent = 'onSubmit'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
</template>


<script>
import FiDatepicker from '@/components/FiDatepicker'
import FiFormField from '@/components/FiFormField'
import FiModal from '@/components/FiModal'
import FiSelect from '@/components/FiSelect'
import pick from 'lodash/pick'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'customer-role-new-modal',

  components: {
    FiFormField,
    FiSelect,
    FiDatepicker,
    FiModal
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    role: {
      type: Object,
      default: null
    }
  },

  i18nOptions: {},

  data () {
    return {
      formData: {
        roleTypeId: '',
        validFrom: null,
        validTo: null
      }
    }
  },

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    saving () {
      if (this.editing) {
        return this.$vueLoading.isLoading(`customer:role:${this.role.id}:save`)
      } else {
        return this.$vueLoading.isLoading('customer:role:save')
      }
    },
    editing () {
      return !!this.role
    },
    title () {
      if (this.editing) {
        return this.$t('editRole')
      } else {
        return this.$t('newRole')
      }
    }
  },

  methods: {
    ...mapActions('customers', ['createCustomerRole', 'updateCustomerRole']),
    resetData () {
      if (this.editing) {
        this.formData = pick(this.role, Object.keys(this.formData))
      } else {
        Object.assign(this.$data, this.$options.data())
      }
    },
    onHide (e) {
      if (this.saving) e.preventDefault()
    },
    async onSubmit () {
      if (this.editing) {
        await this.updateCustomerRole({ id: this.role.id, roleData: this.formData })
      } else {
        await this.createCustomerRole({ roleData: this.formData })
      }
      this.$refs.modal.hide()
    }
  }
}
</script>


<i18n>
en:
  newRole:         "New role"
  editRole:        "Edit role"
  role:            "Role"
  validFrom:       "Valid from"
  validTo:         "Valid until"
  datePlaceholder: "Select date"
et:
  newRole:         "New role"
  editRole:        "Edit role"
  role:            "Role"
  validFrom:       "Valid from"
  validTo:         "Valid until"
  datePlaceholder: "Select date"
ru:
  newRole:         "New role"
  editRole:        "Edit role"
  role:            "Role"
  validFrom:       "Valid from"
  validTo:         "Valid until"
  datePlaceholder: "Select date"
</i18n>
