<template lang="pug">
.customer-prepayment-account-list.card.animated.fadeIn
  .card-header {{ $t('title') }}
    .fa-pull-right
      a(href         = '#'
        v-fi-confirm = '{ ok: newAccount, message: $t("addAccountConfirm") }') {{ $t('addAccount') }}
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = '$vueLoading.isLoading("customer:account:save")')
  .card-body
    fi-table(
      :items = 'accounts'
      :fields = 'fields'
      :empty-label = '$t("notFound")'
      loader = 'customer:prepaymentAccounts:fetch'
    )
      template(#default = '{ items }')
        customer-prepayment-account-list-item(
          v-for         = 'account in items'
          :key          = 'account.id'
          :account      = 'account'
          :class        = '{ "table-active": value === account.referenceNumber }'
          @click.native = 'selectAccount(account.referenceNumber)'
        )
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import CustomerPrepaymentAccountListItem from './CustomerPrepaymentAccountListItem'
import FiTable from '@/components/FiTable'

export default {
  name: 'customer-prepayment-account-list',

  components: { FiTable, CustomerPrepaymentAccountListItem },

  props: {
    value: {
      type: String,
      default: ''
    },
    accounts: {
      type: Array,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['classifierByName']),
    fields () {
      return [
        { label: this.$t('iban') },
        { label: this.$t('reference') },
        { label: this.$t('balance'), class: 'money' },
        { label: this.$t('booked'), class: 'money' },
        {}, {}, {}
      ]
    }
  },

  methods: {
    ...mapActions('customers', ['createCustomerAccount']),
    async newAccount ({ done }) {
      await this.createCustomerAccount({ accountData: { accountTypeId: this.classifierByName('accountTypes', 'PREPAYMENT')?.id } })
      done()
    },
    selectAccount (accountId) {
      this.$emit('input', accountId)
    }
  }
}
</script>


<i18n>
en:
  title:             "Prepayment accounts"
  addAccount:        "+ Add new account"
  addAccountConfirm: "Are you sure you want to add account?"
  iban:              "IBAN"
  reference:         "Reference"
  booked:            "Booked amount"
  balance:           "Balance"
  notFound:          "Prepayment accounts not found"
et:
  title:             "Prepayment accounts"
  addAccount:        "+ Add new account"
  addAccountConfirm: "Are you sure you want to add account?"
  iban:              "IBAN"
  reference:         "Reference"
  booked:            "Booked amount"
  balance:           "Balance"
  notFound:          "Prepayment accounts not found"
ru:
  title:             "Prepayment accounts"
  addAccount:        "+ Add new account"
  addAccountConfirm: "Are you sure you want to add account?"
  iban:              "IBAN"
  reference:         "Reference"
  booked:            "Booked amount"
  balance:           "Balance"
  notFound:          "Prepayment accounts not found"
</i18n>
