<template lang = 'pug'>
tr.customer-related-loans-list-item
  td: router-link(:to = '{ name: "Loan", params: { id: loan.loanId } }') {{ loan.agreementNumber }}
  td {{ classifierByName(loan.relatedCustomerType.classifier, loan.relatedCustomerType.id).human }}
  td(v-if = 'full'): router-link(:to = '{ name: "Product", params: {id: loan.productId, type: productGroup } }') {{ product.name }}
  td.money {{ loan.guaranteedAmount | money }}
  template(v-if = 'full')
    td {{ $t('common:formatDate', { value: loan.validFrom }) }}
    td {{ $t('common:formatDate', { value: loan.validUntil }) }}
  td(v-else): .badge(:class = '`badge-${loanStatusColor(status.name)}`') {{ status.human }}

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'customer-related-loans-list-item',

  props: {
    loan: {
      type: Object,
      required: true
    },
    full: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierByName', 'classifierById']),
    ...mapGetters('products', ['productById']),
    ...mapGetters('loans', ['loanStatusColor']),
    status () {
      return this.classifierById(this.loan?.loanStatus?.classifier, this.loan?.loanStatus?.id)
    },
    product () {
      return this.productById(this.loan.productId)
    },
    productGroup () {
      const { group = '' } = this.product
      if (isNaN(group)) {
        return group.toLowerCase()
      } else {
        return this.classifierById('productGroups', this.product.group).human
      }
    }
  }
}
</script>

<style scoped>

</style>
