<template lang="pug">
tr.application-related-persons-list-item
  td {{ paidTaxes.year }}
  td {{ paidTaxes.quarter }}
  td {{ paidTaxes.turnover }}
  td {{ paidTaxes.paidTaxes }}
  td {{ paidTaxes.employeeTaxes }}
  td {{ paidTaxes.employees }}
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'application-related-persons-list-item',


  props: {
    paidTaxes: {
      type: Object,
      default: () => []
    },
    product: {
      type: Object,
      default: () => {}
    },
    application: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    showEditRelatedPerson: false
  }),

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'classifierByName']),
    loader () {
      return this.$vueLoading.isLoading(`factoring:application:relatedPerson:${this.paidTaxes.customerId}:save`)
    },
    deleteLoader () {
      return this.$vueLoading.isLoading(`factoring:application:relatedPerson:${this.paidTaxes.customerId}:delete`)
    }
  }
}
</script>
