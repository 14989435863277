import { render, staticRenderFns } from "./CustomerGeneral.vue?vue&type=template&id=c74d383c&scoped=true&lang=pug&"
import script from "./CustomerGeneral.vue?vue&type=script&lang=js&"
export * from "./CustomerGeneral.vue?vue&type=script&lang=js&"
import style0 from "./CustomerGeneral.vue?vue&type=style&index=0&id=c74d383c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c74d383c",
  null
  
)

export default component.exports