<template lang="pug">
tr.customer-payment-info-transaction-list-item
  td {{ $t('common:formatDate', { value: transaction.createdAt }) }}
  td.money(:class = 'amountClass') {{ transaction.amount | money }}
  td {{ classifierByName('accountTypes', transaction.accountEntryFrom).human }}
  td {{ classifierByName('accountTypes', transaction.accountEntryTo).human }}
  td: router-link(
    v-if = 'contractLink'
    :to  = 'contractLink'
  ) {{ transaction.contractNumber }}
</template>


<script>
import { mapGetters } from 'vuex'

const TransactableTypeRoute = {
  DEPOSIT: 'Deposit',
  FACTORING: 'FactoringContract',
  LOANS: 'Loan',
  OVERDRAFT: 'Loan',
  LEASING: 'Loan',
  CUSTOMER_SURPLUS: 'Loan'
}

export default {
  name: 'customer-payment-info-transaction-list-item',

  props: {
    transaction: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierByName']),
    amountClass () {
      return this.transaction.transactionType === 'DEBIT' ? 'text-success' : 'text-danger'
    },
    contractLink () {
      const { contractId, transactableType } = this.transaction
      if (!TransactableTypeRoute[transactableType]) return

      return {
        name: TransactableTypeRoute[transactableType],
        params: { id: contractId?.toString() }
      }
    }
  }
}
</script>
