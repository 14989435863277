<template lang="pug">
tr.customer-factoring-list-item
  td: router-link(:to = '{ name: "FactoringContract", params: { id: factoring.factoringId.toString() }}') {{ factoring.agreementNumber }}
  td {{ factoring.creditIssuerName }}
  td {{ product.name }}
  td.money(v-if = 'full') {{ factoring.contractLimit | money }}
  td.money {{ factoring.balance | money }}
  td.money {{ factoring.debts | money }}
  td {{ $t('common:formatDate', { value: factoring.periodStart }) }}
  td {{ $t('common:formatDate', { value: factoring.periodEnd }) }}
  td
    router-link(:to = '{ name: "CustomerPaymentInfo", params: { id: factoring.customerId } }') {{ factoring.referenceNumber }}
  td.money {{ factoring.nextPaymentAmount | money }}
  td {{ $t('common:formatDate', { value: factoring.nextPaymentDate }) }}
  td {{ $t('common:formatDate', { value: factoring.updatedAt }) }}
  td: .badge(:class = '`badge-${loanStatusColor(status.id)}`') {{ status.human }}
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'customer-factoring-list-item',

  props: {
    factoring: {
      type: Object,
      required: true
    },
    full: {
      type: Boolean
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierByName']),
    ...mapGetters('loans', ['loanStatusColor']),
    ...mapGetters('products', ['productById']),
    product () {
      return this.productById(this.factoring.productId)
    },
    status () {
      return this.classifierByName(this.factoring.status.classifier, this.factoring.status.code) ?? {}
    }
  }
}
</script>
