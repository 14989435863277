<template lang='pug'>
.scoring-request-list-table
  fi-table(
    :fields      = 'fields'
    :items       = 'items'
    loader       = 'scoring:requests:fetch'
    :empty-label = '$t("notFound")'
    responsive
  )
    template(#default = '{ items }')
      scoring-request-list-item(
        v-for    = 'request in items'
        :key     = 'request.id'
        :request = 'request'
      )
  fi-pagination(
    v-if    = 'pages > 1'
    :pages  = 'pages'
    v-model = 'page'
  )
</template>


<script>
import FiPagination from '@/components/FiPagination'
import FiTable from '@/components/FiTable'
import ScoringRequestListItem from '@/views/scoring/ScoringRequestListItem'

export default {
  name: 'scoring-request-list-table',

  components: { FiPagination, ScoringRequestListItem, FiTable },

  props: {
    value: {
      type: [Number, String],
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    loader: {
      type: String,
      required: true
    },
    pages: {
      type: Number,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    fields () {
      return [
        {
          label: 'ID'
        },
        {
          label: this.$t('scoringModel')
        },
        {
          label: this.$t('idCode')
        },
        {
          label: this.$t('name')
        },
        {
          label: this.$t('date')
        },
        {
          label: this.$t('result')
        },
        {}
      ]
    },
    page: {
      get () {
        return this.value
      },
      set (page) {
        this.$emit('input', page)
      }
    }
  }
}
</script>


<i18n>
en:
  scoringModel: "Scoring model"
  idCode:       "ID code"
  name:         "Name"
  date:         "Date"
  result:       "Result"
  notFound:     "Not found"
et:
  scoringModel: "Scoring model"
  idCode:       "ID code"
  name:         "Name"
  date:         "Date"
  result:       "Result"
  notFound:     "Not found"
ru:
  scoringModel: "Scoring model"
  idCode:       "ID code"
  name:         "Name"
  date:         "Date"
  result:       "Result"
  notFound:     "Not found"
</i18n>
