  <template lang = "pug">
.customer-new.animated.fadeIn
  form.form-horizontal(@submit.prevent = 'onSubmit' novalidate)
    .card.mb-0.border-bottom-0
      .card-header {{ $t('title') }}
      .card-body
        .row
          .col-lg-6
            fi-form-field(:label = '$t("sector")')
              fi-select(
                v-model.number = 'customerData.sectorId'
                :options       = 'optionsFromClassifier("sectors")'
                sm
              )
            fi-form-field(:label = '$t("segment")')
              fi-select(
                v-model.number = 'customerData.segmentId'
                :options       = 'optionsFromClassifier("segments")'
                sm
              )
    .card.mb-0.border-bottom-0
      .card-header {{ $t('customerData') }}
      .card-body
        .row
          .col-lg-6
            template(v-if = 'isCorporateCustomer(customerData)')
              fi-form-field(
                :label = '$t("name")'
                :state = '!$v.customerData.customerName.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.customerData.customerName.required') {{ $t('common:required') }}
                input.form-control.form-control-sm(
                  v-model.trim = 'customerData.customerName'
                  type         = 'text'
                  :class       = '{ "is-invalid": $v.customerData.customerName.$error }'
                )

              fi-form-field(
                :label = '$t("regCode")'
                :state = '!$v.customerData.idCode.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.customerData.idCode.required') {{ $t('common:required') }}
                input.form-control.form-control-sm(
                  v-model.trim = 'customerData.idCode'
                  type         = 'text'
                  :class       = '{ "is-invalid": $v.customerData.idCode.$error }'
                )

            template(v-if = '!isCorporateCustomer(customerData)')
              fi-form-field(
                :label = '$t("firstName")'
                :state = '!$v.customerData.firstName.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.customerData.firstName.required') {{ $t('common:required') }}
                input.form-control.form-control-sm(
                  v-model.trim = '$v.customerData.$model.firstName'
                  type         = 'text'
                  :class       = '{ "is-invalid": $v.customerData.firstName.$error }'
                )

              fi-form-field(
                :label = '$t("lastName")'
                :state = '!$v.customerData.lastName.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.customerData.lastName.required') {{ $t('common:required') }}
                input.form-control.form-control-sm(
                  v-model.trim = 'customerData.lastName'
                  type         = 'text'
                  :class       = '{ "is-invalid": $v.customerData.lastName.$error }'
                )


              fi-form-field(
                :label = '$t("idCode")'
                :state = '!$v.customerData.idCode.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.customerData.idCode.required') {{ $t('common:required') }}
                input.form-control.form-control-sm(
                  v-model.trim = 'customerData.idCode'
                  type         = 'text'
                  :class       = '{ "is-invalid": $v.customerData.idCode.$error }'
                )

            fi-form-field(
              :label = '$t("phone")'
              :state = '!$v.customerData.phoneNumbers.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.customerData.phoneNumbers.required') {{ $t('common:required') }}
              customer-contact-list(
                v-model    = 'customerData.phoneNumbers'
                :add-label = '$t("addPhone")'
                name       = 'phoneContact'
                allow-main
              )
                template(#input="{ contact, onInput }")
                  fi-phone-input.form-control-sm.form-control.animated.fadeIn(
                    :value     = 'contact'
                    :class     = '{ "is-invalid": $v.customerData.phoneNumbers.$error }'
                    :countries = 'countries'
                    @input     = 'onInput'
                  )

            fi-form-field(
              :label = '$t("deskPhone")'
              :state = '!$v.customerData.deskPhoneNumbers.$error'
            )
              customer-contact-list(
                v-model    = 'customerData.deskPhoneNumbers'
                :add-label = '$t("addPhone")'
                name       = 'phoneContact'
              )
                template(#input="{ contact, onInput }")
                  fi-phone-input.form-control-sm.form-control.animated.fadeIn(
                    :value     = 'contact'
                    :class     = '{ "is-invalid": $v.customerData.deskPhoneNumbers.$error }'
                    :countries = 'countries'
                    @input     = 'onInput'
                  )

            fi-form-field(
              :label = '$t("email")'
              :state = '!$v.customerData.emails.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.customerData.emails.required') {{ $t('common:required') }}
              customer-contact-list(
                v-model      = 'customerData.emails'
                :add-label   = '$t("addEmail")'
                :input-class = '{ "is-invalid": $v.customerData.emails.$error }'
                name         = 'emailContact'
                allow-main
              )

            .animated.fadeIn(v-if = '!isCorporateCustomer(customerData)')
              fi-form-field(
                :label = '$t("documentType")'
                :state = '!$v.privateCustomerData.idDocId.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.privateCustomerData.idDocId.required') {{ $t('common:required') }}
                fi-select(
                  v-model.number = 'privateCustomerData.idDocId'
                  :options       = 'optionsFromClassifier("idDocs")'
                  :class         = '{ "is-invalid": $v.privateCustomerData.idDocId.$error }'
                  required
                  sm
                )

              fi-form-field(
                :label = '$t("documentNumber")'
                :state = '!$v.privateCustomerData.idDocNumber.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.privateCustomerData.idDocNumber.required') {{ $t('common:required') }}
                input.form-control.form-control-sm(
                  v-model.trim = 'privateCustomerData.idDocNumber'
                  type         = 'text'
                  :class       = '{ "is-invalid": $v.privateCustomerData.idDocNumber.$error }'
                )
              fi-form-field(:label = '$t("aml")')
                fi-select.animated.fadeIn(
                  v-model.number = 'customerData.antiMoneyLaunderingCategoryId'
                  :options       = 'optionsFromClassifier("antiMoneyLaunderingCategory")'
                  required
                  sm
                )
              fi-form-field(:label = '$t("riskCategory")')
                fi-select.animated.fadeIn(
                  v-model.number = 'customerData.riskCategoryId'
                  :options       = 'optionsFromClassifier("riskCategory")'
                  required
                  sm
                )
          .col-lg-6
            fi-form-field(:label = '$t("country")')
              fi-select(
                v-model.number = 'customerData.countryId'
                :options       = 'optionsFromClassifier("countries")'
                sm
              )

            fi-form-field(
              :label = '$t("county")'
              :state = '!$v.customerData.countyId.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.customerData.countyId.required') {{ $t('common:required') }}
              fi-select(
                v-model.number = 'customerData.countyId'
                :options       = 'counties'
                :class         = '{ "is-invalid": $v.customerData.countyId.$error }'
                required
                sm
              )

            fi-form-field(
              :label = '$t("city")'
              :state = '!$v.addressData.city.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.addressData.city.required') {{ $t('common:required') }}
              input.form-control.form-control-sm(
                v-model.trim = 'addressData.city'
                type         = 'text'
                :class       = '{ "is-invalid": $v.addressData.city.$error }'
              )

            fi-form-field(
              :label = '$t("address")'
              :state = '!$v.addressData.fullAddress.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.addressData.fullAddress.required') {{ $t('common:required') }}
              input.form-control.form-control-sm(
                v-model.trim = 'addressData.fullAddress'
                type         = 'text'
                :class       = '{ "is-invalid": $v.addressData.fullAddress.$error }'
              )

            fi-form-field(
              :label = '$t("postalCode")'
              :state = '!$v.addressData.postalCode.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.addressData.postalCode.required') {{ $t('common:required') }}
              input.form-control.form-control-sm(
                v-model.trim = 'addressData.postalCode'
                type         = 'text'
                :class       = '{ "is-invalid": $v.addressData.postalCode.$error }'
              )

            fi-form-field(:label = '$t("accountExternal")')
              .input-group.input-group-sm
                input.form-control.form-control-sm(v-model = 'accountExternalId')
                .input-group-append
                  button.btn.btn-sm.btn-primary(@click.prevent = 'addAccount') {{ $t('addAccount') }}
              c-list-group
                c-list-group-item(
                  v-for = '(account, index) in $v.customerData.externalBankAccounts.$each.$iter'
                  :key  = 'index'
                )
                  .col
                    b-radio.fa-pull-left(
                      v-model = 'account.generalAccount.$model'
                      @change = 'setDefaultAccount(index)'
                      name    = 'defaultAccount'
                      :value  = 'true'
                    ) {{ account.accountExternalNumber.$model }}
                    c-button.fa-pull-right(
                      size = 'sm'
                      color = 'danger'
                      @click.prevent = 'removeAccount(index)'
                    )
                      i.cil-trash.fa-lg
            .animated.fadeIn(v-if = '!isCorporateCustomer(customerData)')
              fi-form-field(
                :label = '$t("maritalStatus")'
                :state = '!$v.privateCustomerData.maritalStatusId.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.privateCustomerData.maritalStatusId.required') {{ $t('common:required') }}
                fi-select(
                  v-model.number = 'privateCustomerData.maritalStatusId'
                  :options       = 'optionsFromClassifier("maritalStatus")'
                  :class         = '{ "is-invalid": $v.privateCustomerData.maritalStatusId.$error }'
                  required
                  sm
                )
              fi-form-field(:label = '$t("dependantNumber")')
                input.form-control.form-control-sm(
                  v-model.number = 'privateCustomerData.dependantNumber'
                  type         = 'text'
                )
            fi-form-field(:label = '$t("residence")')
              .form-row
                .col
                  fi-select.animated.fadeIn(
                    v-model.number = 'customerData.residenceCountryId'
                    :options       = 'optionsFromClassifier("countries")'
                    required
                    sm
                  )
                .col
                  fi-select.animated.fadeIn(
                    v-model.number = 'customerData.residenceTypeId'
                    :options       = 'optionsFromClassifier("residenceType")'
                    sm
                  )
    .card
      .card-body
        button.btn.btn-primary(
          type      = 'submit'
          :disabled = 'saving'
        ) {{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'onCancel'
        ) {{ $t("common:cancel") }}
  fi-confirm-modal(
    v-model      = 'showConfirmModal'
    :ok-callback = '() => onSubmit({ confirm: true })'
    :message     = '$t("confirmMessage")'
    loader       = 'customer:save'
  )
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiPhoneInput from '@/components/FiPhoneInput'
import FiSelect from '@/components/FiSelect'
import { validation, validators } from '@/validation'
import customerRules from '@/validation/customerRules'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import { mapActions, mapGetters, mapState } from 'vuex'
import { CButton, CListGroup, CListGroupItem } from '@coreui/vue'
import CustomerContactList from '@/views/customers/CustomerContactList'
import FiConfirmModal from '@/components/FiConfirmModal'

export default {
  name: 'customer-new',

  components: {
    FiConfirmModal,
    CustomerContactList,
    FiPhoneInput,
    FiSelect,
    FiFormField,
    CListGroup,
    CListGroupItem,
    CButton
  },

  mixins: [validation],

  i18nOptions: {},

  data () {
    return {
      accountExternalId: null,
      showConfirmModal: false,
      customerData: {
        antiMoneyLaunderingCategoryId: '',
        riskCategoryId: '',
        externalBankAccounts: [],
        sectorId: '',
        segmentId: '',
        idCode: null,
        firstName: null,
        lastName: null,
        customerName: null,
        phoneNumbers: [],
        deskPhoneNumbers: [],
        emails: [],
        countryId: '',
        residenceCountryId: '',
        residenceTypeId: '',
        countyId: ''
      },
      privateCustomerData: {
        idDocId: '',
        idDocNumber: null,
        maritalStatusId: '',
        dependantNumber: null
      },
      addressData: {
        countryId: '',
        countyId: '',
        city: null,
        fullAddress: null,
        postalCode: null,
        isDefault: true
      }
    }
  },

  computed: {
    ...mapState('customers', ['customer']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierByName', 'optionsFromCountiesByCountry', 'classifierList']),
    ...mapGetters('customers', ['isCorporateCustomer']),
    countries () {
      return Object.values(this.classifierList('countries')).map(({ name }) => name)
    },
    saving () {
      return this.$vueLoading.isLoading('customer:save')
    },
    counties () {
      return this.optionsFromCountiesByCountry(this.customerData.countryId)
    }
  },

  watch: {
    'customerData.countryId' (countryId) {
      this.addressData.countryId = countryId
    },
    'customerData.countyId' (countyId) {
      this.addressData.countyId = countyId
    },
    'customerData.sectorId' () {
      this.customerData.lastName = ''
      this.customerData.firstName = ''
      this.customerData.customerName = ''
      this.$v.$reset()
    }
  },

  validations () {
    return {
      customerData: {
        ...pick(customerRules, Object.keys(this.customerData)),
        externalBankAccounts: {
          length: validators.minLength(1),
          $each: {
            accountExternalNumber: {},
            generalAccount: {}
          }
        },
        customerName: {
          required: validators.requiredIf(function () {
            return this.isCorporateCustomer(this.customerData)
          })
        },
        firstName: {
          required: validators.requiredUnless(function () {
            return this.isCorporateCustomer(this.customerData)
          })
        },
        lastName: {
          required: validators.requiredUnless(function () {
            return this.isCorporateCustomer(this.customerData)
          })
        }
      },
      addressData: pick(customerRules, Object.keys(this.addressData)),
      privateCustomerData: {
        idDocId: {},
        idDocNumber: {},
        maritalStatusId: {}
      }
    }
  },

  mounted () {
    this.customerData.sectorId = this.classifierByName('sectors', 'PRIVATE_CUSTOMER')?.id
    this.customerData.segmentId = this.classifierByName('segments', 'regular')?.id
    this.customerData.countryId = this.classifierByName('countries', 'ee')?.id
    this.customerData.residenceCountryId = this.classifierByName('countries', 'ee')?.id
  },

  methods: {
    ...mapActions('customers', ['createCustomer']),
    onCancel () {
      this.$router.push({ name: 'Customers' })
    },
    async onSubmit ({ confirm }) {
      if (this.validate()) {
        if (
          !confirm &&
          Object.values(this.privateCustomerData).some(value => !value) &&
          !this.isCorporateCustomer(this.customerData)
        ) {
          this.showConfirmModal = true
          return
        }
        await this.createCustomer({
          customerData: pickBy(this.customerData),
          privateCustomerData: pickBy(this.privateCustomerData),
          addressData: pickBy(this.addressData)
        })
        this.$router.push({ name: 'Customer', params: { id: this.customer.id } })
      }
    },
    addAccount () {
      if (!this.accountExternalId) {
        return
      }
      this.$v.customerData.externalBankAccounts.$model.push({
        accountExternalNumber: this.accountExternalId,
        generalAccount: !this.$v.customerData.externalBankAccounts.$model.length
      })
      this.accountExternalId = null
    },
    removeAccount (index) {
      this.$v.customerData.externalBankAccounts.$model.splice(index, 1)
      if (this.$v.customerData.externalBankAccounts.$model.length) {
        this.$v.customerData.externalBankAccounts.$model[0].generalAccount = true
      }
    },
    setDefaultAccount (index) {
      this.$v.customerData.externalBankAccounts.$model = this.$v.customerData.externalBankAccounts.$model.map((account, idx) => ({
        ...account,
        generalAccount: idx === index
      }))
    }
  }
}
</script>


<i18n>
en:
  title:           "New customer"
  customerData:    "Customer data"
  name:            "Name"
  firstName:       "First name"
  lastName:        "Last name"
  idCode:          "ID code"
  regCode:         "Reg Code"
  sector:          "Sector"
  segment:         "Segment"
  phone:           "Mobile phone"
  deskPhone:       "Desk phone"
  email:           "E-mail"
  country:         "Country"
  county:          "County"
  city:            "City"
  address:         "Address"
  postalCode:      "Postal code"
  documentType:    "Document type"
  documentNumber:  "Document number"
  aml:             "AML category"
  riskCategory:    "Risk category"
  emailError:      "Value must be correct email address"
  accountExternal: "Payout bank account"
  addAccount:      "Add"
  addPhone:        "Add phone"
  addEmail:        "Add email"
  confirmMessage:   "Document information not added. Do you want to proceed?"
  maritalStatus:   "Marital status"
  dependantNumber: "Number of dependants"
  residence:       "Residence"
et:
  title:           "New customer"
  customerData:    "Customer data"
  name:            "Name"
  firstName:       "First name"
  lastName:        "Last name"
  idCode:          "ID code"
  regCode:         "Reg Code"
  sector:          "Sector"
  segment:         "Segment"
  phone:           "Mobile phone"
  deskPhone:       "Desk phone"
  email:           "E-mail"
  country:         "Country"
  county:          "County"
  city:            "City"
  address:         "Address"
  postalCode:      "Postal code"
  documentType:    "Document type"
  documentNumber:  "Document number"
  aml:             "AML category"
  riskCategory:    "Risk category"
  emailError:      "Value must be correct email address"
  accountExternal: "Payout bank account"
  addAccount:      "Add"
  addPhone:        "Add phone"
  addEmail:        "Add email"
  confirmMessage:   "Document information not added. Do you want to proceed?"
  maritalStatus:   "Marital status"
  dependantNumber: "Number of dependants"
  residence:       "Residence"
ru:
  title:           "New customer"
  customerData:    "Customer data"
  name:            "Name"
  firstName:       "First name"
  lastName:        "Last name"
  idCode:          "ID code"
  regCode:         "Reg Code"
  sector:          "Sector"
  segment:         "Segment"
  phone:           "Mobile phone"
  deskPhone:       "Desk phone"
  email:           "E-mail"
  country:         "Country"
  county:          "County"
  city:            "City"
  address:         "Address"
  postalCode:      "Postal code"
  documentType:    "Document type"
  documentNumber:  "Document number"
  aml:             "AML category"
  riskCategory:    "Risk category"
  emailError:      "Value must be correct email address"
  accountExternal: "Payout bank account"
  addAccount:      "Add"
  addPhone:        "Add phone"
  addEmail:        "Add email"
  confirmMessage:   "Document information not added. Do you want to proceed?"
  maritalStatus:   "Marital status"
  dependantNumber: "Number of dependants"
  residence:       "Residence"
</i18n>
