<template lang="pug">
.customer-relation-list.card
  v-loading(loader='customers:relations:fetch')
    template(#spinner)
      i.fa.fa-spinner.fa-pulse.ml-1
    .card-header
      | {{ $t('represents') }}
      a.float-right(
        v-if           = 'full'
        href           = '#'
        @click.prevent = 'newRelationModal = true'
      ) {{ $t('newLink') }}
    .card-body
      .text-center(v-if = 'customerRelations.relations.length === 0') {{ $t('notFound') }}
      .table-responsive(v-else)
        table.table.mb-0
          thead(v-if = 'full'): tr
            th(width = '20%') {{ $t('relatedCustomer') }}
            th(width = '20%') {{ $t('relationType') }}
            th(width = '15%') {{ $t('validFrom') }}
            th(width = '15%') {{ $t('validTo') }}
            th(width = '20%') {{ $t('comment') }}
            th.text-nowrap {{ $t('mainContact') }}
            th
          tbody
            customer-relation-list-item(
              v-for     = 'relation in customerRelations.relations'
              :key      = 'relation.id'
              :full     = 'full'
              :relation = 'relation'
              :customer = 'customer'
              field     = 'referredCustomer'
              removable
              editable
              @submit = 'updateRelation'
            )
      customer-relation-new-modal(
        v-model   = 'newRelationModal'
        loader    = 'customer:relation:save'
        :customer = 'customer'
        @submit   = 'saveRelation'
      )
    .card-header.border-top {{ $t('representedBy') }}
    .card-body
      .text-center(v-if = 'referredRelations.length === 0') {{ $t('notFound') }}
      .table-responsive(v-else)
        table.table.mb-0
          thead(v-if = 'full'): tr
            th(width = '20%') {{ $t('referer') }}
            th(width = '20%') {{ $t('relationType') }}
            th(width = '15%') {{ $t('validFrom') }}
            th(width = '15%') {{ $t('validTo') }}
            th(width = '20%') {{ $t('comment') }}
            th(width = '20%') {{ $t('phone') }}
            th(width = '20%') {{ $t('email') }}
            th.text-nowrap(width = '20%') {{ $t('mainContact') }}
            th
          tbody
            customer-relation-list-item(
              v-for     = 'relation in referredRelations'
              :key      = 'relation.id'
              :full     = 'full'
              :relation = 'relation'
              :customer = 'customer'
              field     = 'customer'
              edit-main
              @set-main = 'setMainContact'
            )
    customer-related-loans-list(
      :loans = 'relatedLoans'
      :full  = 'full'
    )
</template>


<script>
import CustomerRelatedLoansList from '@/views/customers/CustomerRelatedLoansList'
import { mapActions, mapState } from 'vuex'
import CustomerRelationListItem from './CustomerRelationListItem'
import CustomerRelationNewModal from './CustomerRelationNewModal'
import { loanStatuses } from '@/const'

export default {
  name: 'customer-relation-list',

  components: {
    CustomerRelatedLoansList,
    CustomerRelationListItem,
    CustomerRelationNewModal
  },

  props: {
    full: {
      type: Boolean,
      default: false
    }
  },

  i18nOptions: {},

  data () {
    return {
      newRelationModal: false
    }
  },

  computed: {
    ...mapState('customers', ['customer', 'customerRelations', 'customerRelatedLoans']),
    relatedLoans () {
      const { active, delayed, bailiff, collector, terminated } = loanStatuses
      const allowedStatuses = [active, delayed, bailiff, collector, terminated]

      if (this.full) {
        return this.customerRelatedLoans
      }

      return this.customerRelatedLoans.filter(({ loanStatus }) => allowedStatuses.includes(loanStatus.code))
    },
    referredRelations () {
      return this.customerRelations.referredRelations.filter(({ mainContact }) => this.full || mainContact)
    }
  },

  methods: {
    ...mapActions('customers', ['updateCustomerRelation', 'createCustomerRelation']),
    async setMainContact ({ referredCustomerId, id }) {
      await this.updateCustomerRelation({ id, relationData: { referredCustomerId, mainContact: true }, type: 'referred' })
      this.$toasted.success(this.$t('updateSuccess'))
    },
    async saveRelation ({ data, done }) {
      await this.createCustomerRelation(data)
      // eslint-disable-next-line no-unused-expressions
      done?.()
    },
    async updateRelation ({ data: { id, relationData, referredCustomerId }, done }) {
      await this.updateCustomerRelation({ id, relationData: { referredCustomerId, ...relationData }, type: 'referred' })
      // eslint-disable-next-line no-unused-expressions
      done?.()
    }
  }
}
</script>


<i18n>
en:
  represents:      "Represents"
  newLink:         "+ New relation"
  representedBy:   "Represented by"
  notFound:        "Represents not found"
  relatedCustomer: "Related customer"
  referer:         "Referer"
  relationType:    "Relation type"
  validFrom:       "Valid from"
  validTo:         "Valid until"
  comment:         "Comment"
  phone:           "Phone"
  email:           "Email"
  mainContact:     "Main contact"
  updateSuccess:   "Main contact successfully updated"
et:
  represents:      "Represents"
  newLink:         "+ New relation"
  representedBy:   "Represented by"
  notFound:        "Represents not found"
  relatedCustomer: "Related customer"
  referer:         "Referer"
  relationType:    "Relation type"
  validFrom:       "Valid from"
  validTo:         "Valid until"
  comment:         "Comment"
  phone:           "Phone"
  email:           "Email"
  mainContact:     "Main contact"
  updateSuccess:   "Main contact successfully updated"
ru:
  represents:      "Represents"
  newLink:         "+ New relation"
  representedBy:   "Represented by"
  notFound:        "Represents not found"
  relatedCustomer: "Related customer"
  referer:         "Referer"
  relationType:    "Relation type"
  validFrom:       "Valid from"
  validTo:         "Valid until"
  comment:         "Comment"
  phone:           "Phone"
  email:           "Email"
  mainContact:     "Main contact"
  updateSuccess:   "Main contact successfully updated"
</i18n>
