<template lang="pug">
.card.customer-factoring-list.animated.fadeIn
  .card-header {{ $t('title') }}
    router-link.float-right(:to  = '{ name: "FactoringNew", params: { customer }}') {{ $t('newLink') }}
  .card-body
    fi-table(
      :fields = 'fields'
      :items  = 'contracts'
      loader  = 'customer:factoring:contracts:fetch'
      :empty-label = "$t('notFound')"
      responsive
      fixed
    )
      template(#default = '{ items }')
        customer-factoring-list-item(
          v-for      = 'contract in items'
          :key       = 'contract.factoringId'
          :factoring = 'contract'
          full
        )
</template>


<script>
import CustomerFactoringListItem from '@/views/customers/CustomerFactoringListItem'
import FiTable from '@/components/FiTable'

export default {
  name: 'customer-factoring-list',

  components: {
    FiTable,
    CustomerFactoringListItem
  },

  props: {
    contracts: {
      type: Array,
      required: true
    },
    customer: {
      type: Object,
      required: true
    }
  },

  computed: {
    fields () {
      return [
        { label: this.$t('contract') },
        { label: this.$t('creditIssuer') },
        { label: this.$t('product') },
        {
          label: this.$t('limit'),
          class: 'money'
        },
        {
          label: this.$t('balance'),
          class: 'money'
        },
        {
          label: this.$t('debt'),
          class: 'money'
        },
        {
          label: this.$t('startDate')
        },
        {
          label: this.$t('endDate')
        },
        {
          key: 'reference',
          label: this.$t('reference')
        },
        {
          label: this.$t('nextPayment'),
          class: 'money'
        },
        {
          label: this.$t('nextPaymentDate')
        },
        {
          label: this.$t('updatedAt')
        },
        {
          label: this.$t('status')
        }
      ]
    }
  }
}
</script>


<i18n>
en:
  title:           "Factoring contracts"
  newLink:         "+ New Factoring"
  contract:        "Contract"
  creditIssuer:    "Credit Issuer"
  product:         "Product"
  limit:           "Limit"
  startDate:       "Start Date"
  endDate:         "End Date"
  reference:       "Reference number"
  balance:         "Balance"
  debt:            "Debt"
  nextPayment:     "Next Payment"
  nextPaymentDate: "Next payment date"
  updatedAt:       "Last updated"
  status:          "Status"
  notFound:        "Contracts not found"
et:
  title:           "Factoring contracts"
  newLink:         "+ New Factoring"
  contract:        "Contract"
  creditIssuer:    "Credit Issuer"
  product:         "Product"
  limit:           "Limit"
  startDate:       "Start Date"
  endDate:         "End Date"
  reference:       "Reference number"
  balance:         "Balance"
  debt:            "Debt"
  nextPayment:     "Next Payment"
  nextPaymentDate: "Next payment date"
  updatedAt:       "Last updated"
  status:          "Status"
  notFound:        "Contracts not found"
ru:
  title:           "Factoring contracts"
  newLink:         "+ New loan"
  contract:        "Contract"
  creditIssuer:    "Credit Issuer"
  product:         "Product"
  limit:           "Limit"
  startDate:       "Start date"
  endDate:         "End date"
  reference:       "Reference number"
  balance:         "Balance"
  debt:            "Debt"
  nextPayment:     "Next Payment"
  nextPaymentDate: "Next payment date"
  updatedAt:       "Last updated"
  status:          "Status"
  notFound:        "Contracts not found"
</i18n>
