<template lang="pug">
.application-new-button
  v-loading(loader = 'classifiers:productTypes:fetch')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse
    b-dd(:text   = '$t("link")'
         variant = 'link p-0'
         right)
      b-dd-item(v-for = 'productType in productTypes'
                :key  = 'productType.value'
                :to   = '{ name: "ApplicationNew", query: { productType: productType.value }, params: { customerId, customer } }') {{ productType.text }}
</template>


<script>
export default {
  name: 'application-new-button',

  props: {
    customerId: {
      type: Number,
      default: null
    },
    productTypes: {
      type: Array,
      required: true
    },
    customer: {
      type: Object,
      default: null
    }
  },

  i18nOptions: {}
}
</script>


<i18n>
en:
  link: '+ New application'
et:
  link: '+ New application'
ru:
  link: '+ New application'
</i18n>
